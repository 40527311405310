/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BoatPackage } from './boat-package';
import { BookingAddOn } from './booking-add-on';
import { BookingUser } from './booking-user';
import { SharedDetailsBoat } from './shared-details-boat';
import { SharedDetailsCaptain } from './shared-details-captain';
import { SharedDetailsOwner } from './shared-details-owner';
import { SharedDetailsRenterPricing } from './shared-details-renter-pricing';

/**
 * 
 * @export
 * @interface SharedDetails
 */
export interface SharedDetails {
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'state'?: SharedDetailsStateEnum;
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'state_read_by_user'?: string;
    /**
     * 
     * @type {BoatPackage}
     * @memberof SharedDetails
     */
    'package'?: BoatPackage;
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'conversation_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'trip_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'trip_finish'?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'package_type'?: SharedDetailsPackageTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SharedDetails
     */
    'passengers'?: number;
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'captain_state'?: SharedDetailsCaptainStateEnum;
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'boat_us_sub_policy'?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'insurance_type'?: SharedDetailsInsuranceTypeEnum;
    /**
     * Insurance provider name
     * @type {string}
     * @memberof SharedDetails
     */
    'insurer'?: SharedDetailsInsurerEnum;
    /**
     * Towing insurance provider name
     * @type {string}
     * @memberof SharedDetails
     */
    'towing_insurer'?: SharedDetailsTowingInsurerEnum;
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'fuel_policy'?: SharedDetailsFuelPolicyEnum;
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'charter_type'?: SharedDetailsCharterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'charter_agreement_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'pay_captain_at_dock'?: string;
    /**
     * 
     * @type {SharedDetailsRenterPricing}
     * @memberof SharedDetails
     */
    'renter_pricing'?: SharedDetailsRenterPricing;
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'location_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedDetails
     */
    'pickup_instructions'?: string;
    /**
     * 
     * @type {SharedDetailsOwner}
     * @memberof SharedDetails
     */
    'owner'?: SharedDetailsOwner;
    /**
     * 
     * @type {BookingUser}
     * @memberof SharedDetails
     */
    'renter'?: BookingUser;
    /**
     * 
     * @type {SharedDetailsCaptain}
     * @memberof SharedDetails
     */
    'captain'?: SharedDetailsCaptain;
    /**
     * 
     * @type {SharedDetailsBoat}
     * @memberof SharedDetails
     */
    'boat'?: SharedDetailsBoat;
    /**
     * 
     * @type {Array<BookingAddOn>}
     * @memberof SharedDetails
     */
    'booking_add_ons'?: Array<BookingAddOn>;
}

export const SharedDetailsStateEnum = {
    cancelled: 'cancelled',
    pre_approved: 'pre_approved',
    expired: 'expired',
    aboard: 'aboard',
    payment_held: 'payment_held',
    ashore: 'ashore',
    concluded: 'concluded',
    approved: 'approved',
    pending_tos: 'pending_tos',
    initialized: 'initialized',
    disputed: 'disputed',
    declined: 'declined',
    request_canceled: 'request_canceled'
} as const;

export type SharedDetailsStateEnum = typeof SharedDetailsStateEnum[keyof typeof SharedDetailsStateEnum];
export const SharedDetailsPackageTypeEnum = {
    bareboat: 'bareboat',
    captained: 'captained'
} as const;

export type SharedDetailsPackageTypeEnum = typeof SharedDetailsPackageTypeEnum[keyof typeof SharedDetailsPackageTypeEnum];
export const SharedDetailsCaptainStateEnum = {
    pending: 'pending',
    available: 'available',
    cancelled: 'cancelled',
    declined: 'declined',
    admin_removed: 'admin_removed',
    renter_removed: 'renter_removed',
    approved: 'approved'
} as const;

export type SharedDetailsCaptainStateEnum = typeof SharedDetailsCaptainStateEnum[keyof typeof SharedDetailsCaptainStateEnum];
export const SharedDetailsInsuranceTypeEnum = {
    commercial: 'commercial',
    p2p: 'p2p'
} as const;

export type SharedDetailsInsuranceTypeEnum = typeof SharedDetailsInsuranceTypeEnum[keyof typeof SharedDetailsInsuranceTypeEnum];
export const SharedDetailsInsurerEnum = {
    boat_us: 'boat_us',
    buoy: 'buoy'
} as const;

export type SharedDetailsInsurerEnum = typeof SharedDetailsInsurerEnum[keyof typeof SharedDetailsInsurerEnum];
export const SharedDetailsTowingInsurerEnum = {
    boat_us: 'boat_us',
    sea_tow: 'sea_tow',
    buoy: 'buoy'
} as const;

export type SharedDetailsTowingInsurerEnum = typeof SharedDetailsTowingInsurerEnum[keyof typeof SharedDetailsTowingInsurerEnum];
export const SharedDetailsFuelPolicyEnum = {
    renter_pays: 'renter_pays',
    owner_pays: 'owner_pays'
} as const;

export type SharedDetailsFuelPolicyEnum = typeof SharedDetailsFuelPolicyEnum[keyof typeof SharedDetailsFuelPolicyEnum];
export const SharedDetailsCharterTypeEnum = {
    Time_Charter: 'Time Charter',
    Bareboat: 'Bareboat'
} as const;

export type SharedDetailsCharterTypeEnum = typeof SharedDetailsCharterTypeEnum[keyof typeof SharedDetailsCharterTypeEnum];


