/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DomesticV2BoatsBoatIdPackagesGetRequestInstantBooking } from './domestic-v2-boats-boat-id-packages-get-request-instant-booking';

/**
 * 
 * @export
 * @interface DomesticV2BoatsBoatIdPackagesGetRequest
 */
export interface DomesticV2BoatsBoatIdPackagesGetRequest {
    /**
     * 
     * @type {boolean}
     * @memberof DomesticV2BoatsBoatIdPackagesGetRequest
     */
    'available'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DomesticV2BoatsBoatIdPackagesGetRequest
     */
    'include_captain_price'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BoatsBoatIdPackagesGetRequest
     */
    'package_type': DomesticV2BoatsBoatIdPackagesGetRequestPackageTypeEnum;
    /**
     * Boat package price for half day trip with cents without dots.
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdPackagesGetRequest
     */
    'half_day_cents'?: number;
    /**
     * Boat package price for full day trip with cents without dots.
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdPackagesGetRequest
     */
    'all_day_cents': number;
    /**
     * Boat package price for seven days trip with cents without dots.
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdPackagesGetRequest
     */
    'seven_day_cents'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdPackagesGetRequest
     */
    'two_hour_price_cents'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdPackagesGetRequest
     */
    'three_hour_price_cents'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdPackagesGetRequest
     */
    'six_hour_price_cents'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BoatsBoatIdPackagesGetRequest
     */
    'fuel_policy': DomesticV2BoatsBoatIdPackagesGetRequestFuelPolicyEnum;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BoatsBoatIdPackagesGetRequest
     */
    'insurance_type': DomesticV2BoatsBoatIdPackagesGetRequestInsuranceTypeEnum;
    /**
     * 
     * @type {DomesticV2BoatsBoatIdPackagesGetRequestInstantBooking}
     * @memberof DomesticV2BoatsBoatIdPackagesGetRequest
     */
    'instant_booking'?: DomesticV2BoatsBoatIdPackagesGetRequestInstantBooking;
}

export const DomesticV2BoatsBoatIdPackagesGetRequestPackageTypeEnum = {
    captained: 'captained',
    bareboat: 'bareboat'
} as const;

export type DomesticV2BoatsBoatIdPackagesGetRequestPackageTypeEnum = typeof DomesticV2BoatsBoatIdPackagesGetRequestPackageTypeEnum[keyof typeof DomesticV2BoatsBoatIdPackagesGetRequestPackageTypeEnum];
export const DomesticV2BoatsBoatIdPackagesGetRequestFuelPolicyEnum = {
    renter_pays: 'renter_pays',
    owner_pays: 'owner_pays'
} as const;

export type DomesticV2BoatsBoatIdPackagesGetRequestFuelPolicyEnum = typeof DomesticV2BoatsBoatIdPackagesGetRequestFuelPolicyEnum[keyof typeof DomesticV2BoatsBoatIdPackagesGetRequestFuelPolicyEnum];
export const DomesticV2BoatsBoatIdPackagesGetRequestInsuranceTypeEnum = {
    p2p: 'p2p',
    commercial: 'commercial'
} as const;

export type DomesticV2BoatsBoatIdPackagesGetRequestInsuranceTypeEnum = typeof DomesticV2BoatsBoatIdPackagesGetRequestInsuranceTypeEnum[keyof typeof DomesticV2BoatsBoatIdPackagesGetRequestInsuranceTypeEnum];


