import getConfig from 'next/config';
import { defaultPic, valueEmpty } from 'helpers';

const { CF_URL, CF_BUCKET } = getConfig().publicRuntimeConfig;

if (typeof btoa === 'undefined') {
  global.btoa = (str) => Buffer.from(str, 'binary').toString('base64');
}

export const cloudFlareLoader = ({ src, width, quality }) => {
  const imageData = btoa(
    JSON.stringify({
      key: src,
      bucket: CF_BUCKET,
      edits: {
        webp: {
          quality,
        },
        resize: {
          width,
          height: undefined,
        },
      },
    })
  );

  return `${CF_URL}${imageData}?w=${width}`;
};

// TODO same is in new inbox in utils - make it common after release
export const determineStaticPreviewImage = (pictureUrl: string, firstName: string) => {
  if (pictureUrl?.includes('default_profile_pic.png')) {
    return defaultPic(firstName);
  }
  return pictureUrl;
};

export const getProfilePicFromUser = (user?: { picture?: { url?: string }; first_name?: string }) => {
  if (!user?.picture?.url?.includes('default_profile_pic.png')) {
    return user?.picture?.url;
  }
  return defaultPic(user?.first_name);
};

export const getLargePicFromUser = (user: { picture_large: { url: string }; first_name: string }) => {
  if (!valueEmpty(user?.picture_large?.url) && !user?.picture_large?.url.includes('default_profile_pic.png')) {
    return `${user?.picture_large?.url}`;
  }
  return defaultPic(user?.first_name);
};
