import styled from 'styled-components';
import Button from 'components/ui/Button/Button';
import HeaderDropdown, { DropdownSection } from './header/HeaderDropdown';

const ImpersonationMenuItem = ({ userDetails }) => (
  <HeaderDropdown
    icon={
      <svg height={32} fill="#2a8500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1008 1008">
        <path d="M766.6 523.6L765 522c6.2-7.8 10.9-14.8 14.8-21.1 3.9-6.2 3.9-10.9 0-14-32-28.9-65.6-39.8-99.9-32.8-14 3.1-27.3 9.4-40.6 18.7-18.7 14-33.6 31.2-44.5 50.7-2.3 4.7-1.6 8.6 2.3 11.7 32 28.9 65.6 39.8 99.9 32.8 14-3.1 27.3-9.4 40.6-18.7 13.4-9.3 22.8-17.8 29-25.7zm-352.7 12.5c3.9-3.1 4.7-7 2.3-11.7-22.6-39-50.7-62.4-85.1-69.5s-67.9 3.9-99.9 32.8c-3.9 3.1-4.7 7-2.3 11.7 22.6 39 50.7 62.4 85.1 69.5s67.9-3.9 99.9-32.8zm454.2-217.7l2.3 3.1c7 5.5 10.9 15.6 12.5 29.7 1.6 14 3.9 39 6.2 74.9 6.2 89.7 9.4 143.6 9.4 161.5 0 10.9-12.5 25-37.5 42.1-25 17.2-56.2 32-94.4 45.3-38.2 13.3-72.6 20.3-103.8 20.3-25.8 0-47.6-7-66.3-21.1-14.8-11.7-27.3-24.2-36.7-36.7-9.4-12.5-16.4-21.9-21.9-28.1-11.7-14-22.6-21.1-32.8-21.1-9.4 0-17.2 3.9-24.2 11.7-7 7.8-13.3 14.8-18.7 21.1-5.5 6.2-10.9 12.5-16.4 19.5-5.5 7-11.7 13.3-18.7 19.5s-14 11.7-21.9 16.4c-16.3 11-38.2 16.5-65.5 16.5-27.3 0-60.1-7-98.3-20.3-38.2-13.3-69.5-28.1-94.4-45.3s-37.5-31.2-37.5-42.9 2.3-47.6 6.2-107.7 7-105.4 10.1-135.8c.8-9.4 4.7-17.2 11.7-22.6 7-5.5 16.4-7 28.9-4.7s40.6 10.1 84.3 23.4c43.7 13.3 88.2 25 134.2 35.9 46 10.9 85.1 16.4 117.8 16.4s71.8-5.5 117.8-16.4c46-10.9 90.5-22.6 134.2-35.9 43.7-13.3 71.8-21.1 84.3-23.4s22-.8 29.1 4.7z" />
      </svg>
    }
  >
    <DropdownSection>
      <Wrapper>
        <div>You are impersonating </div>
        <strong>
          {userDetails.first_name} {userDetails.last_name}
        </strong>
        <div> (User id: {userDetails.id})</div>

        <Button component="a" href="/exit_impersonation" text="Exit impersonation" outline />
      </Wrapper>
    </DropdownSection>
  </HeaderDropdown>
);

const Wrapper = styled.div`
  font-size: 12px;

  div {
    color: #0a4195;
    padding: 10px 0;
  }
`;

export default ImpersonationMenuItem;
