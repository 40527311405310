/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BookingPricingRenterCancellationPolicy
 */
export interface BookingPricingRenterCancellationPolicy {
    /**
     * 
     * @type {string}
     * @memberof BookingPricingRenterCancellationPolicy
     */
    'name'?: BookingPricingRenterCancellationPolicyNameEnum;
    /**
     * 
     * @type {number}
     * @memberof BookingPricingRenterCancellationPolicy
     */
    'percent'?: number;
}

export const BookingPricingRenterCancellationPolicyNameEnum = {
    strict: 'strict',
    moderate: 'moderate',
    flexible: 'flexible'
} as const;

export type BookingPricingRenterCancellationPolicyNameEnum = typeof BookingPricingRenterCancellationPolicyNameEnum[keyof typeof BookingPricingRenterCancellationPolicyNameEnum];


