import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { ColoredLink } from 'components/header/shareComponents';
import { useSetAtom } from 'jotai';
import { logoutRedirectUrlAtom } from 'auth/userStore';
import getConfig from 'next/config';
import boatsetterPaths from 'utils/boatsetterPaths';
import NavInbox from './NavInbox';

import HeaderDropdown, { DropdownSection } from './HeaderDropdown';

const { RAILS_URL } = getConfig().publicRuntimeConfig;
const baseURL = RAILS_URL.replace(/https?:\/\//, '');

const AccountDropdownMenuItem = (props) => {
  const { image, title, style, imageProps = {}, userDetails, isSearch = false } = props;

  const { is_owner, is_boat_manager, is_captain, is_renter, id: userId } = userDetails;

  const header = () => (
    <UserPic data-testid="components-header-user-pic" alt={title} style={style || {}} src={image} {...imageProps} />
  );

  const setLogoutRedirectUrl = useSetAtom(logoutRedirectUrlAtom);

  const bookings_default_path = () => {
    if (is_owner || is_boat_manager) {
      return `${boatsetterPaths.ownerDashboard}/bookings`;
    }
    if (is_captain) {
      return `${boatsetterPaths.captainDashboard}/bookings`;
    }
    return '/bookings';
  };

  return (
    <StyledHeaderDropdown icon={header()} id="accountDropdownMenu">
      <DropdownSection>
        <ColoredLink>
          <a href={`/users/${userId}`}>
            <ProfileWrapper>
              <Hi>Hi {title}</Hi>
              <ViewProfile data-testid="components-header-account-dropdown-view-profile-link">View profile</ViewProfile>
            </ProfileWrapper>
          </a>
        </ColoredLink>
      </DropdownSection>
      {/* eslint-disable-next-line no-nested-ternary */}
      {is_owner || is_boat_manager || is_captain ? (
        <>
          {(is_owner || is_boat_manager) && (
            <DropdownSection>
              <li>
                <ColoredLink>
                  <a href={boatsetterPaths.ownerDashboard}>Owner dashboard</a>
                </ColoredLink>
              </li>
              <li>
                <ColoredLink>
                  <Link href={boatsetterPaths.myBoats}>My boats</Link>
                </ColoredLink>
              </li>
            </DropdownSection>
          )}
          {is_captain && (
            <DropdownSection>
              <li>
                <ColoredLink>
                  <a href={boatsetterPaths.captainDashboard}>Captain dashboard</a>
                </ColoredLink>
              </li>
            </DropdownSection>
          )}
        </>
      ) : is_renter ? (
        <DropdownSection>
          <li>
            <ColoredLink>
              <a href={boatsetterPaths.renterDashboard}>Renter dashboard</a>
            </ColoredLink>
          </li>
        </DropdownSection>
      ) : null}
      {isSearch && (
        <DropdownSection>
          <li>
            <NavInbox userDetails={userDetails} />
          </li>
          <li>
            <ColoredLink>
              <a href={bookings_default_path()}>Bookings</a>
            </ColoredLink>
          </li>
        </DropdownSection>
      )}
      <DropdownSection>
        <li>
          <ColoredLink>
            <a href={boatsetterPaths.account}>My account</a>
          </ColoredLink>
        </li>
        <li>
          <ColoredLink>
            <a href="/account/favorites">Favorites</a>
          </ColoredLink>
        </li>
        <li>
          <ColoredLink>
            <Link href={boatsetterPaths.listBoat}>List your boat</Link>
          </ColoredLink>
        </li>
      </DropdownSection>
      <DropdownSection>
        <li>
          <ColoredLink>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://support.boatsetter.com?utm_source=${baseURL}&utm_medium=referral`}
            >
              Support
            </a>
          </ColoredLink>
        </li>
        <li>
          <ColoredLink>
            <Link
              passHref
              data-method="delete"
              href={boatsetterPaths.logOut}
              rel="nofollow"
              onClick={() => setLogoutRedirectUrl(`${window.location.pathname}${window.location.search}`)}
            >
              Log out
            </Link>
          </ColoredLink>
        </li>
      </DropdownSection>
    </StyledHeaderDropdown>
  );
};

const StyledHeaderDropdown = styled(HeaderDropdown)`
  padding-left: 25px;
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const Hi = styled.span`
  padding-bottom: 2px;
`;

const ViewProfile = styled.span`
  font-size: 12px;
  font-weight: 500;
`;

const UserPic = styled.img`
  width: 37px;
  height: 37px;
  border: 1px solid #fff;
  border-radius: 50%;
`;

AccountDropdownMenuItem.defaultProps = {
  showLogout: false,
};

export default AccountDropdownMenuItem;
