/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DomesticV2MeTrackAbandonedSessionPostRequest
 */
export interface DomesticV2MeTrackAbandonedSessionPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeTrackAbandonedSessionPostRequest
     */
    'trip_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeTrackAbandonedSessionPostRequest
     */
    'trip_finish'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeTrackAbandonedSessionPostRequest
     */
    'last_searched_trip_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeTrackAbandonedSessionPostRequest
     */
    'conversation_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeTrackAbandonedSessionPostRequest
     */
    'last_searched_pdp_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeTrackAbandonedSessionPostRequest
     */
    'last_searched_plp_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeTrackAbandonedSessionPostRequest
     */
    'abandoned_checkout_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeTrackAbandonedSessionPostRequest
     */
    'abandon_type': DomesticV2MeTrackAbandonedSessionPostRequestAbandonTypeEnum;
}

export const DomesticV2MeTrackAbandonedSessionPostRequestAbandonTypeEnum = {
    pdp: 'pdp',
    plp: 'plp',
    checkout: 'checkout'
} as const;

export type DomesticV2MeTrackAbandonedSessionPostRequestAbandonTypeEnum = typeof DomesticV2MeTrackAbandonedSessionPostRequestAbandonTypeEnum[keyof typeof DomesticV2MeTrackAbandonedSessionPostRequestAbandonTypeEnum];


