import styled, { css } from 'styled-components';

export const ColoredLink = styled.span`
  font-size: 14px;
`;
export const LinkWrap = styled.div<{ white?: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 32px;

  ${({ white }) =>
    white &&
    css`
      color: white;
      a {
        color: white !important;
      }
    `}
`;
