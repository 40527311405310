import React, { ReactNode } from 'react';

export type MenuItemType = {
  name: string | ReactNode;
  icon: string;
  target: string;
  visible: boolean;

  status?: Partial<{
    showStatus: boolean;
    name: MenuItemStatuses;
  }>;
  handler?: (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export enum MenuItemStatuses {
  ACTIVE = 'ACTIVE',
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  DENIED = 'DENIED',
}
