export const helpItems = [
  {
    target: 'http://support.boatbound.co/hc/en-us/categories/200221820-How-Boatbound-Works',
    name: 'How Boatsetter Works',
  },
  {
    target: 'http://support.boatbound.co/hc/en-us/categories/200229304-Insurance-Safety',
    name: 'Insurance & Safety',
  },
  {
    target: 'http://support.boatbound.co/hc/en-us/categories/200032729-Listing-Your-Boat',
    name: 'Listing Your Boat',
  },
  {
    target: 'http://support.boatbound.co/hc/en-us/categories/200039154-Renting-A-Boat',
    name: 'Renting A Boat',
  },
  {
    target: 'http://support.boatbound.co/hc/en-us/requests/new',
    name: 'Contact Support',
  },
  {
    target: 'tel:+1-305-600-5435',
    name: 'Call (305) 600-5435',
  },
];

export const helpItemsBLW = [
  {
    target: 'https://support.boatsetter.com',
    name: 'Support site',
  },
  {
    target: 'mailto:contact@boatsetter.com',
    name: 'Email us',
  },
  {
    target: 'tel:+1-855-908-3869',
    name: 'Call 1 (855) 908-3869',
  },
];
