/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Requirements due or past due. If there are any past due items, the boat cannot be activated
 * @export
 * @interface DomesticV2BoatsBoatIdActivationRequirementsGet200Response
 */
export interface DomesticV2BoatsBoatIdActivationRequirementsGet200Response {
    /**
     * 
     * @type {Array<string>}
     * @memberof DomesticV2BoatsBoatIdActivationRequirementsGet200Response
     */
    'past_due'?: Array<DomesticV2BoatsBoatIdActivationRequirementsGet200ResponsePastDueEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DomesticV2BoatsBoatIdActivationRequirementsGet200Response
     */
    'currently_due'?: Array<DomesticV2BoatsBoatIdActivationRequirementsGet200ResponseCurrentlyDueEnum>;
}

export const DomesticV2BoatsBoatIdActivationRequirementsGet200ResponsePastDueEnum = {
    commercial_captain_prices: 'commercial_captain_prices',
    package_prices: 'package_prices',
    tax_form: 'tax_form',
    bank_account: 'bank_account',
    stripe_additional_requirements: 'stripe_additional_requirements'
} as const;

export type DomesticV2BoatsBoatIdActivationRequirementsGet200ResponsePastDueEnum = typeof DomesticV2BoatsBoatIdActivationRequirementsGet200ResponsePastDueEnum[keyof typeof DomesticV2BoatsBoatIdActivationRequirementsGet200ResponsePastDueEnum];
export const DomesticV2BoatsBoatIdActivationRequirementsGet200ResponseCurrentlyDueEnum = {
    commercial_captain_prices: 'commercial_captain_prices',
    package_prices: 'package_prices',
    tax_form: 'tax_form',
    bank_account: 'bank_account',
    stripe_additional_requirements: 'stripe_additional_requirements'
} as const;

export type DomesticV2BoatsBoatIdActivationRequirementsGet200ResponseCurrentlyDueEnum = typeof DomesticV2BoatsBoatIdActivationRequirementsGet200ResponseCurrentlyDueEnum[keyof typeof DomesticV2BoatsBoatIdActivationRequirementsGet200ResponseCurrentlyDueEnum];


