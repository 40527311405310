/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AddOnsParams } from './add-ons-params';

/**
 * 
 * @export
 * @interface DomesticV2TripsCalculatorGetRequest
 */
export interface DomesticV2TripsCalculatorGetRequest {
    /**
     * 
     * @type {boolean}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'apply_boating_credits'?: boolean;
    /**
     * The package ID
     * @type {string}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'package_id'?: string;
    /**
     * The trip duration type
     * @type {string}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'duration'?: DomesticV2TripsCalculatorGetRequestDurationEnum;
    /**
     * ISO 8601 date
     * @type {string}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'trip_start'?: string;
    /**
     * ISO 8601 date
     * @type {string}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'trip_finish'?: string;
    /**
     * 
     * @type {number}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'passengers'?: number;
    /**
     * The coupon code to discount rental price. Overrides Boating Credits
     * @type {string}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'coupon_code'?: string;
    /**
     * The ID of the captain.
     * @type {string}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'captain_id'?: string;
    /**
     * The UUID of the captain.
     * @type {string}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'captain_public_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'booking_total'?: number;
    /**
     * The trip time
     * @type {string}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'trip_time'?: string;
    /**
     * The array of add-ons
     * @type {Array<AddOnsParams>}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'add_ons'?: Array<AddOnsParams>;
    /**
     * The unique id for the insurance renter policy which is or will be attached to the booking
     * @type {string}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'insurance_renter_policy_id'?: string;
    /**
     * Where the initialization of the booking occured.
     * @type {string}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'initialization_source'?: DomesticV2TripsCalculatorGetRequestInitializationSourceEnum;
    /**
     * Which page the calculate was called on
     * @type {string}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'displayed_page'?: DomesticV2TripsCalculatorGetRequestDisplayedPageEnum;
    /**
     * Whether full pricing was shown to the user.
     * @type {boolean}
     * @memberof DomesticV2TripsCalculatorGetRequest
     */
    'full_pricing_shown_to_user'?: boolean;
}

export const DomesticV2TripsCalculatorGetRequestDurationEnum = {
    full_day: 'full_day',
    half_day: 'half_day',
    two_hours: 'two_hours',
    three_hours: 'three_hours',
    six_hours: 'six_hours'
} as const;

export type DomesticV2TripsCalculatorGetRequestDurationEnum = typeof DomesticV2TripsCalculatorGetRequestDurationEnum[keyof typeof DomesticV2TripsCalculatorGetRequestDurationEnum];
export const DomesticV2TripsCalculatorGetRequestInitializationSourceEnum = {
    instant: 'instant',
    checkout: 'checkout',
    conversation: 'conversation'
} as const;

export type DomesticV2TripsCalculatorGetRequestInitializationSourceEnum = typeof DomesticV2TripsCalculatorGetRequestInitializationSourceEnum[keyof typeof DomesticV2TripsCalculatorGetRequestInitializationSourceEnum];
export const DomesticV2TripsCalculatorGetRequestDisplayedPageEnum = {
    pdp: 'pdp',
    conversation: 'conversation',
    checkout: 'checkout',
    add_ons: 'add_ons'
} as const;

export type DomesticV2TripsCalculatorGetRequestDisplayedPageEnum = typeof DomesticV2TripsCalculatorGetRequestDisplayedPageEnum[keyof typeof DomesticV2TripsCalculatorGetRequestDisplayedPageEnum];


