/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Reward
 */
export interface Reward {
    /**
     * The type of reward
     * @type {string}
     * @memberof Reward
     */
    'reward_type'?: RewardRewardTypeEnum;
    /**
     * The number of times the user has earned this reward
     * @type {number}
     * @memberof Reward
     */
    'earned_count'?: number;
    /**
     * Credit amount in cents
     * @type {number}
     * @memberof Reward
     */
    'credits'?: number;
    /**
     * The currency of the credits
     * @type {string}
     * @memberof Reward
     */
    'credits_currency'?: string;
    /**
     * Short description of the reward
     * @type {string}
     * @memberof Reward
     */
    'title'?: string;
    /**
     * Long description of the reward
     * @type {string}
     * @memberof Reward
     */
    'description'?: string;
    /**
     * The reward\'s current status. Open indicates that the user can earn this reward. Closed indicates the user can no longer earn the reward, either because it\'s been discountinued or the user no longer meets the requirements
     * @type {string}
     * @memberof Reward
     */
    'status'?: RewardStatusEnum;
}

export const RewardRewardTypeEnum = {
    first_review: 'first_review',
    gift_card_purchase: 'gift_card_purchase',
    friends_boat_rents: 'friends_boat_rents',
    friend_rents: 'friend_rents'
} as const;

export type RewardRewardTypeEnum = typeof RewardRewardTypeEnum[keyof typeof RewardRewardTypeEnum];
export const RewardStatusEnum = {
    open_closed: 'open closed'
} as const;

export type RewardStatusEnum = typeof RewardStatusEnum[keyof typeof RewardStatusEnum];


