import boatsetterPaths from 'utils/boatsetterPaths';

export const SubmenuNames = {
  experiences: 'Experiences',
  boatCharters: 'Boat charters',
  locations: 'Locations',
  lakes: 'Lakes',
};
export const Submenus = {
  experiences: [
    { name: 'All boats', link: boatsetterPaths.boatRentals },
    { name: 'Yachts', link: boatsetterPaths.yachtRentals },
    { name: 'Fishing boats', link: boatsetterPaths.fishingBoatRentals },
    { name: 'Party boats', link: boatsetterPaths.partyBoatRentals },
    { name: 'Pontoon boats', link: boatsetterPaths.pontoonBoatRentals },
    { name: 'Sailboats', link: boatsetterPaths.sailboatRentals },
    { name: 'Jet Skis', link: boatsetterPaths.jetSkis },

    // { name: 'Electric boat rentals', link: '/electric-boat-rentals' },
    // { name: 'Catamaran rentals', link: '/catamaran-rentals' },
    // { name: 'Center Console rentals', link: '/center-console-rentals' },
    // { name: 'Bass boat rentals', link: '/bass-boat-rentals' },
    // { name: 'Speed boat rentals', link: '/speed-boat-rentals' },
    // { name: 'Bachelor party', link: '/bachelor-party-boat-rentals' },
    // { name: 'Bachelorette party', link: '/bachelorette-party-boat-rentals' },
    // { name: 'Watersports', link: '/watersports' },
    // { name: 'Wakeboard boat rentals', link: '/wakeboard-boat-rentals' },
    // { name: 'View all', link: '/boat-rentals' },
  ],
  boatCharters: [
    { name: 'Luxury yachts', link: boatsetterPaths.luxuryYachtCharters },
    { name: 'Fishing', link: boatsetterPaths.fishingCharters },
  ],
  locations: [
    {
      name: 'Miami',
      link: boatsetterPaths.locations.miami,
    },
    {
      name: 'Chicago',
      link: boatsetterPaths.locations.chicago,
    },
    {
      name: 'Seattle',
      link: boatsetterPaths.locations.seattle,
    },
    {
      name: 'San Diego',
      link: boatsetterPaths.locations.sanDiego,
    },
    {
      name: 'NYC',
      link: boatsetterPaths.locations.newYorkCity,
    },
    {
      name: 'Washington DC',
      link: boatsetterPaths.locations.washingtonDC,
    },
    {
      name: 'Los Angeles',
      link: boatsetterPaths.locations.losAngeles,
    },
    {
      name: 'San Francisco',
      link: boatsetterPaths.locations.sanFrancisco,
    },
    {
      name: 'Austin',
      link: boatsetterPaths.locations.austin,
    },
    {
      name: 'Destin',
      link: boatsetterPaths.locations.destin,
    },
    {
      name: 'Tampa',
      link: boatsetterPaths.locations.tampaBay,
    },
    {
      name: 'Sarasota',
      link: boatsetterPaths.locations.sarasota,
    },
    {
      name: 'Fort Lauderdale',
      link: boatsetterPaths.locations.fortLauderdale,
    },
    // {
    //   name: 'Boat rental Lake Murray',
    //   link: '/boat-rentals/lake-murray--sc--united-states',
    // },
    // { name: 'View all', link: '/boat-rentals' },
  ],
  lakes: [
    {
      name: 'Lake Travis',
      link: boatsetterPaths.locations.lakeTravis,
    },
    {
      name: 'Lake Tahoe',
      link: boatsetterPaths.locations.lakeTahoe,
    },
    {
      name: 'Lake Norman',
      link: boatsetterPaths.locations.lakeNorman,
    },
    {
      name: 'Lake Lanier',
      link: boatsetterPaths.locations.lakeLanier,
    },
    {
      name: 'Lake George',
      link: boatsetterPaths.locations.lakeGeorge,
    },
    {
      name: 'Lake of the Ozarks',
      link: boatsetterPaths.locations.lakeOfTheOzarks,
    },
    {
      name: 'Lake Havasu',
      link: boatsetterPaths.locations.lakeHavasu,
    },
    {
      name: 'Lake Mead',
      link: boatsetterPaths.locations.lakeMead,
    },
    {
      name: 'Lake Powell',
      link: boatsetterPaths.locations.lakePowell,
    },
    {
      name: 'Lake Conroe',
      link: boatsetterPaths.locations.lakeConroe,
    },
    { name: 'View all', link: boatsetterPaths.boatRentals },
  ],
};
