/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DomesticV2BookingsIdCicoCheckInGetRequestLocalRequirements } from './domestic-v2-bookings-id-cico-check-in-get-request-local-requirements';
import { DomesticV2BookingsIdCicoCheckInGetRequestNotes } from './domestic-v2-bookings-id-cico-check-in-get-request-notes';

/**
 * 
 * @export
 * @interface DomesticV2BookingsIdCicoCheckInGetRequest
 */
export interface DomesticV2BookingsIdCicoCheckInGetRequest {
    /**
     * values: 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1
     * @type {number}
     * @memberof DomesticV2BookingsIdCicoCheckInGetRequest
     */
    'boat_fuel': number;
    /**
     * 
     * @type {number}
     * @memberof DomesticV2BookingsIdCicoCheckInGetRequest
     */
    'engine_hours': number | null;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BookingsIdCicoCheckInGetRequest
     */
    'renter_signature': string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BookingsIdCicoCheckInGetRequest
     */
    'owner_signature': string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BookingsIdCicoCheckInGetRequest
     */
    'signature_type': DomesticV2BookingsIdCicoCheckInGetRequestSignatureTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BookingsIdCicoCheckInGetRequest
     */
    'inspection_at': string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BookingsIdCicoCheckInGetRequest
     */
    'ts'?: string;
    /**
     * 
     * @type {DomesticV2BookingsIdCicoCheckInGetRequestNotes}
     * @memberof DomesticV2BookingsIdCicoCheckInGetRequest
     */
    'notes': DomesticV2BookingsIdCicoCheckInGetRequestNotes;
    /**
     * 
     * @type {DomesticV2BookingsIdCicoCheckInGetRequestLocalRequirements}
     * @memberof DomesticV2BookingsIdCicoCheckInGetRequest
     */
    'local_requirements': DomesticV2BookingsIdCicoCheckInGetRequestLocalRequirements;
}

export const DomesticV2BookingsIdCicoCheckInGetRequestSignatureTypeEnum = {
    text: 'text',
    image: 'image'
} as const;

export type DomesticV2BookingsIdCicoCheckInGetRequestSignatureTypeEnum = typeof DomesticV2BookingsIdCicoCheckInGetRequestSignatureTypeEnum[keyof typeof DomesticV2BookingsIdCicoCheckInGetRequestSignatureTypeEnum];


