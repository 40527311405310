/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DomesticV2MeDevicesIdDeleteRequest
 */
export interface DomesticV2MeDevicesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeDevicesIdDeleteRequest
     */
    'state'?: DomesticV2MeDevicesIdDeleteRequestStateEnum;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeDevicesIdDeleteRequest
     */
    'device_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeDevicesIdDeleteRequest
     */
    'app_build_number'?: string;
}

export const DomesticV2MeDevicesIdDeleteRequestStateEnum = {
    push_enabled_push_disabled: 'push_enabled push_disabled'
} as const;

export type DomesticV2MeDevicesIdDeleteRequestStateEnum = typeof DomesticV2MeDevicesIdDeleteRequestStateEnum[keyof typeof DomesticV2MeDevicesIdDeleteRequestStateEnum];


