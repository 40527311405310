/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { OwnerCaptainProvision } from './owner-captain-provision';

/**
 * 
 * @export
 * @interface DomesticV2BoatsBoatIdTemporaryPackageGetRequest
 */
export interface DomesticV2BoatsBoatIdTemporaryPackageGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'price_mode'?: DomesticV2BoatsBoatIdTemporaryPackageGetRequestPriceModeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'initial_duration_preferences'?: Array<DomesticV2BoatsBoatIdTemporaryPackageGetRequestInitialDurationPreferencesEnum>;
    /**
     * Commercial captained fishing charter
     * @type {boolean}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'commercial_captained_fishing_charter'?: boolean;
    /**
     * Renter operates boat
     * @type {boolean}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'bareboat'?: boolean;
    /**
     * Captain operates boat
     * @type {boolean}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'captained'?: boolean;
    /**
     * Use boatsetter captain network or custom captain list
     * @type {boolean}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'using_captain_network'?: boolean;
    /**
     * 
     * @type {OwnerCaptainProvision}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'owner_captain_provision'?: OwnerCaptainProvision;
    /**
     * Captain payment be collected
     * @type {string}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'captain_payment_collection'?: DomesticV2BoatsBoatIdTemporaryPackageGetRequestCaptainPaymentCollectionEnum;
    /**
     * Fuel policy
     * @type {string}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'fuel_policy'?: DomesticV2BoatsBoatIdTemporaryPackageGetRequestFuelPolicyEnum;
    /**
     * Boat 2-hour price in cents
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'boat_two_hour_price_cents'?: number;
    /**
     * Boat 3-hour price in cents
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'boat_three_hour_price_cents'?: number;
    /**
     * Boat 4-hour price in cents
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'boat_half_day_price_cents'?: number;
    /**
     * Boat 6-hour price in cents
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'boat_six_hour_price_cents'?: number;
    /**
     * Boat 4-hour price in cents
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'boat_all_day_price_cents'?: number;
    /**
     * Captain 2-hour price in cents
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'captain_two_hour_price_cents'?: number;
    /**
     * Captain 3-hour price in cents
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'captain_three_hour_price_cents'?: number;
    /**
     * Captain 4-hour price in cents
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'captain_half_day_price_cents'?: number;
    /**
     * Captain 6-hour price in cents
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'captain_six_hour_price_cents'?: number;
    /**
     * Captain 4-hour price in cents
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdTemporaryPackageGetRequest
     */
    'captain_all_day_price_cents'?: number;
}

export const DomesticV2BoatsBoatIdTemporaryPackageGetRequestPriceModeEnum = {
    smart: 'smart',
    custom: 'custom'
} as const;

export type DomesticV2BoatsBoatIdTemporaryPackageGetRequestPriceModeEnum = typeof DomesticV2BoatsBoatIdTemporaryPackageGetRequestPriceModeEnum[keyof typeof DomesticV2BoatsBoatIdTemporaryPackageGetRequestPriceModeEnum];
export const DomesticV2BoatsBoatIdTemporaryPackageGetRequestInitialDurationPreferencesEnum = {
    half_day: 'half_day',
    all_day: 'all_day',
    two_hours: 'two_hours',
    three_hours: 'three_hours',
    six_hours: 'six_hours'
} as const;

export type DomesticV2BoatsBoatIdTemporaryPackageGetRequestInitialDurationPreferencesEnum = typeof DomesticV2BoatsBoatIdTemporaryPackageGetRequestInitialDurationPreferencesEnum[keyof typeof DomesticV2BoatsBoatIdTemporaryPackageGetRequestInitialDurationPreferencesEnum];
export const DomesticV2BoatsBoatIdTemporaryPackageGetRequestCaptainPaymentCollectionEnum = {
    via_boatsetter: 'via_boatsetter',
    at_dock: 'at_dock',
    empty: ''
} as const;

export type DomesticV2BoatsBoatIdTemporaryPackageGetRequestCaptainPaymentCollectionEnum = typeof DomesticV2BoatsBoatIdTemporaryPackageGetRequestCaptainPaymentCollectionEnum[keyof typeof DomesticV2BoatsBoatIdTemporaryPackageGetRequestCaptainPaymentCollectionEnum];
export const DomesticV2BoatsBoatIdTemporaryPackageGetRequestFuelPolicyEnum = {
    owner_pays: 'owner_pays',
    renter_pays: 'renter_pays'
} as const;

export type DomesticV2BoatsBoatIdTemporaryPackageGetRequestFuelPolicyEnum = typeof DomesticV2BoatsBoatIdTemporaryPackageGetRequestFuelPolicyEnum[keyof typeof DomesticV2BoatsBoatIdTemporaryPackageGetRequestFuelPolicyEnum];


