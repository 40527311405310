/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface InsuranceOwnerPolicy
 */
export interface InsuranceOwnerPolicy {
    /**
     * ID of object in database
     * @type {number}
     * @memberof InsuranceOwnerPolicy
     */
    'id'?: number;
    /**
     * Insurance Owner master policy number
     * @type {string}
     * @memberof InsuranceOwnerPolicy
     */
    'policy_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceOwnerPolicy
     */
    'status'?: InsuranceOwnerPolicyStatusEnum;
}

export const InsuranceOwnerPolicyStatusEnum = {
    approved: 'approved',
    denied: 'denied',
    pending: 'pending'
} as const;

export type InsuranceOwnerPolicyStatusEnum = typeof InsuranceOwnerPolicyStatusEnum[keyof typeof InsuranceOwnerPolicyStatusEnum];


