/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DomesticV2BookingsIdReviewsPostRequest
 */
export interface DomesticV2BookingsIdReviewsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BookingsIdReviewsPostRequest
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof DomesticV2BookingsIdReviewsPostRequest
     */
    'rating': number;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BookingsIdReviewsPostRequest
     */
    'review_for': DomesticV2BookingsIdReviewsPostRequestReviewForEnum;
}

export const DomesticV2BookingsIdReviewsPostRequestReviewForEnum = {
    boat: 'boat',
    renter: 'renter',
    captain: 'captain'
} as const;

export type DomesticV2BookingsIdReviewsPostRequestReviewForEnum = typeof DomesticV2BookingsIdReviewsPostRequestReviewForEnum[keyof typeof DomesticV2BookingsIdReviewsPostRequestReviewForEnum];


