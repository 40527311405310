/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PackageInstantBooking } from './package-instant-booking';

/**
 * 
 * @export
 * @interface BoatPackage
 */
export interface BoatPackage {
    /**
     * 
     * @type {string}
     * @memberof BoatPackage
     */
    'package_type'?: BoatPackagePackageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BoatPackage
     */
    'charter_agreement_type'?: BoatPackageCharterAgreementTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BoatPackage
     */
    'owner_captain_provision'?: BoatPackageOwnerCaptainProvisionEnum;
    /**
     * 
     * @type {string}
     * @memberof BoatPackage
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BoatPackage
     */
    'available'?: boolean;
    /**
     * Half day trip value with cents
     * @type {number}
     * @memberof BoatPackage
     */
    'half_day_cents'?: number;
    /**
     * Full day trip value with cents
     * @type {number}
     * @memberof BoatPackage
     */
    'all_day_cents'?: number;
    /**
     * Seven days trip value with cents
     * @type {number}
     * @memberof BoatPackage
     */
    'seven_day_cents'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatPackage
     */
    'two_hour_price_cents'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatPackage
     */
    'three_hour_price_cents'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatPackage
     */
    'six_hour_price_cents'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatPackage
     */
    'security_deposit_cents'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BoatPackage
     */
    'instant_bookable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BoatPackage
     */
    'fuel_policy'?: BoatPackageFuelPolicyEnum;
    /**
     * 
     * @type {string}
     * @memberof BoatPackage
     */
    'insurance_type'?: BoatPackageInsuranceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BoatPackage
     */
    'price_mode'?: BoatPackagePriceModeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof BoatPackage
     */
    'eligible_insurers'?: Array<BoatPackageEligibleInsurersEnum>;
    /**
     * 
     * @type {PackageInstantBooking}
     * @memberof BoatPackage
     */
    'instant_booking'?: PackageInstantBooking;
}

export const BoatPackagePackageTypeEnum = {
    bareboat: 'bareboat',
    captained: 'captained'
} as const;

export type BoatPackagePackageTypeEnum = typeof BoatPackagePackageTypeEnum[keyof typeof BoatPackagePackageTypeEnum];
export const BoatPackageCharterAgreementTypeEnum = {
    time_charter: 'time_charter',
    bareboat: 'bareboat'
} as const;

export type BoatPackageCharterAgreementTypeEnum = typeof BoatPackageCharterAgreementTypeEnum[keyof typeof BoatPackageCharterAgreementTypeEnum];
export const BoatPackageOwnerCaptainProvisionEnum = {
    na: 'na',
    owner_choice: 'owner_choice',
    renter_choice: 'renter_choice'
} as const;

export type BoatPackageOwnerCaptainProvisionEnum = typeof BoatPackageOwnerCaptainProvisionEnum[keyof typeof BoatPackageOwnerCaptainProvisionEnum];
export const BoatPackageFuelPolicyEnum = {
    renter_pays: 'renter_pays',
    owner_pays: 'owner_pays'
} as const;

export type BoatPackageFuelPolicyEnum = typeof BoatPackageFuelPolicyEnum[keyof typeof BoatPackageFuelPolicyEnum];
export const BoatPackageInsuranceTypeEnum = {
    commercial: 'commercial',
    p2p: 'p2p'
} as const;

export type BoatPackageInsuranceTypeEnum = typeof BoatPackageInsuranceTypeEnum[keyof typeof BoatPackageInsuranceTypeEnum];
export const BoatPackagePriceModeEnum = {
    smart: 'smart',
    custom: 'custom'
} as const;

export type BoatPackagePriceModeEnum = typeof BoatPackagePriceModeEnum[keyof typeof BoatPackagePriceModeEnum];
export const BoatPackageEligibleInsurersEnum = {
    buoy: 'buoy'
} as const;

export type BoatPackageEligibleInsurersEnum = typeof BoatPackageEligibleInsurersEnum[keyof typeof BoatPackageEligibleInsurersEnum];


