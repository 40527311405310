const AppStore = ({ height = 49, width = 148 }) => (
  <svg width={width} height={height} viewBox="0 0 132 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M131.267 32.7329C131.267 34.7047 129.586 36.3019 127.508 36.3019H4.49712C2.41964 36.3019 0.733337 34.7047 0.733337 32.7329V4.27173C0.733337 2.30089 2.41964 0.69812 4.49712 0.69812H127.507C129.586 0.69812 131.266 2.30089 131.266 4.27173L131.267 32.7329Z"
      fill="black"
    />
    <path
      d="M127.111 0.741158C129.375 0.741158 131.217 2.48363 131.217 4.625V32.375C131.217 34.5164 129.375 36.2588 127.111 36.2588H4.88889C2.62533 36.2588 0.783444 34.5164 0.783444 32.375V4.625C0.783444 2.48363 2.62533 0.741158 4.88889 0.741158H127.111ZM127.111 1.95543e-06H4.88889C2.20122 1.95543e-06 0 2.08241 0 4.625V32.375C0 34.9176 2.20122 37 4.88889 37H127.111C129.799 37 132 34.9176 132 32.375V4.625C132 2.08241 129.799 1.95543e-06 127.111 1.95543e-06Z"
      fill="#A6A6A6"
    />
    <path
      d="M29.4585 18.3001C29.4302 15.3189 32.0389 13.8685 32.1582 13.8009C30.6808 11.7632 28.3908 11.4847 27.5861 11.4625C25.6628 11.2711 23.7972 12.5513 22.8175 12.5513C21.8182 12.5513 20.3095 11.481 18.6834 11.5125C16.591 11.543 14.6335 12.6891 13.5599 14.4688C11.3442 18.0976 12.9967 23.4302 15.1194 26.3634C16.1813 27.7999 17.4221 29.4038 19.0462 29.3474C20.6351 29.2854 21.2286 28.3891 23.146 28.3891C25.0458 28.3891 25.6032 29.3474 27.2595 29.3113C28.9648 29.2854 30.0384 27.8683 31.0631 26.4189C32.2902 24.7724 32.783 23.1508 32.8025 23.0676C32.7625 23.0546 29.4908 21.8734 29.4585 18.3001Z"
      fill="white"
    />
    <path
      d="M26.3296 9.533C27.1842 8.52197 27.7689 7.1465 27.6066 5.75067C26.3697 5.80247 24.8229 6.56005 23.9321 7.54887C23.1441 8.42022 22.4401 9.84842 22.6219 11.1915C24.0113 11.2896 25.4379 10.5283 26.3296 9.533Z"
      fill="white"
    />
    <path
      d="M52.4529 29.1411H50.2323L49.016 25.5253H44.7881L43.6294 29.1411H41.4675L45.6563 16.8312H48.2435L52.4529 29.1411ZM48.6493 24.0083L47.5493 20.7939C47.433 20.4656 47.2149 19.6923 46.8932 18.475H46.8541C46.726 18.9985 46.5197 19.7718 46.2361 20.7939L45.1557 24.0083H48.6493Z"
      fill="white"
    />
    <path
      d="M63.225 24.5939C63.225 26.1035 62.7938 27.2967 61.9314 28.1727C61.1589 28.9525 60.1997 29.3419 59.0548 29.3419C57.8188 29.3419 56.931 28.9219 56.3903 28.082H56.3512V32.7579H54.2666V23.1869C54.2666 22.2379 54.2402 21.2639 54.1893 20.2649H56.0227L56.139 21.6718H56.1781C56.8733 20.6117 57.9284 20.0826 59.3442 20.0826C60.451 20.0826 61.375 20.4961 62.1142 21.324C62.8554 22.1528 63.225 23.2424 63.225 24.5939ZM61.1012 24.666C61.1012 23.8021 60.8959 23.0898 60.4833 22.5293C60.0325 21.9447 59.4273 21.6524 58.6685 21.6524C58.1542 21.6524 57.6868 21.8152 57.2693 22.1361C56.8508 22.4599 56.5771 22.8826 56.449 23.4062C56.3844 23.6504 56.3522 23.8502 56.3522 24.0074V25.4874C56.3522 26.1331 56.5614 26.6779 56.9799 27.1228C57.3984 27.5677 57.942 27.7897 58.6108 27.7897C59.396 27.7897 60.0071 27.503 60.4442 26.9313C60.8822 26.3588 61.1012 25.604 61.1012 24.666Z"
      fill="white"
    />
    <path
      d="M74.0168 24.5939C74.0168 26.1035 73.5856 27.2967 72.7223 28.1727C71.9508 28.9525 70.9916 29.3419 69.8466 29.3419C68.6107 29.3419 67.7229 28.9219 67.1832 28.082H67.144V32.7579H65.0594V23.1869C65.0594 22.2379 65.033 21.2639 64.9822 20.2649H66.8155L66.9319 21.6718H66.971C67.6652 20.6117 68.7202 20.0826 70.137 20.0826C71.2429 20.0826 72.1669 20.4961 72.908 21.324C73.6463 22.1528 74.0168 23.2424 74.0168 24.5939ZM71.8931 24.666C71.8931 23.8021 71.6868 23.0898 71.2742 22.5293C70.8234 21.9447 70.2201 21.6524 69.4604 21.6524C68.9451 21.6524 68.4787 21.8152 68.0602 22.1361C67.6417 22.4599 67.3689 22.8826 67.2408 23.4062C67.1773 23.6504 67.144 23.8502 67.144 24.0074V25.4874C67.144 26.1331 67.3533 26.6779 67.7698 27.1228C68.1883 27.5668 68.732 27.7897 69.4027 27.7897C70.1879 27.7897 70.799 27.503 71.236 26.9313C71.6741 26.3588 71.8931 25.604 71.8931 24.666Z"
      fill="white"
    />
    <path
      d="M86.0825 25.6891C86.0825 26.7362 85.6982 27.5881 84.9268 28.2458C84.079 28.9645 82.8988 29.3234 81.3823 29.3234C79.9821 29.3234 78.8596 29.0681 78.0099 28.5566L78.493 26.9128C79.4082 27.4364 80.4124 27.6991 81.5065 27.6991C82.2916 27.6991 82.9028 27.5307 83.3418 27.1959C83.7788 26.861 83.9969 26.4115 83.9969 25.8509C83.9969 25.3514 83.817 24.9306 83.4562 24.5892C83.0973 24.2479 82.4979 23.9306 81.661 23.6374C79.3827 22.8336 78.2446 21.6561 78.2446 20.1076C78.2446 19.0957 78.6436 18.2659 79.4424 17.6203C80.2383 16.9737 81.3002 16.6509 82.628 16.6509C83.8121 16.6509 84.7957 16.8461 85.5809 17.2355L85.0597 18.8431C84.3264 18.4657 83.4972 18.277 82.5693 18.277C81.836 18.277 81.263 18.4482 80.8524 18.7886C80.5052 19.0929 80.3312 19.4638 80.3312 19.9032C80.3312 20.3897 80.5297 20.7921 80.9286 21.1085C81.2757 21.4008 81.9064 21.7171 82.8216 22.0584C83.9412 22.4849 84.7635 22.9834 85.2924 23.5551C85.8195 24.1249 86.0825 24.8381 86.0825 25.6891Z"
      fill="white"
    />
    <path
      d="M92.975 21.7449H90.6772V26.0545C90.6772 27.1506 91.082 27.6982 91.8936 27.6982C92.2661 27.6982 92.5751 27.6677 92.8195 27.6066L92.8772 29.1042C92.4665 29.2494 91.9258 29.3225 91.2561 29.3225C90.4328 29.3225 89.7894 29.0848 89.325 28.6102C88.8625 28.1348 88.6297 27.3374 88.6297 26.2173V21.743H87.2609V20.263H88.6297V18.6378L90.6772 18.0532V20.263H92.975V21.7449Z"
      fill="white"
    />
    <path
      d="M103.342 24.6299C103.342 25.9943 102.93 27.1145 102.106 27.9905C101.243 28.8923 100.097 29.3419 98.6686 29.3419C97.2919 29.3419 96.1958 28.9099 95.3784 28.046C94.5609 27.182 94.1522 26.0914 94.1522 24.777C94.1522 23.4015 94.5727 22.2749 95.4165 21.3989C96.2584 20.522 97.3945 20.0836 98.8231 20.0836C100.2 20.0836 101.308 20.5155 102.144 21.3804C102.943 22.2194 103.342 23.3026 103.342 24.6299ZM101.18 24.6938C101.18 23.8751 100.995 23.1731 100.62 22.5875C100.183 21.879 99.5583 21.5256 98.7487 21.5256C97.9108 21.5256 97.2743 21.8799 96.8372 22.5875C96.4627 23.174 96.2779 23.8872 96.2779 24.7308C96.2779 25.5494 96.4627 26.2515 96.8372 26.8361C97.288 27.5446 97.9176 27.898 98.7302 27.898C99.5261 27.898 100.151 27.5372 100.602 26.8176C100.986 26.2209 101.18 25.5115 101.18 24.6938Z"
      fill="white"
    />
    <path
      d="M110.118 21.9992C109.912 21.9632 109.692 21.9447 109.461 21.9447C108.728 21.9447 108.161 22.2064 107.762 22.7309C107.415 23.1934 107.241 23.778 107.241 24.4838V29.1412H105.157L105.177 23.0602C105.177 22.0372 105.15 21.1057 105.098 20.2658H106.914L106.99 21.9641H107.048C107.268 21.3804 107.615 20.9105 108.09 20.5581C108.555 20.2408 109.056 20.0826 109.597 20.0826C109.79 20.0826 109.964 20.0956 110.118 20.1187V21.9992Z"
      fill="white"
    />
    <path
      d="M119.441 24.2831C119.441 24.6365 119.417 24.9343 119.365 25.1776H113.111C113.136 26.0545 113.438 26.7251 114.019 27.1876C114.546 27.6011 115.227 27.8083 116.064 27.8083C116.99 27.8083 117.835 27.6686 118.595 27.3883L118.921 28.7573C118.033 29.1236 116.985 29.3059 115.776 29.3059C114.321 29.3059 113.179 28.9007 112.348 28.0913C111.518 27.282 111.103 26.1951 111.103 24.8316C111.103 23.4932 111.489 22.3785 112.263 21.4896C113.072 20.5406 114.166 20.066 115.543 20.066C116.895 20.066 117.919 20.5406 118.614 21.4896C119.165 22.2435 119.441 23.1759 119.441 24.2831ZM117.454 23.7716C117.467 23.187 117.331 22.6819 117.049 22.2555C116.688 21.707 116.134 21.4332 115.388 21.4332C114.706 21.4332 114.152 21.7005 113.728 22.237C113.381 22.6634 113.175 23.175 113.111 23.7707H117.454V23.7716Z"
      fill="white"
    />
    <path
      d="M47.96 9.25842C47.96 10.3471 47.6149 11.1667 46.9255 11.7171C46.287 12.2249 45.3797 12.4793 44.2044 12.4793C43.6216 12.4793 43.123 12.4552 42.7054 12.4071V6.45844C43.2501 6.37519 43.8367 6.33264 44.4703 6.33264C45.5899 6.33264 46.4337 6.56297 47.0028 7.02362C47.6403 7.54439 47.96 8.28902 47.96 9.25842ZM46.8796 9.28524C46.8796 8.57947 46.6821 8.03834 46.287 7.66094C45.892 7.28447 45.3151 7.09577 44.5554 7.09577C44.2327 7.09577 43.958 7.11612 43.7302 7.15867V11.681C43.8563 11.6995 44.087 11.7078 44.4224 11.7078C45.2066 11.7078 45.8118 11.5015 46.2382 11.089C46.6645 10.6764 46.8796 10.0752 46.8796 9.28524Z"
      fill="white"
    />
    <path
      d="M53.6888 10.2092C53.6888 10.8798 53.4864 11.4293 53.0816 11.8603C52.6573 12.3034 52.0951 12.5245 51.393 12.5245C50.7164 12.5245 50.1776 12.3127 49.7758 11.8872C49.3749 11.4626 49.1744 10.927 49.1744 10.2814C49.1744 9.60611 49.3807 9.05203 49.7953 8.62191C50.2099 8.19178 50.7672 7.97626 51.4693 7.97626C52.1459 7.97626 52.6896 8.18808 53.1012 8.61266C53.4923 9.02521 53.6888 9.55801 53.6888 10.2092ZM52.626 10.2407C52.626 9.83828 52.5341 9.49326 52.3512 9.20558C52.1361 8.85778 51.8301 8.68388 51.4321 8.68388C51.0205 8.68388 50.7076 8.85778 50.4925 9.20558C50.3087 9.49326 50.2177 9.84383 50.2177 10.2582C50.2177 10.6606 50.3096 11.0056 50.4925 11.2933C50.7144 11.6411 51.0234 11.815 51.4224 11.815C51.8135 11.815 52.1205 11.6383 52.3415 11.2841C52.5312 10.9908 52.626 10.643 52.626 10.2407Z"
      fill="white"
    />
    <path
      d="M61.3702 8.065L59.928 12.4255H58.9893L58.3919 10.532C58.2404 10.0593 58.1172 9.5894 58.0213 9.1232H58.0028C57.9138 9.60235 57.7906 10.0713 57.6322 10.532L56.9976 12.4255H56.0482L54.692 8.065H55.7451L56.2662 10.1379C56.3924 10.6282 56.496 11.0953 56.5791 11.5375H56.5977C56.674 11.173 56.8001 10.7087 56.9781 10.1472L57.6322 8.06593H58.4672L59.094 10.1028C59.2455 10.5995 59.3687 11.0777 59.4636 11.5384H59.4919C59.5613 11.0898 59.666 10.6115 59.8048 10.1028L60.3641 8.06593H61.3702V8.065Z"
      fill="white"
    />
    <path
      d="M66.6825 12.4255H65.6578V9.92804C65.6578 9.15844 65.3488 8.77364 64.7289 8.77364C64.4248 8.77364 64.1794 8.87909 63.9887 9.09091C63.8 9.30274 63.7042 9.55249 63.7042 9.83831V12.4246H62.6795V9.31106C62.6795 8.92811 62.6668 8.51279 62.6423 8.06324H63.5429L63.5908 8.74496H63.6191C63.7384 8.53314 63.9164 8.35831 64.1501 8.21864C64.4278 8.05584 64.7387 7.97351 65.0789 7.97351C65.5092 7.97351 65.867 8.10486 66.1516 8.36849C66.5055 8.69131 66.6825 9.17324 66.6825 9.81334V12.4255Z"
      fill="white"
    />
    <path d="M69.5083 12.4256H68.4846V6.06433H69.5083V12.4256Z" fill="white" />
    <path
      d="M75.5413 10.2092C75.5413 10.8798 75.3389 11.4293 74.9341 11.8603C74.5097 12.3034 73.9465 12.5245 73.2454 12.5245C72.5678 12.5245 72.0291 12.3127 71.6282 11.8872C71.2273 11.4626 71.0269 10.927 71.0269 10.2814C71.0269 9.60611 71.2332 9.05203 71.6477 8.62191C72.0623 8.19178 72.6197 7.97626 73.3207 7.97626C73.9983 7.97626 74.541 8.18808 74.9536 8.61266C75.3447 9.02521 75.5413 9.55801 75.5413 10.2092ZM74.4774 10.2407C74.4774 9.83828 74.3855 9.49326 74.2027 9.20558C73.9885 8.85778 73.6815 8.68388 73.2845 8.68388C72.8719 8.68388 72.559 8.85778 72.3449 9.20558C72.1611 9.49326 72.0701 9.84383 72.0701 10.2582C72.0701 10.6606 72.1621 11.0056 72.3449 11.2933C72.5669 11.6411 72.8758 11.815 73.2748 11.815C73.6659 11.815 73.9719 11.6383 74.1929 11.2841C74.3836 10.9908 74.4774 10.643 74.4774 10.2407Z"
      fill="white"
    />
    <path
      d="M80.5004 12.4255H79.5803L79.5041 11.9232H79.4757C79.1609 12.3238 78.7121 12.5245 78.1293 12.5245C77.6942 12.5245 77.3422 12.3922 77.0772 12.1295C76.8367 11.8909 76.7164 11.5939 76.7164 11.2415C76.7164 10.7087 76.9511 10.3026 77.4234 10.0214C77.8947 9.74023 78.5576 9.60241 79.4112 9.60888V9.52748C79.4112 8.95306 79.0924 8.66631 78.4539 8.66631C77.9993 8.66631 77.5984 8.77453 77.2523 8.98913L77.044 8.35273C77.4723 8.10206 78.0012 7.97626 78.6251 7.97626C79.8297 7.97626 80.4339 8.57751 80.4339 9.78001V11.3858C80.4339 11.8215 80.4564 12.1684 80.5004 12.4255ZM79.4366 10.927V10.2545C78.3063 10.236 77.7411 10.5293 77.7411 11.1333C77.7411 11.3608 77.8057 11.531 77.9377 11.6448C78.0697 11.7586 78.2379 11.815 78.4383 11.815C78.6632 11.815 78.8734 11.7475 79.065 11.6134C79.2577 11.4783 79.376 11.3072 79.42 11.0972C79.4307 11.05 79.4366 10.9927 79.4366 10.927Z"
      fill="white"
    />
    <path
      d="M86.3232 12.4256H85.4139L85.366 11.7253H85.3376C85.0472 12.2581 84.5525 12.5245 83.8573 12.5245C83.3019 12.5245 82.8394 12.3183 82.4727 11.9057C82.1061 11.4932 81.9232 10.9576 81.9232 10.2999C81.9232 9.59413 82.1217 9.02248 82.5206 8.58588C82.9069 8.17888 83.3801 7.97538 83.9433 7.97538C84.5622 7.97538 84.9954 8.17241 85.2418 8.56738H85.2613V6.06433H86.287V11.2508C86.287 11.6754 86.2988 12.0667 86.3232 12.4256ZM85.2613 10.5867V9.85961C85.2613 9.73381 85.2516 9.63206 85.233 9.55436C85.1753 9.32126 85.0511 9.12516 84.8624 8.96698C84.6717 8.80881 84.442 8.72926 84.177 8.72926C83.7947 8.72926 83.4955 8.87263 83.2755 9.16031C83.0574 9.44798 82.9469 9.81521 82.9469 10.2638C82.9469 10.6949 83.0516 11.0445 83.2618 11.3137C83.4837 11.6005 83.7829 11.7438 84.1574 11.7438C84.4938 11.7438 84.7627 11.6245 84.967 11.3849C85.1645 11.1639 85.2613 10.8975 85.2613 10.5867Z"
      fill="white"
    />
    <path
      d="M95.0869 10.2092C95.0869 10.8798 94.8845 11.4293 94.4797 11.8603C94.0554 12.3034 93.4941 12.5245 92.7911 12.5245C92.1154 12.5245 91.5767 12.3127 91.1738 11.8872C90.773 11.4626 90.5725 10.927 90.5725 10.2814C90.5725 9.60611 90.7788 9.05203 91.1934 8.62191C91.608 8.19178 92.1653 7.97626 92.8683 7.97626C93.544 7.97626 94.0886 8.18808 94.4993 8.61266C94.8904 9.02521 95.0869 9.55801 95.0869 10.2092ZM94.025 10.2407C94.025 9.83828 93.9331 9.49326 93.7503 9.20558C93.5342 8.85778 93.2291 8.68388 92.8302 8.68388C92.4195 8.68388 92.1066 8.85778 91.8906 9.20558C91.7067 9.49326 91.6158 9.84383 91.6158 10.2582C91.6158 10.6606 91.7077 11.0056 91.8906 11.2933C92.1125 11.6411 92.4215 11.815 92.8204 11.815C93.2115 11.815 93.5195 11.6383 93.7405 11.2841C93.9292 10.9908 94.025 10.643 94.025 10.2407Z"
      fill="white"
    />
    <path
      d="M100.597 12.4255H99.573V9.92804C99.573 9.15844 99.264 8.77364 98.6431 8.77364C98.339 8.77364 98.0936 8.87909 97.9039 9.09091C97.7142 9.30274 97.6194 9.55249 97.6194 9.83831V12.4246H96.5937V9.31106C96.5937 8.92811 96.5819 8.51279 96.5575 8.06324H97.457L97.505 8.74496H97.5333C97.6536 8.53314 97.8315 8.35831 98.0642 8.21864C98.3429 8.05584 98.6529 7.97351 98.9941 7.97351C99.4234 7.97351 99.7812 8.10486 100.066 8.36849C100.421 8.69131 100.597 9.17324 100.597 9.81334V12.4255Z"
      fill="white"
    />
    <path
      d="M107.493 8.7912H106.365V10.9095C106.365 11.4478 106.565 11.717 106.962 11.717C107.146 11.717 107.298 11.7022 107.419 11.6717L107.445 12.407C107.243 12.4792 106.977 12.5153 106.649 12.5153C106.244 12.5153 105.93 12.3987 105.702 12.1656C105.473 11.9325 105.359 11.5403 105.359 10.9899V8.7912H104.686V8.06508H105.359V7.26588L106.364 6.97913V8.06415H107.492V8.7912H107.493Z"
      fill="white"
    />
    <path
      d="M112.918 12.4256H111.892V9.94656C111.892 9.16493 111.583 8.77366 110.964 8.77366C110.489 8.77366 110.164 9.00028 109.986 9.45353C109.956 9.54881 109.938 9.66536 109.938 9.80226V12.4246H108.915V6.06433H109.938V8.69226H109.958C110.281 8.21403 110.743 7.97538 111.343 7.97538C111.767 7.97538 112.118 8.10673 112.397 8.37036C112.744 8.69873 112.918 9.18713 112.918 9.83278V12.4256Z"
      fill="white"
    />
    <path
      d="M118.514 10.039C118.514 10.2129 118.5 10.3591 118.475 10.4784H115.402C115.416 10.9095 115.563 11.2378 115.847 11.4654C116.107 11.6689 116.443 11.7706 116.853 11.7706C117.308 11.7706 117.722 11.7022 118.096 11.5644L118.256 12.2378C117.819 12.4172 117.305 12.5069 116.71 12.5069C115.996 12.5069 115.434 12.3081 115.027 11.9103C114.618 11.5126 114.416 10.9788 114.416 10.3091C114.416 9.65146 114.604 9.10386 114.985 8.66726C115.382 8.20106 115.918 7.96796 116.596 7.96796C117.259 7.96796 117.763 8.20106 118.103 8.66726C118.378 9.03726 118.514 9.49513 118.514 10.039ZM117.536 9.78836C117.544 9.50068 117.476 9.25278 117.337 9.04373C117.159 8.77456 116.888 8.63951 116.522 8.63951C116.187 8.63951 115.915 8.77086 115.706 9.03448C115.536 9.24446 115.435 9.49513 115.402 9.78836H117.536Z"
      fill="white"
    />
  </svg>
);

export default AppStore;
