import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { CSSTransition } from 'react-transition-group';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import { mediaLgMax, mediaMdMax, mediaSmMax, mediaSmViewport } from 'helpers/breakpoints';
import { useUserDetails } from 'auth/mutations/userHooks';
import getConfig from 'next/config';
import boatsetterPaths from 'utils/boatsetterPaths';
import AppStore from 'components/icons/social/AppStore';
import GooglePlay from 'components/icons/social/GooglePlay';
import { getProfilePicFromUser } from 'utils/images';
import { SubmenuNames, Submenus } from '../homepage/menuUtils';
import NavInbox from '../header/NavInbox';

const { RAILS_URL } = getConfig().publicRuntimeConfig;
const baseURL = RAILS_URL.replace(/https?:\/\//, '');

type Props = {
  menuOpen: boolean;
  setMenuOpen: (bool: boolean) => void;
  white?: boolean;
  hideNav?: boolean;
  isHome?: boolean;
  isHomePage?: boolean;
  isSearch?: boolean;
  greenBg?: boolean;
};

const MobileMenu = (props: Props) => {
  const {
    white = false,
    hideNav = false,
    isHome = false,
    isSearch = false,
    isHomePage = false,
    greenBg = false,
    menuOpen,
    setMenuOpen,
  } = props;

  const { userDetails } = useUserDetails();

  const [submenu, setSubmenu] = useState({
    experiences: false,
    boatCharters: false,
    locations: false,
  });
  const [os, setOs] = useState('none');

  const router = useRouter();

  const isLoginOrSignup = router.pathname.includes('login') || router.pathname.includes('signup');

  const arrowUp = white ? '/images/up-arrow.svg' : '/images/up-arrow-light.svg';
  const arrowDown = white ? '/images/down-arrow.svg' : '/images/down-arrow-light.svg';

  const { first_name, id: userId, is_owner, is_boat_manager, is_captain, is_renter, is_admin } = userDetails;

  const userPicture = getProfilePicFromUser(userDetails);

  const bookings_default_path = () => {
    if (is_owner || is_boat_manager) {
      return `${boatsetterPaths.ownerDashboard}/bookings`;
    }
    if (is_captain) {
      return `${boatsetterPaths.captainDashboard}/bookings`;
    }
    return '/bookings';
  };

  useEffect(() => {
    const getMobileOperatingSystem = () => {
      const thisWindow: any = window;
      const userAgent = navigator.userAgent || navigator.vendor || thisWindow.opera;

      if (/android/i.test(userAgent)) {
        setOs('Android');
        return;
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !thisWindow.MSStream) {
        setOs('iOS');
      }
    };

    getMobileOperatingSystem();
  }, []);

  if (hideNav) {
    return null;
  }

  return (
    <div key="mmm" id="mmm">
      <CSSTransition in={menuOpen} timeout={300} classNames="NavOffCanvas-menu" unmountOnExit mountOnEnter>
        <MobileMenuWrapper
          className={`${white ? 'white-nav' : ''} ${greenBg ? 'bgDarkGreen' : ''} js-off-canvas-item`}
          textBlue={isHome && isSearch}
          textAqua={is_captain && !isHome && !isSearch}
          textGreen={is_owner && !isHome && !isSearch}
          data-testid="components-headers-mobile-menu-box"
        >
          {userId ? (
            <div>
              <MobileMenuUser href={`/users/${userId}`}>
                <div>
                  <UserName>Hi {first_name}</UserName>
                  <ViewProfile>View profile</ViewProfile>
                </div>
                <UserPicture src={userPicture} />
              </MobileMenuUser>
              {is_admin && (
                <MobileMenuList>
                  <MobileMenuItem>
                    <MobileMenuLink href="/admin">Admin</MobileMenuLink>
                  </MobileMenuItem>
                </MobileMenuList>
              )}
              {/* eslint-disable-next-line no-nested-ternary */}
              {is_owner || is_boat_manager || is_captain ? (
                <>
                  {(is_owner || is_boat_manager) && (
                    <MobileMenuList>
                      <MobileMenuItem>
                        <MobileMenuLink href={boatsetterPaths.ownerDashboard}>Owner dashboard</MobileMenuLink>
                      </MobileMenuItem>
                      <MobileMenuItem>
                        <MobileMenuLink href={boatsetterPaths.myBoats}>My boats</MobileMenuLink>
                      </MobileMenuItem>
                    </MobileMenuList>
                  )}
                  {is_captain && (
                    <MobileMenuList>
                      <MobileMenuItem>
                        <MobileMenuLink href={boatsetterPaths.captainDashboard}>Captain dashboard</MobileMenuLink>
                      </MobileMenuItem>
                    </MobileMenuList>
                  )}
                </>
              ) : is_renter ? (
                <MobileMenuList>
                  {/* TODO variant? */}
                  <MobileMenuItem>
                    <MobileMenuLink href={boatsetterPaths.renterDashboard}>Renter dashboard</MobileMenuLink>
                  </MobileMenuItem>
                </MobileMenuList>
              ) : null}

              {isSearch && (
                <MobileMenuList>
                  <MobileMenuItem>
                    <a>
                      <NavInbox userDetails={userDetails} />
                    </a>
                  </MobileMenuItem>
                  <MobileMenuItem>
                    <MobileMenuLink href={bookings_default_path()}>Bookings</MobileMenuLink>
                  </MobileMenuItem>
                </MobileMenuList>
              )}

              <MobileMenuList>
                <MobileMenuItem>
                  <MobileMenuLink href={boatsetterPaths.account}>My account</MobileMenuLink>
                </MobileMenuItem>
                <MobileMenuItem>
                  <MobileMenuLink href="/account/favorites">Favorites</MobileMenuLink>
                </MobileMenuItem>
                <MobileMenuItem>
                  <MobileMenuLink href={boatsetterPaths.listBoat}>List your boat</MobileMenuLink>
                </MobileMenuItem>
              </MobileMenuList>

              <MobileMenuList noBorder>
                <MobileMenuItem>
                  <MobileMenuLink href={`https://support.boatsetter.com?utm_source=${baseURL}&utm_medium=referral`}>
                    Support
                  </MobileMenuLink>
                </MobileMenuItem>
                <MobileMenuItem>
                  <Link passHref href={boatsetterPaths.logOut} legacyBehavior>
                    <MobileMenuLink rel="nofollow" onClick={() => setMenuOpen(false)} data-method="delete">
                      Logout
                    </MobileMenuLink>
                  </Link>
                </MobileMenuItem>
              </MobileMenuList>
            </div>
          ) : (
            <MobileMenuList noUser>
              <MobileMenuItem>
                <Link passHref href={boatsetterPaths.listBoat} legacyBehavior>
                  <MobileMenuLink textBlueOnly onClick={() => setMenuOpen(false)}>
                    List your boat
                  </MobileMenuLink>
                </Link>
              </MobileMenuItem>
              <MobileMenuItem>
                <MobileMenuLink textBlueOnly href={boatsetterPaths.aboutUs}>
                  About us
                </MobileMenuLink>
              </MobileMenuItem>
              <MobileMenuItem>
                <MobileMenuLink
                  textBlueOnly
                  href={`https://support.boatsetter.com?utm_source=${baseURL}&utm_medium=referral`}
                >
                  Support
                </MobileMenuLink>
              </MobileMenuItem>
              {!isHome && (
                <>
                  <MobileMenuItem>
                    <Link passHref href="#login" legacyBehavior>
                      <MobileMenuLink
                        textBlueOnly
                        onClick={() => {
                          router.push({
                            pathname: boatsetterPaths.logIn,
                            query: {
                              redirect_to: isLoginOrSignup ? '' : router.asPath,
                            },
                          });
                          setMenuOpen(false);
                        }}
                      >
                        Log in
                      </MobileMenuLink>
                    </Link>
                  </MobileMenuItem>
                  <MobileMenuItem>
                    <Link passHref href="#signup" legacyBehavior>
                      <MobileMenuLink
                        textBlueOnly
                        onClick={() => {
                          router.push({
                            pathname: boatsetterPaths.signUp,
                            query: {
                              redirect_to: isLoginOrSignup ? '' : router.asPath,
                            },
                          });
                          setMenuOpen(false);
                        }}
                      >
                        Sign up
                      </MobileMenuLink>
                    </Link>
                  </MobileMenuItem>
                </>
              )}
            </MobileMenuList>
          )}
          {isHomePage && (
            <div>
              {Object.keys(Submenus).map((key) => (
                <MobileSubmenuList noBorder key={key} open={submenu[key]}>
                  <MobileMenuItem>
                    <SubmenuToggle
                      onClick={() => {
                        setSubmenu({
                          ...submenu,
                          [key]: !submenu[key],
                        });
                      }}
                    >
                      <MobileMenuLink textBlueOnly>{SubmenuNames[key]}</MobileMenuLink>
                      <img src={submenu[key] ? arrowUp : arrowDown} alt="" />
                    </SubmenuToggle>
                    <MobileMenuList variant noBorder open={submenu[key]}>
                      {Submenus[key].map((submenuItem) => (
                        <MobileMenuItem key={submenuItem.name}>
                          <MobileMenuLink textBlueOnly href={`${submenuItem.link}`}>
                            {submenuItem.name}
                          </MobileMenuLink>
                        </MobileMenuItem>
                      ))}
                    </MobileMenuList>
                  </MobileMenuItem>
                </MobileSubmenuList>
              ))}
            </div>
          )}
          {is_owner ? (
            <Apps>
              <div className="bsApp">
                <div>
                  <p className="smallHeading">The Boatsetter Owner App</p>
                  <p className="description">Manage your boat on the go</p>
                </div>
              </div>
              <div className="download">
                {os === 'iOS' && (
                  <a
                    href="https://apps.apple.com/app/apple-store/id1369215908?pt=43037800&ct=Ownerapp_nav&mt=8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AppStore height={45} width={145} />
                  </a>
                )}
                {os === 'Android' && (
                  <a
                    href="https://play.google.com/store/apps/details?id=com.boatsetter.app&referrer=utm_source%3Dboatsetter.com%26utm_campaign%3DOwnerapp_nav"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GooglePlay height={45} width={145} />
                  </a>
                )}
              </div>
            </Apps>
          ) : (
            <Apps>
              <div className="bsApp">
                <div>
                  <p className="smallHeading">The Boatsetter App</p>
                  <p className="description">Find and book boats in over 600+ locations</p>
                </div>
              </div>
              <div className="download">
                {os === 'iOS' && (
                  <a
                    href="https://apps.apple.com/app/apple-store/id1449873080?pt=43037800&ct=Renterapp_nav&mt=8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AppStore height={45} width={145} />
                  </a>
                )}
                {os === 'Android' && (
                  <a
                    href="https://play.google.com/store/apps/details?id=com.boatsetterrenters&referrer=utm_source%3Dboatsetter.com%26utm_campaign%3DRenterapp_nav"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GooglePlay height={45} width={145} />
                  </a>
                )}
              </div>
            </Apps>
          )}
        </MobileMenuWrapper>
      </CSSTransition>
    </div>
  );
};

export default MobileMenu;

const SubmenuToggle = styled.div`
  display: flex;
  img {
    margin-left: auto;
  }
`;

export const MobileMenuUser = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0;
  margin: 0 auto 1em auto;
  width: calc(100% - 3em);
  border-bottom: 1px solid #eaebeb;
`;

export const UserName = styled.div`
  line-height: 1.7;
  font-weight: 500;
  font-size: 16px;
`;

export const ViewProfile = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
`;

export const UserPicture = styled.img`
  border-radius: 50%;
  width: 2.3em;
  height: 2.3em;
`;
export const MobileMenuLink = styled.a<{ textBlueOnly?: boolean }>`
  color: ${({ textBlueOnly }) => textBlueOnly && '#0A4195'};
`;

export const MobileMenuList = styled.ul<{ noBorder?: boolean; variant?: boolean; open?: boolean; noUser?: boolean }>`
  margin: 0 auto 0.8em auto;
  padding: 0 0 12px 0;
  width: calc(100% - 3em);
  border-bottom: 1px solid #ededed;

  ${({ noBorder }) =>
    noBorder &&
    css`
      border-bottom: none !important;
    `}

  ${({ variant, open }) =>
    variant &&
    css`
      margin: 0 auto 0 auto;
      height: 0;
      transform: matrix(0, 0, 0, 0, 180, 0);

      ${open &&
      css`
        height: unset;
        transform: unset;
      `}

      ${MobileMenuItem} {
        padding: 0.2em 0;
        &:first-child {
          padding-top: 0.5em;
        }
      }
    `}

  ${({ noUser }) =>
    noUser &&
    css`
      margin-bottom: 1em;

      ${MobileMenuItem} {
        padding: 0.8em 0;
      }
    `};
`;

const getColor = (textAqua, textGreen) => {
  if (textAqua) {
    return '#00677D';
  }
  if (textGreen) {
    return '#0E7873';
  }
  return '';
};

const getMobileColor = (textBlue, textAqua, textGreen) => {
  if (textBlue) {
    return '#0A4195';
  }
  if (textAqua) {
    return '#00677D';
  }
  if (textGreen) {
    return '#0E7873 !important';
  }
  return '';
};

export const MobileMenuWrapper = styled.div<{ textBlue: boolean; textAqua: boolean; textGreen: boolean }>`
  height: calc(100% - 54px);

  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 54px;
  left: auto;
  right: 0;
  width: 100%;
  background-color: #fff;
  z-index: 9999;
  padding-bottom: 200px;

  @media ${mediaSmViewport} {
    display: block;
  }

  ${({ textBlue, textAqua, textGreen }) => css`
    ${MobileMenuLink} {
      color: ${getMobileColor(textBlue, textAqua, textGreen)};
    }
    ${UserName} {
      color: ${getColor(textAqua, textGreen)};
    }
    ${ViewProfile} {
      color: ${getColor(textAqua, textGreen)};
    }
  `};

  &.NavOffCanvas-menu-enter {
    transform: translateX(100%);
  }
  &.NavOffCanvas-menu-enter-active {
    transform: translateX(0);
    transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &.NavOffCanvas-menu-exit {
    transform: translateX(0);
    transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &.NavOffCanvas-menu-exit-active {
    transform: translateX(100%);
    transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &.white-nav {
    @media ${mediaMdMax} {
      top: 60px;
      height: calc(100vh - 47px - 13px);
      height: calc((var(--vh, 1vh) * 100) - 47px - 13px);
    }

    @media ${mediaSmMax} {
      top: 54px;
      height: calc(100vh - 47px - 7px);
      height: calc((var(--vh, 1vh) * 100) - 47px - 7px);
    }

    top: 59px;
    background-color: white;
    position: absolute;

    li a {
      font-weight: 500;
      font-size: 16px;

      span {
        font-size: 16px;
      }
    }

    .mobile-menu-top {
      justify-content: space-between;
    }
  }

  &.bgDarkGreen {
    background-color: #0e7873;

    ${MobileMenuLink}, ${MobileMenuUser}, .smallHeading, .description {
      color: #ffffff !important;
    }

    ${MobileMenuList}, ${MobileMenuUser} {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
`;

export const MobileMenuItem = styled.li`
  padding: 0.5em 0;

  a {
    line-height: 1.714285;
    font-weight: 500;
    font-size: 16px;
    color: #0a4195 !important;
  }
`;

const MobileSubmenuList = styled(MobileMenuList)`
  border-bottom: 1px solid #ededed;
  margin: 0 auto 0 auto;

  & > ${MobileMenuItem} {
    height: 30px;
  }

  ${({ open }) =>
    open &&
    css`
      & > ${MobileMenuItem} {
        height: unset;
      }
    `}
`;

export const Apps = styled.div`
  width: calc(100% - 3em);
  margin: 0 auto;

  border-top: 1px solid rgba(142, 150, 151, 0.2);
  padding-top: 30px;
  margin-top: 40px;

  .download {
    display: flex;

    img {
      @media ${mediaLgMax} {
        width: 125px;
      }
    }
    .google {
      padding-left: 15px;
    }
  }
  .bsApp {
    display: flex;
    padding-top: 30px;
    padding-bottom: 15px;

    @media ${mediaLgMax} {
      padding-top: 0;
    }
    @media ${mediaSmMax} {
      padding-top: 25px;
    }
    div {
      display: flex;
      flex-flow: column;
      margin: auto 0;

      p {
        margin-bottom: 0;
      }
      .description {
        color: #17233c;
        font-size: 14px;
        line-height: 20px;
        opacity: 0.6;
      }
      .smallHeading {
        font-weight: 600;
        padding-bottom: 5px;
        font-size: 16px;
        line-height: 20px;
        color: #17233c;
      }
    }
  }
`;
