import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { comingFromLogoutAtom, failedLoginAtom } from 'auth/userStore';
import { useRouter } from 'next/router';
import boatsetterPaths from 'utils/boatsetterPaths';

export const useProtectedUrlGuard = (isProtected: boolean) => {
  const [comingFromLogout, setComingFromLogout] = useAtom(comingFromLogoutAtom);
  const [loginFailed, setLoginFailed] = useAtom(failedLoginAtom);
  const router = useRouter();

  useEffect(() => {
    // if page is not protected don't redirect
    if (!isProtected) return;

    // if page is redirected and user failed login redirect to login
    if (loginFailed) {
      setLoginFailed(false);
      router.push({
        pathname: boatsetterPaths.logIn,
        query: {
          redirect_to: `${window?.location?.pathname}${window?.location?.search}`,
        },
      });
    }

    // if user came to auth only page from logout redirect, redirect to homepage
    if (comingFromLogout) {
      setComingFromLogout(false);
      router.push('/');
    }
  }, [isProtected, comingFromLogout, loginFailed]);
};
