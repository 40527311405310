/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const InsuranceCustomizations = {
    bimini_top_or_other_canvas: 'bimini_top_or_other_canvas',
    electronic_equipment: 'electronic_equipment',
    hull_configuration_change_not_to_spec: 'hull_configuration_change_not_to_spec',
    engine_horsepower_change_not_to_spec: 'engine_horsepower_change_not_to_spec'
} as const;

export type InsuranceCustomizations = typeof InsuranceCustomizations[keyof typeof InsuranceCustomizations];



