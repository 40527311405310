import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Returns the current users dayjs timezone in the form of a string.
 * Examples:
 * '-05:00' for Eastern Standard Time (EST): 'America/New_York'
 * '-06:00' for Central Standard Time (CST): 'America/Chicago'
 * '-07:00' for Mountain Standard Time (MST): 'America/Denver'
 * '-08:00' for Pacific Standard Time (PST): 'America/Los_Angeles'
 */
const getUserTimezone = () => dayjs.tz.guess();

/**
 * Standardizes the current time to the specified timezone.
 * This step is important because we want to ensure the time of the search / boat and
 * not the timezone of the user's browser or the server.
 *
 * @param {string} tz - The timezone identifier.
 * @returns {dayjs.Dayjs} - The current time in the specified timezone.
 */
const standardizeLocalTime = (tz: string) => {
  const now = dayjs.tz(dayjs(), 'UTC');
  const nowLocalTime = dayjs.tz(now, tz);
  return nowLocalTime;
};

/**
 * Determines if the provided local time is at or after 2 PM.
 * Example Timezones:
 * UTC: 'UTC' (Default)
 * Eastern Standard Time (EST): 'America/New_York'
 * Central Standard Time (CST): 'America/Chicago'
 * Mountain Standard Time (MST): 'America/Denver'
 * Pacific Standard Time (PST): 'America/Los_Angeles'
 *
 * @param {dayjs.Dayjs} localTime - The local time to check.
 * @returns {boolean} - True if the local time is at or after 2 PM, otherwise false.
 */
const isLocalTimeAtOrAfter2pm = (localTime: dayjs.Dayjs) => {
  const hour = localTime.hour();
  return hour >= 14;
};

/**
 * Gets the earliest weekend date based on the current date and time in the given timezone.
 *
 * @param {string} tz - The timezone identifier.
 * @returns {dayjs.Dayjs} - The earliest weekend date.
 */
const earliestWeekend = (tz: string) => {
  const localTime = standardizeLocalTime(tz);
  const dayOfWeek = localTime.day();

  if (dayOfWeek === 6) {
    // Return Sunday if it's on or after 2 pm
    if (isLocalTimeAtOrAfter2pm(localTime)) {
      return localTime.add(1, 'day');
    }
    // Saturday
    return localTime;
  }
  if (dayOfWeek === 0) {
    // Return next Saturday if it's on or after 2 pm
    if (isLocalTimeAtOrAfter2pm(localTime)) {
      return localTime.add(6, 'day');
    }
    // Sunday
    return localTime;
  }
  // Weekdays return the next Saturday
  const daysUntilSaturday = 6 - dayOfWeek;
  return localTime.add(daysUntilSaturday, 'day');
};

/**
 * Gets the default start date based on the provided options.
 *
 * @param {Object} options - The options for determining the default start date.
 * @param {string} [options.timeZone='UTC'] - The timezone identifier.
 * @returns {dayjs.Dayjs} - The default start date.
 */
const getDefaultStartDate = ({ timeZone = null } = {}) => earliestWeekend(timeZone || getUserTimezone());

export default getDefaultStartDate;
