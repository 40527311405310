/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BookingPrerequisite } from './booking-prerequisite';
import { InsuranceRenterPolicy } from './insurance-renter-policy';

/**
 * 
 * @export
 * @interface TripCalculator
 */
export interface TripCalculator {
    /**
     * ISO8601 date
     * @type {string}
     * @memberof TripCalculator
     */
    'trip_start'?: string;
    /**
     * ISO8601 date
     * @type {string}
     * @memberof TripCalculator
     */
    'trip_finish'?: string;
    /**
     * 
     * @type {string}
     * @memberof TripCalculator
     */
    'free_cancelation_until'?: string;
    /**
     * HH:MM trip start time
     * @type {string}
     * @memberof TripCalculator
     */
    'trip_start_time'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TripCalculator
     */
    'trip_start_time_modifiable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'package_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof TripCalculator
     */
    'currency_code'?: string;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'boating_credits'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'boat_pass_price_adjustment'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'boat_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'service_fee'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'owner_price_adjustment'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'captain_fee'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'captain_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'renter_add_on_total'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'owner_add_on_total'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'pre_tax_and_fees_booking_total'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'rental_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'date_price_adjustment'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'security_deposit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'towing_cost'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'coupon_price_adjustment'?: number;
    /**
     * The renter portion of the insurance fee, in cents
     * @type {number}
     * @memberof TripCalculator
     */
    'renter_insurance_fee'?: number;
    /**
     * The renter portion of the insurance tax, in cents
     * @type {number}
     * @memberof TripCalculator
     */
    'renter_insurance_tax'?: number;
    /**
     * insurance policy URL for insurance
     * @type {string}
     * @memberof TripCalculator
     */
    'insurance_policy_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof TripCalculator
     */
    'duration'?: string;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'sales_tax'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'passengers'?: number;
    /**
     * 
     * @type {string}
     * @memberof TripCalculator
     */
    'package_type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TripCalculator
     */
    'is_instant_book'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TripCalculator
     */
    'fuel_policy'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TripCalculator
     */
    'is_boat_available'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TripCalculator
     */
    'is_captain_available'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'max_passengers'?: number;
    /**
     * 
     * @type {Array<BookingPrerequisite>}
     * @memberof TripCalculator
     */
    'prerequisites'?: Array<BookingPrerequisite>;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'owner_listing_fee'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'booking_total'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'amount_to_be_charged'?: number;
    /**
     * 
     * @type {number}
     * @memberof TripCalculator
     */
    'amount_to_be_collected_at_dock'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TripCalculator
     */
    'display_captain_price_as_tbd'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TripCalculator
     */
    'captain_payment_collection_at_dock'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TripCalculator
     */
    'insurance_type'?: TripCalculatorInsuranceTypeEnum;
    /**
     * 
     * @type {InsuranceRenterPolicy}
     * @memberof TripCalculator
     */
    'insurance_renter_policy'?: InsuranceRenterPolicy;
}

export const TripCalculatorInsuranceTypeEnum = {
    commercial: 'commercial',
    p2p: 'p2p'
} as const;

export type TripCalculatorInsuranceTypeEnum = typeof TripCalculatorInsuranceTypeEnum[keyof typeof TripCalculatorInsuranceTypeEnum];


