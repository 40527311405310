import { useRouter } from 'next/router';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { StartTimeApi } from 'swagger/apis/start-time-api';
import OpenApiConfiguration from 'api/OpenApiConfiguration';
import BoatsetterOpenApiConfiguration from 'api/BoatsetterOpenApiConfiguration';
import { ListingsApi, Trim } from '@boatsetter/typescript-axios';
import { BoatsApi } from 'swagger/apis/boats-api';
import {
  DomesticV2BoatsBoatIdInsuranceGetRequest,
  DomesticV2BoatsBoatIdPhotosIdDeleteRequest,
  DomesticV2BoatsBoatIdCoiPutRequest,
  DomesticV2BoatsBoatIdAddressesGetRequest,
  DomesticV2BoatsBoatIdPackagesGetRequest,
  BoatInsertOrUpdate,
  InsuranceScreeningAnswersBoat,
  InsuranceScreeningAnswersOwner,
  BoatModel,
} from 'swagger/models';
import { BoatAddressesApi } from 'swagger/apis/boat-addresses-api';
import { FeatureCategoryApi } from 'swagger/apis/feature-category-api';
import { CustomQueryOptions } from 'stacks/user/queryHooks';
import { InsurersApi } from 'swagger/apis/insurers-api';
import { useGetBoatAvailableInsurancesQuery } from 'components/pdp/v3/hooks';
import useLazySnackbar from 'components/banners/useLazySnackbar';
import { AxiosError } from 'axios';
import type { AxiosResponse, InternalAxiosRequestConfig } from '@boatsetter/typescript-axios/node_modules/axios';
import { useSyncMutation, getIsBuoyEligible } from './utils';

const startTimes = new StartTimeApi(OpenApiConfiguration);
const boatsApi = new BoatsApi(OpenApiConfiguration);
const trimsApi = new ListingsApi(BoatsetterOpenApiConfiguration);
const boatAddressesApi = new BoatAddressesApi(OpenApiConfiguration);
const featureCategoryApi = new FeatureCategoryApi(OpenApiConfiguration);
const insurersApi = new InsurersApi(OpenApiConfiguration);

interface DomesticV2BoatsBoatIdSubmitForApprovalPostRequest {
  insurer?: string;
}

export const useBoatMakesQuery = (year?: number) => {
  const { data: { data: boatMakes } = {}, ...rest } = useQuery(
    ['boatMakes', year],
    () => trimsApi.listingsMakesGet(year, 'boat'),
    {
      enabled: !!year,
    }
  );

  return {
    boatMakes,
    ...rest,
  };
};

export const useBoatModelsQuery = (make: string, year?: number) => {
  const { data: { data: boatModels } = {}, ...rest } = useQuery(
    ['boatModels', year, make],
    () => callListingsModelTrims(year, make),
    {
      enabled: !!make && !!year,
    }
  );

  return {
    boatModels,
    ...rest,
  };
};

const callListingsModelTrims = async (year: number, make: string): Promise<AxiosResponse<BoatModel[], any>> => {
  const trimsPromise = trimsApi.listingsModelTrimsGet(year, make, 'boat');
  const boatModelTrims = await formatTrimsToBoatModelTrims(trimsPromise);
  return formatBoatModelsToAxiosResponse(boatModelTrims);
};

const formatBoatModelsToAxiosResponse = (boatModels: BoatModel[]): AxiosResponse<BoatModel[], any> => ({
  data: boatModels,
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {} as InternalAxiosRequestConfig,
});

const formatTrimsToBoatModelTrims = async (trimsPromise: Promise<AxiosResponse<Trim[], any>>): Promise<BoatModel[]> => {
  const response = await trimsPromise;
  const trims = response.data;

  // Strip out any trim that don't have a meta.abosTrimID
  return trims
    .filter((trim) => trim?.meta?.abosTrimID)
    .map((trim) => ({
      id: trim.meta.abosTrimID,
      trim_id: trim.id,
      make: trim.make,
      model: trim.model,
      year: trim.year,
      length: trim?.specifications?.length,
      hull_material: trim?.specifications?.hullMaterial,
      engine_horsepower: trim?.specifications?.engineHorsepower,
      engine_quantity: trim?.specifications?.engineQuantity,
      fuel_type: trim?.specifications?.fuelType,
      engine_type: trim?.specifications?.engineType,
      boat_type: trim?.specifications?.boatType,
    }));
};

export const useCreateBoatMutation = () =>
  useMutation(({ body }: { body: BoatInsertOrUpdate }) => boatsApi.domesticV2BoatsPost(body));

export const useCreatePackageMutation = (invalidate = true) => {
  const queryClient = useQueryClient();

  return useSyncMutation(
    ({ boatId, body }: { boatId: string; body: DomesticV2BoatsBoatIdPackagesGetRequest }) =>
      boatsApi.domesticV2BoatsBoatIdPackagesPost(boatId, body),
    {
      onSuccess: async (_, { boatId }) => {
        if (invalidate) {
          await queryClient.invalidateQueries(['boatPackages', boatId]);
        }
      },
    }
  );
};

export const useUpdatePackageMutation = (invalidate = true) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      boatId,
      body,
      packageId,
    }: {
      boatId: string;
      body: DomesticV2BoatsBoatIdPackagesGetRequest;
      packageId: string;
      // @ts-ignore
    }) => boatsApi.domesticV2BoatsBoatIdPackagesIdPatch(boatId, packageId, body),
    {
      onSuccess: async (_, { boatId }) => {
        if (invalidate) {
          await queryClient.invalidateQueries(['boatPackages', boatId]);
        }
      },
    }
  );
};

export const useGetBoatAddressesQuery = (boatId: string) => {
  const { data: { data: boatAddresses } = {}, ...rest } = useQuery(
    ['boatAddresses', boatId],
    () => boatAddressesApi.domesticV2BoatsBoatIdAddressesGet(boatId),
    {
      enabled: !!boatId,
    }
  );

  return {
    boatAddresses,
    ...rest,
  };
};

export const useGetBoatPhotosQuery = (boatId: string) => {
  const { data: { data: boatPhotos } = {}, ...rest } = useQuery(
    ['boatPhotos', boatId],
    () => boatsApi.domesticV2BoatsIdPhotosGet(boatId),
    {
      enabled: !!boatId,
    }
  );

  return {
    boatPhotos,
    ...rest,
  };
};

export const useCreateBoatAddressMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ boatId, body }: { boatId: string; body: DomesticV2BoatsBoatIdAddressesGetRequest }) =>
      boatAddressesApi.domesticV2BoatsBoatIdAddressesPost(boatId, body),
    {
      onSuccess: async (_, { boatId }) => {
        await queryClient.invalidateQueries(['boatAddresses', boatId]);
        await queryClient.invalidateQueries(['boatDetail', boatId, null]);
      },
    }
  );
};

export const useInsuranceQuestionsQuery = (
  {
    insurerId,
    targetTypes,
    boatId = null,
  }: {
    insurerId: 'buoy' | 'boat_us';
    targetTypes: ('renter' | 'owner' | 'boat')[];
    boatId?: string;
  },
  options?: CustomQueryOptions<typeof insurersApi.domesticV2InsurersInsurerIdQuestionsGet>
) => {
  const isEnabled =
    (options?.enabled ?? true) && targetTypes.length > 0 && (targetTypes.includes('boat') ? !!boatId : true);

  const { data: { data: insuranceScreening } = {}, ...rest } = useQuery(
    ['insuranceQuestions', insurerId, targetTypes, boatId],
    () =>
      insurersApi.domesticV2InsurersInsurerIdQuestionsGet(insurerId, [], boatId, {
        params: { target_types: targetTypes },
      }),
    {
      ...options,
      enabled: isEnabled,
    }
  );

  return {
    insuranceScreening,
    ...rest,
  };
};

export const useInsuranceAnswersMutation = () =>
  useMutation(
    ({
      insurerId,
      boat,
      owner,
    }: {
      insurerId: 'buoy' | 'boat_us';
      boat?: InsuranceScreeningAnswersBoat;
      owner?: InsuranceScreeningAnswersOwner;
    }) => insurersApi.domesticV2InsurersInsurerIdAnswersPut(insurerId, { boat, owner })
  );

export const useInsuranceSubmitMutation = () =>
  useMutation(({ insurer, boatId }: { insurer: 'buoy' | 'boat_us'; boatId?: string }) =>
    boatsApi.domesticV3BoatsBoatIdSubmitToInsurancePost(boatId, { insurer })
  );

export const useMyInsurersQuery = (options?: CustomQueryOptions<typeof insurersApi.domesticV2MeInsurersGet>) => {
  const { data: { data: { insurers } = {} } = {}, ...other } = useQuery(
    ['myInsurers'],
    () => insurersApi.domesticV2MeInsurersGet(),
    options
  );
  return {
    myInsurers: insurers,
    ...other,
  };
};

export const useJustFilledOwnerInsuranceQuestions = () => {
  const { myInsurers } = useMyInsurersQuery();
  const ownerBuoyInsurer = myInsurers?.find((insurer) => insurer.name === 'buoy' && insurer.target === 'owner');

  const { insuranceScreening } = useInsuranceQuestionsQuery({
    insurerId: 'buoy',
    targetTypes: ['owner'],
  });

  const justFilledOwnerInsuranceQuestions = insuranceScreening?.owner?.complete && !ownerBuoyInsurer;

  return justFilledOwnerInsuranceQuestions;
};

export const useUpdateBoatAddressMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      boatId,
      addressId,
      body,
    }: {
      boatId: string;
      addressId: string;
      body: DomesticV2BoatsBoatIdAddressesGetRequest;
    }) => boatAddressesApi.domesticV2BoatsBoatIdAddressesIdPatch(boatId, addressId, body),
    {
      onSuccess: async (_, { boatId }) => {
        await queryClient.invalidateQueries(['boatAddresses', boatId]);
        await queryClient.invalidateQueries(['boatDetail', boatId]);
      },
    }
  );
};

export const useAllBoatFeaturesQuery = (boatLength?: string) => {
  const { data: { data: allBoatFeatures } = {}, ...rest } = useQuery(['allBoatFeatures'], () =>
    featureCategoryApi.domesticV2FeatureCategoriesGet(boatLength)
  );

  return {
    allBoatFeatures,
    ...rest,
  };
};

export const useUpdateBoatMutation = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useLazySnackbar();

  return useMutation(
    ({ boatId, body }: { boatId: string; body: BoatInsertOrUpdate }) => boatsApi.domesticV2BoatsIdPatch(boatId, body),
    {
      onSuccess: async (_, { boatId }) => {
        await queryClient.invalidateQueries(['boatDetail', boatId, null]);
        await queryClient.invalidateQueries(['boatAddresses', boatId]);
        await queryClient.invalidateQueries(['boatPhotos', boatId]);
        await queryClient.invalidateQueries(['boatInsurance', boatId]);
        await queryClient.invalidateQueries(['boatPackages', boatId]);
      },
      onError: (_: AxiosError<any>, variables) => {
        if (_.response.data.data.boat_registration_number.includes('has already been taken')) {
          enqueueSnackbar('Boat registration number is already in use.', { variant: 'error', autoHideDuration: 6000 });
          return;
        }
        enqueueSnackbar(
          variables.body.listing_tagline || variables.body.listing_description
            ? 'To protect users, messages that contain phone numbers, emails or website links are not allowed.'
            : 'There was a problem updating your boat. Please contact info@boatsetter.com',
          { variant: 'error', autoHideDuration: 6000 }
        );
      },
    }
  );
};

export const useEstimatedEarningsQuery = ({
  make,
  boatModel,
  yearManufactured,
  latitude,
  longitude,
  countryCode,
  state,
}) => {
  const router = useRouter();

  // only enable query if user is on BLW page and has filled out required fields
  const isBLW = router.pathname.includes('/list-boat');
  const earningCalcEnabled = isBLW && !!(make && boatModel && yearManufactured);

  return useQuery(
    ['estimatedEarnings', make, boatModel, yearManufactured, latitude, longitude, countryCode, state],
    () =>
      boatsApi.domesticV2BoatsEstimatedEarningsCalculatorGet(
        make,
        boatModel,
        yearManufactured,
        latitude,
        longitude,
        countryCode,
        state
      ),
    {
      enabled: earningCalcEnabled,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    }
  );
};

export const useSubmitBoatForApprovalMutation = () => {
  const { enqueueSnackbar } = useLazySnackbar();

  return useMutation(
    ({ boatId, body }: { boatId: string; body: DomesticV2BoatsBoatIdSubmitForApprovalPostRequest }) =>
      boatsApi.domesticV2BoatsBoatIdSubmitForApprovalPost(boatId, body),
    {
      onError: () => {
        enqueueSnackbar('There was an issue submitting your boat. Please contact support for help.', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      },
    }
  );
};

export const useUploadCOIMutation = () => {
  const { enqueueSnackbar } = useLazySnackbar();

  return useMutation(
    ({ boatId, body }: { boatId: string; body: DomesticV2BoatsBoatIdCoiPutRequest }) =>
      // @ts-ignore
      boatsApi.domesticV2BoatsBoatIdCoiUploadPost(boatId, body),
    {
      onError: () => {
        enqueueSnackbar('There was a problem uploading your file. Please contact support for help.', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      },
      onSuccess: () => {
        enqueueSnackbar('Your COI file was successfully uploaded.', { variant: 'success', autoHideDuration: 5000 });
      },
    }
  );
};

export const useDeleteCoiMutation = () => {
  const { enqueueSnackbar } = useLazySnackbar();

  return useMutation(({ boatId }: { boatId: string }) => boatsApi.domesticV2BoatsBoatIdCoiDeleteDelete(boatId), {
    onSuccess: () => {
      enqueueSnackbar('Your COI file was successfully deleted.', { variant: 'success', autoHideDuration: 5000 });
    },
  });
};

export const useCreateInsuranceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ boatId, body }: { boatId: string; body: DomesticV2BoatsBoatIdInsuranceGetRequest }) =>
      boatsApi.domesticV2BoatsBoatIdInsurancePost(boatId, body),
    {
      onSettled: async (_, __, { boatId }) => {
        await queryClient.invalidateQueries(['boatInsurance', boatId]);
      },
    }
  );
};

export const useGetBoatInsuranceQuery = (boatId: string) => {
  const { data: { data: boatInsurance } = {}, ...rest } = useQuery(
    ['boatInsurance', boatId],
    () => boatsApi.domesticV2BoatsBoatIdInsuranceGet(boatId),
    {
      enabled: !!boatId,
    }
  );

  return {
    boatInsurance,
    ...rest,
  };
};

export const useCreateBoatPhotoMutation = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useLazySnackbar();

  return useMutation(
    ({ boatId, body }: { boatId: string; body: any }) =>
      boatsApi.domesticV2BoatsIdPhotosPost(boatId, body, {
        headers: {
          Accept: 'application/json',
        },
      }),
    {
      onSettled: async (_, __, { boatId }) => {
        await queryClient.invalidateQueries(['boatPhotos', boatId]);
      },
      onError: () => {
        enqueueSnackbar('There was a problem processing your request. Please try again', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      },
    }
  );
};

export const useDeleteBoatPhotoMutation = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useLazySnackbar();

  return useMutation(
    ({ boatId, photoId }: { boatId: string; photoId: string }) =>
      boatsApi.domesticV2BoatsBoatIdPhotosIdDelete(boatId, photoId),
    {
      onSettled: async (_, __, { boatId }) => {
        await queryClient.invalidateQueries(['boatPhotos', boatId]);
      },
      onError: () => {
        enqueueSnackbar('There was a problem processing your request. Please try again', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      },
    }
  );
};

export const useUpdateBoatPhotoMutation = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useLazySnackbar();

  return useMutation(
    ({
      boatId,
      photoId,
      boatPhoto,
    }: {
      boatId: string;
      photoId: string;
      boatPhoto: DomesticV2BoatsBoatIdPhotosIdDeleteRequest;
    }) => boatsApi.domesticV2BoatsBoatIdPhotosIdPatch(boatId, photoId, boatPhoto),
    {
      onSettled: async (_, __, { boatId }) => {
        await queryClient.invalidateQueries(['boatPhotos', boatId]);
      },
      onError: () => {
        enqueueSnackbar('There was a problem processing your request. Please try again', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      },
    }
  );
};

export const useUpdateStartTimesMutation = () =>
  useMutation(({ boatId, body }: { boatId: string; body: { [key: string]: string[] } }) =>
    startTimes.domesticV2BoatsBoatIdStartTimesPatch(boatId, {
      start_times: body,
    })
  );

export const useSetStartTimesToDefault = () =>
  useMutation((boatId: string) => startTimes.domesticV2BoatsBoatIdStartTimesSetDefaultPatch(boatId));

export const useIsBoatBuoyEligible = (boatId: string) => {
  const { boatAvailableInsurances, isLoading, isIdle } = useGetBoatAvailableInsurancesQuery(boatId);
  return isLoading || isIdle ? false : getIsBuoyEligible(boatAvailableInsurances);
};

export const useBuoyInsuranceInfo = (boatId: string) => {
  const { boatAvailableInsurances, isLoading, isIdle } = useGetBoatAvailableInsurancesQuery(boatId);
  return isLoading || isIdle
    ? { data: {}, isLoading }
    : {
        data: boatAvailableInsurances?.providers?.find((p) => p.name === 'buoy'),
        isLoading,
      };
};
