import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ColoredLink, LinkWrap } from 'components/header/shareComponents';
import { SubmenuNames, Submenus } from '../homepage/menuUtils';

const ExtraDropdownMenu = ({ isAdmin, isCaptain, isAnonymous }) => {
  const [active, setActive] = useState(false);

  const toggleMenu = () => {
    setActive(!active);
  };

  function useOutsideAlerter(ref) {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref && ref.current && !ref.current.contains(event.target) && active === true) {
        setActive(false);
      }
    }

    useEffect(() => {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    });
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <DropdownWrapper ref={wrapperRef}>
      <LinkWrap>
        <ColoredLink as="button" type="button" onClick={() => toggleMenu()}>
          <a>Experiences</a>
        </ColoredLink>
      </LinkWrap>
      <Dropdown
        $active={active}
        className={`${!isAdmin && !isAnonymous ? 'notAdmin' : ''} ${isAdmin ? 'isAdmin' : ''}} ${isCaptain}`}
      >
        <Columns>
          {Object.keys(Submenus).map((key, i) => (
            <ul key={`heading-${key}'`}>
              <SectionHeading>{SubmenuNames[key]}</SectionHeading>
              {Submenus[key].map((submenuItem, subSection) => (
                <Item
                  key={submenuItem.link}
                  className={[
                    subSection === Submenus[key].length - 1 ? `last ${key}` : '',
                    i === 2 && subSection > 0 ? 'biggerPadding' : '',
                  ].join(' ')}
                >
                  <Link href={submenuItem.link}>{submenuItem.name}</Link>
                </Item>
              ))}
            </ul>
          ))}
        </Columns>
      </Dropdown>
    </DropdownWrapper>
  );
};

const Item = styled.li`
  min-width: 180px;
  margin-right: 45px;
  padding-bottom: 8px;

  &.last.experiences {
    padding-bottom: 100px;
  }
`;

const Link = styled.a`
  color: #0a4195 !important;
`;
const SectionHeading = styled.div`
  color: #0a4195;
  font-weight: 700;
  padding-bottom: 10px;
`;

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 435px;
  flex-direction: column;
`;

const Dropdown = styled.div<{ $active: boolean }>`
  position: absolute;
  z-index: 100000;
  background: white;
  width: 700px;
  max-height: 500px;
  padding: 35px;
  border-radius: 6px;
  margin-top: 12px;
  top: 112%;
  display: ${({ $active }) => ($active ? 'block' : 'none')};

  &.notAdmin {
    right: -230px;
    &::before {
      right: 33.4%;
    }
  }

  &.isAdmin {
    right: -375px;
    &::before {
      right: 54.1%;
    }
  }

  right: -400px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.25);

  &::before {
    right: 57.7%;
    top: -10px;
    display: inline-block;
    position: absolute;
    bottom: auto;
    content: '';
    border: 10px solid transparent;
    border-top: none;
    border-bottom: 10px solid white;
  }
`;

const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

ExtraDropdownMenu.defaultProps = {
  showLogout: false,
};

export default ExtraDropdownMenu;
