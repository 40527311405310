/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DriveVerificationMovingViolation
 */
export interface DriveVerificationMovingViolation {
    /**
     * 
     * @type {string}
     * @memberof DriveVerificationMovingViolation
     */
    'moving_violation_type': DriveVerificationMovingViolationMovingViolationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DriveVerificationMovingViolation
     */
    'occurrences': number;
}

export const DriveVerificationMovingViolationMovingViolationTypeEnum = {
    suspended_license: 'suspended_license',
    speeding_20_over_limit: 'speeding_20_over_limit',
    speeding_less_than_20_over_limit: 'speeding_less_than_20_over_limit',
    dui_dwi: 'dui_dwi',
    reckless_driving: 'reckless_driving',
    at_fault_accident: 'at_fault_accident'
} as const;

export type DriveVerificationMovingViolationMovingViolationTypeEnum = typeof DriveVerificationMovingViolationMovingViolationTypeEnum[keyof typeof DriveVerificationMovingViolationMovingViolationTypeEnum];


