/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PublicUserCaptain } from './public-user-captain';

/**
 * 
 * @export
 * @interface Captain
 */
export interface Captain {
    /**
     * 
     * @type {string}
     * @memberof Captain
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Captain
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Captain
     */
    'state'?: CaptainStateEnum;
    /**
     * 
     * @type {string}
     * @memberof Captain
     */
    'uscg_number'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Captain
     */
    'new_captain_badge'?: boolean;
    /**
     * 
     * @type {PublicUserCaptain}
     * @memberof Captain
     */
    'user'?: PublicUserCaptain;
}

export const CaptainStateEnum = {
    incomplete_pending_pending_expired_setup_approved_active_expired_admin_deactivated_user_deactivated_declined_blocked: 'incomplete pending pending_expired setup_approved active expired admin_deactivated user_deactivated declined blocked'
} as const;

export type CaptainStateEnum = typeof CaptainStateEnum[keyof typeof CaptainStateEnum];


