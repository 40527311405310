import { atom } from 'jotai';
import { messagesFilterOptions, TabNames } from './constants';

export const activeConversationIdAtom = atom('');

export const mobileConversationDetailVisibleAtom = atom(false);
export const mobileBookingPanelVisibleAtom = atom(false);

export const messagesFilterAtom = atom(messagesFilterOptions.ALL);
export const activeTabNameAtom = atom<string>(TabNames.renter);

export const preApproveCardShowAtom = atom(true);

export const enabledTabsAtom = atom({
  // renter tab is always enabled
  [TabNames.renter]: true,
  [TabNames.owner]: false,
  [TabNames.captain]: false,
});

interface SpamMessageI {
  body: string;
  conversation_id: string;
  sender: {
    id: string;
    user_type: string;
    first_name: string;
    picture: {
      url: string;
    };
  };
  created_at: string;
}

export const sentSpamMessageAtom = atom<SpamMessageI | Record<string, never>>({});
