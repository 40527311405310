/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PackageInstantBooking } from './package-instant-booking';

/**
 * 
 * @export
 * @interface BoatExtendedPackage
 */
export interface BoatExtendedPackage {
    /**
     * 
     * @type {string}
     * @memberof BoatExtendedPackage
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoatExtendedPackage
     */
    'package_type'?: BoatExtendedPackagePackageTypeEnum;
    /**
     * Half day trip value with cents
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'half_day_cents'?: number;
    /**
     * Full day trip value with cents
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'all_day_cents'?: number;
    /**
     * Seven days trip value with cents
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'seven_day_cents'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'two_hour_price_cents'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'three_hour_price_cents'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BoatExtendedPackage
     */
    'available'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'six_hour_price_cents'?: number;
    /**
     * Min half-day price in cents
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'half_day_min_price'?: number;
    /**
     * Min all-day price in cents
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'all_day_min_price'?: number;
    /**
     * Min seven-day price in cents
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'seven_day_min_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoatExtendedPackage
     */
    'fuel_policy'?: BoatExtendedPackageFuelPolicyEnum;
    /**
     * 
     * @type {PackageInstantBooking}
     * @memberof BoatExtendedPackage
     */
    'instant_booking'?: PackageInstantBooking;
    /**
     * 
     * @type {boolean}
     * @memberof BoatExtendedPackage
     */
    'instant_bookable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BoatExtendedPackage
     */
    'insurance_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'two_hours_captain_fee'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'three_hours_captain_fee'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'six_hours_captain_fee'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'half_day_captain_fee'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'full_day_captain_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoatExtendedPackage
     */
    'captain_fee_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoatExtendedPackage
     */
    'trip_start_time'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BoatExtendedPackage
     */
    'include_captain_price'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BoatExtendedPackage
     */
    'multibook_enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'max_passengers'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'min_rental_price_half_day'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'min_rental_price_full_day'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'min_rental_price_two_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'min_rental_price_three_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatExtendedPackage
     */
    'min_rental_price_six_hours'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoatExtendedPackage
     */
    'price_mode'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BoatExtendedPackage
     */
    'eligible_insurers'?: Array<BoatExtendedPackageEligibleInsurersEnum>;
}

export const BoatExtendedPackagePackageTypeEnum = {
    bareboat: 'bareboat',
    captained: 'captained'
} as const;

export type BoatExtendedPackagePackageTypeEnum = typeof BoatExtendedPackagePackageTypeEnum[keyof typeof BoatExtendedPackagePackageTypeEnum];
export const BoatExtendedPackageFuelPolicyEnum = {
    renter_pays: 'renter_pays',
    owner_pays: 'owner_pays'
} as const;

export type BoatExtendedPackageFuelPolicyEnum = typeof BoatExtendedPackageFuelPolicyEnum[keyof typeof BoatExtendedPackageFuelPolicyEnum];
export const BoatExtendedPackageEligibleInsurersEnum = {
    buoy: 'buoy'
} as const;

export type BoatExtendedPackageEligibleInsurersEnum = typeof BoatExtendedPackageEligibleInsurersEnum[keyof typeof BoatExtendedPackageEligibleInsurersEnum];


