/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DomesticV2BookingsIdCicoCheckInGetRequestNotes } from './domestic-v2-bookings-id-cico-check-in-get-request-notes';

/**
 * 
 * @export
 * @interface DomesticV2BookingsIdCicoCheckOutGetRequest
 */
export interface DomesticV2BookingsIdCicoCheckOutGetRequest {
    /**
     * valules: 0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1
     * @type {number}
     * @memberof DomesticV2BookingsIdCicoCheckOutGetRequest
     */
    'boat_fuel': number;
    /**
     * 
     * @type {number}
     * @memberof DomesticV2BookingsIdCicoCheckOutGetRequest
     */
    'engine_hours': number | null;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BookingsIdCicoCheckOutGetRequest
     */
    'renter_signature': string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BookingsIdCicoCheckOutGetRequest
     */
    'owner_signature': string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BookingsIdCicoCheckOutGetRequest
     */
    'signature_type': DomesticV2BookingsIdCicoCheckOutGetRequestSignatureTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BookingsIdCicoCheckOutGetRequest
     */
    'inspection_at': string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BookingsIdCicoCheckOutGetRequest
     */
    'ts'?: string;
    /**
     * 
     * @type {DomesticV2BookingsIdCicoCheckInGetRequestNotes}
     * @memberof DomesticV2BookingsIdCicoCheckOutGetRequest
     */
    'notes': DomesticV2BookingsIdCicoCheckInGetRequestNotes;
}

export const DomesticV2BookingsIdCicoCheckOutGetRequestSignatureTypeEnum = {
    text: 'text',
    image: 'image'
} as const;

export type DomesticV2BookingsIdCicoCheckOutGetRequestSignatureTypeEnum = typeof DomesticV2BookingsIdCicoCheckOutGetRequestSignatureTypeEnum[keyof typeof DomesticV2BookingsIdCicoCheckOutGetRequestSignatureTypeEnum];


