/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DomesticV2MeInsurersGet200ResponseInsurersInner
 */
export interface DomesticV2MeInsurersGet200ResponseInsurersInner {
    /**
     * The human-readable insurer name
     * @type {string}
     * @memberof DomesticV2MeInsurersGet200ResponseInsurersInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeInsurersGet200ResponseInsurersInner
     */
    'status'?: DomesticV2MeInsurersGet200ResponseInsurersInnerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeInsurersGet200ResponseInsurersInner
     */
    'target'?: DomesticV2MeInsurersGet200ResponseInsurersInnerTargetEnum;
    /**
     * CSV values for denial reasons
     * @type {string}
     * @memberof DomesticV2MeInsurersGet200ResponseInsurersInner
     */
    'denial_reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeInsurersGet200ResponseInsurersInner
     */
    'denied'?: DomesticV2MeInsurersGet200ResponseInsurersInnerDeniedEnum;
}

export const DomesticV2MeInsurersGet200ResponseInsurersInnerStatusEnum = {
    approved: 'approved',
    denied: 'denied',
    pending: 'pending'
} as const;

export type DomesticV2MeInsurersGet200ResponseInsurersInnerStatusEnum = typeof DomesticV2MeInsurersGet200ResponseInsurersInnerStatusEnum[keyof typeof DomesticV2MeInsurersGet200ResponseInsurersInnerStatusEnum];
export const DomesticV2MeInsurersGet200ResponseInsurersInnerTargetEnum = {
    owner: 'owner',
    renter: 'renter'
} as const;

export type DomesticV2MeInsurersGet200ResponseInsurersInnerTargetEnum = typeof DomesticV2MeInsurersGet200ResponseInsurersInnerTargetEnum[keyof typeof DomesticV2MeInsurersGet200ResponseInsurersInnerTargetEnum];
export const DomesticV2MeInsurersGet200ResponseInsurersInnerDeniedEnum = {
    owner: 'owner',
    boat: 'boat',
    renter: 'renter',
    nil: 'nil'
} as const;

export type DomesticV2MeInsurersGet200ResponseInsurersInnerDeniedEnum = typeof DomesticV2MeInsurersGet200ResponseInsurersInnerDeniedEnum[keyof typeof DomesticV2MeInsurersGet200ResponseInsurersInnerDeniedEnum];


