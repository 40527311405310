/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AddOnsParams } from './add-ons-params';

/**
 * 
 * @export
 * @interface DomesticV2BookingsIdCalculatorGetRequest
 */
export interface DomesticV2BookingsIdCalculatorGetRequest {
    /**
     * ISO 8601 date
     * @type {string}
     * @memberof DomesticV2BookingsIdCalculatorGetRequest
     */
    'trip_start'?: string;
    /**
     * ISO 8601 date
     * @type {string}
     * @memberof DomesticV2BookingsIdCalculatorGetRequest
     */
    'trip_finish'?: string;
    /**
     * 
     * @type {number}
     * @memberof DomesticV2BookingsIdCalculatorGetRequest
     */
    'passengers'?: number;
    /**
     * Special offer price in cents
     * @type {number}
     * @memberof DomesticV2BookingsIdCalculatorGetRequest
     */
    'special_offer_price'?: number;
    /**
     * The unique id for the insurance renter policy which is or will be attached to the booking
     * @type {string}
     * @memberof DomesticV2BookingsIdCalculatorGetRequest
     */
    'insurance_renter_policy_id'?: string;
    /**
     * The coupon code to discount rental price. Overrides Boating Credits
     * @type {string}
     * @memberof DomesticV2BookingsIdCalculatorGetRequest
     */
    'coupon_code'?: string;
    /**
     * The array of add-ons
     * @type {Array<AddOnsParams>}
     * @memberof DomesticV2BookingsIdCalculatorGetRequest
     */
    'add_ons'?: Array<AddOnsParams>;
    /**
     * Where the initialization of the booking occured.
     * @type {string}
     * @memberof DomesticV2BookingsIdCalculatorGetRequest
     */
    'initialization_source'?: DomesticV2BookingsIdCalculatorGetRequestInitializationSourceEnum;
    /**
     * Which page the calculate was called on
     * @type {string}
     * @memberof DomesticV2BookingsIdCalculatorGetRequest
     */
    'displayed_page'?: DomesticV2BookingsIdCalculatorGetRequestDisplayedPageEnum;
    /**
     * Whether full pricing was shown to the user.
     * @type {boolean}
     * @memberof DomesticV2BookingsIdCalculatorGetRequest
     */
    'full_pricing_shown_to_user'?: boolean;
}

export const DomesticV2BookingsIdCalculatorGetRequestInitializationSourceEnum = {
    instant: 'instant',
    checkout: 'checkout',
    conversation: 'conversation'
} as const;

export type DomesticV2BookingsIdCalculatorGetRequestInitializationSourceEnum = typeof DomesticV2BookingsIdCalculatorGetRequestInitializationSourceEnum[keyof typeof DomesticV2BookingsIdCalculatorGetRequestInitializationSourceEnum];
export const DomesticV2BookingsIdCalculatorGetRequestDisplayedPageEnum = {
    pdp: 'pdp',
    conversation: 'conversation',
    checkout: 'checkout',
    add_ons: 'add_ons'
} as const;

export type DomesticV2BookingsIdCalculatorGetRequestDisplayedPageEnum = typeof DomesticV2BookingsIdCalculatorGetRequestDisplayedPageEnum[keyof typeof DomesticV2BookingsIdCalculatorGetRequestDisplayedPageEnum];


