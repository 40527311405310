/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * When owner is not using the Boatsetter captain network then we ask Who will choose the captain for all the bookings on this boat?
 * @export
 * @enum {string}
 */

export const OwnerCaptainProvision = {
    na: 'na',
    owner_choice: 'owner_choice',
    renter_choice: 'renter_choice'
} as const;

export type OwnerCaptainProvision = typeof OwnerCaptainProvision[keyof typeof OwnerCaptainProvision];



