/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DomesticV2BookingsInquiryPostRequest
 */
export interface DomesticV2BookingsInquiryPostRequest {
    /**
     * 
     * @type {number}
     * @memberof DomesticV2BookingsInquiryPostRequest
     */
    'passengers'?: number;
    /**
     * message body when sending an inquiry
     * @type {string}
     * @memberof DomesticV2BookingsInquiryPostRequest
     */
    'message'?: string;
    /**
     * half day is four hours, full_day is eight.
     * @type {string}
     * @memberof DomesticV2BookingsInquiryPostRequest
     */
    'duration'?: DomesticV2BookingsInquiryPostRequestDurationEnum;
    /**
     * The package the client is making an inquiry on.
     * @type {string}
     * @memberof DomesticV2BookingsInquiryPostRequest
     */
    'package_id'?: string;
    /**
     * ISO8601 date
     * @type {string}
     * @memberof DomesticV2BookingsInquiryPostRequest
     */
    'trip_finish'?: string;
    /**
     * ISO8601 date
     * @type {string}
     * @memberof DomesticV2BookingsInquiryPostRequest
     */
    'trip_start'?: string;
    /**
     * Time in hh:mm format
     * @type {string}
     * @memberof DomesticV2BookingsInquiryPostRequest
     */
    'trip_time'?: string;
}

export const DomesticV2BookingsInquiryPostRequestDurationEnum = {
    half_day: 'half_day',
    full_day: 'full_day',
    two_hours: 'two_hours',
    three_hours: 'three_hours',
    six_hours: 'six_hours'
} as const;

export type DomesticV2BookingsInquiryPostRequestDurationEnum = typeof DomesticV2BookingsInquiryPostRequestDurationEnum[keyof typeof DomesticV2BookingsInquiryPostRequestDurationEnum];


