/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DeviceUser } from './device-user';

/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'device_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'app_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'app_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'device_platform'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'device_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'device_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'device_model'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'culture'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'state'?: DeviceStateEnum;
    /**
     * 
     * @type {DeviceUser}
     * @memberof Device
     */
    'user'?: DeviceUser;
}

export const DeviceStateEnum = {
    push_disabled_push_enabled: 'push_disabled push_enabled'
} as const;

export type DeviceStateEnum = typeof DeviceStateEnum[keyof typeof DeviceStateEnum];


