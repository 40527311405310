import {
  BoatExtendedPackage,
  DomesticV2BoatsBoatIdInsurancesGet200Response,
  DomesticV2MeInsurersGet200ResponseInsurersInner,
  DomesticV2MeInsurersGet200ResponseInsurersInnerDeniedEnum,
} from 'swagger/models';

import { UseMutationResult, useMutation, UseMutationOptions, MutationFunction } from 'react-query';
import { MenuItemStatuses } from 'components/layouts/manage-boat-layout/types';

// eslint-disable-next-line import/prefer-default-export
export const getIsBuoyEligibleFromPackage = (boatPackages: BoatExtendedPackage[]) =>
  boatPackages.some((boatPackage) => boatPackage.eligible_insurers?.includes('buoy') && boatPackage.available === true);

export const getIsBuoyEligible = (boatAvailableInsurances: DomesticV2BoatsBoatIdInsurancesGet200Response) =>
  boatAvailableInsurances?.providers.some(
    (provider) => provider?.name === 'buoy' && provider.base_eligibility === true
  );

export const getIsBoatUSEligible = (boatAvailableInsurances: DomesticV2BoatsBoatIdInsurancesGet200Response) =>
  boatAvailableInsurances?.providers.some(
    (provider) => provider?.name === 'boat_us' && provider.base_eligibility === true
  );
export const isOwnerExcludedFromBuoyInsurance = (insurers: Array<DomesticV2MeInsurersGet200ResponseInsurersInner>) =>
  insurers?.some(
    (insurer) =>
      insurer.name === 'buoy' && insurer.denied === DomesticV2MeInsurersGet200ResponseInsurersInnerDeniedEnum.owner
  );

export const isOwnerApprovedForBuoyInsurance = (insurers: Array<DomesticV2MeInsurersGet200ResponseInsurersInner>) =>
  insurers?.some((insurer) => insurer.name === 'buoy' && insurer.status === 'approved');

export const useSyncMutation = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationKey'>
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const mutationResults = useMutation(mutationFn, options);

  return {
    ...mutationResults,
    mutate: (...params: [TVariables]) => {
      if (!mutationResults.isLoading) {
        mutationResults.mutate(...params);
      }
    },
  };
};

export const mapInsuranceStatus = (buoyInsurance) => {
  const { status, pending_updates } = buoyInsurance;
  return pending_updates ? MenuItemStatuses.PENDING : status?.toUpperCase();
};

export default useSyncMutation;
