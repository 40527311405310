/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ConversationMessage } from '../models';
// @ts-ignore
import { DomesticV2ConversationsConversationIdAdminTrackConversationPostRequest } from '../models';
// @ts-ignore
import { DomesticV2ConversationsConversationIdGet200Response } from '../models';
// @ts-ignore
import { DomesticV2ConversationsConversationIdMessagesPostRequest } from '../models';
/**
 * ConversationsApi - axios parameter creator
 * @export
 */
export const ConversationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Track conversation for admin
         * @param {string} conversationId 
         * @param {DomesticV2ConversationsConversationIdAdminTrackConversationPostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2ConversationsConversationIdAdminTrackConversationPost: async (conversationId: string, body?: DomesticV2ConversationsConversationIdAdminTrackConversationPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('domesticV2ConversationsConversationIdAdminTrackConversationPost', 'conversationId', conversationId)
            const localVarPath = `/domestic/v2/conversations/{conversation_id}/admin_track_conversation`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the conversation details
         * @param {string} conversationId 
         * @param {number} [page] Page for pagination
         * @param {number} [perPage] Returned conversations per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2ConversationsConversationIdGet: async (conversationId: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('domesticV2ConversationsConversationIdGet', 'conversationId', conversationId)
            const localVarPath = `/domestic/v2/conversations/{conversation_id}`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the conversation details
         * @param {string} conversationId 
         * @param {DomesticV2ConversationsConversationIdMessagesPostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2ConversationsConversationIdMessagesPost: async (conversationId: string, body?: DomesticV2ConversationsConversationIdMessagesPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('domesticV2ConversationsConversationIdMessagesPost', 'conversationId', conversationId)
            const localVarPath = `/domestic/v2/conversations/{conversation_id}/messages`
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationsApi - functional programming interface
 * @export
 */
export const ConversationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConversationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Track conversation for admin
         * @param {string} conversationId 
         * @param {DomesticV2ConversationsConversationIdAdminTrackConversationPostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2ConversationsConversationIdAdminTrackConversationPost(conversationId: string, body?: DomesticV2ConversationsConversationIdAdminTrackConversationPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2ConversationsConversationIdAdminTrackConversationPost(conversationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the conversation details
         * @param {string} conversationId 
         * @param {number} [page] Page for pagination
         * @param {number} [perPage] Returned conversations per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2ConversationsConversationIdGet(conversationId: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2ConversationsConversationIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2ConversationsConversationIdGet(conversationId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the conversation details
         * @param {string} conversationId 
         * @param {DomesticV2ConversationsConversationIdMessagesPostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2ConversationsConversationIdMessagesPost(conversationId: string, body?: DomesticV2ConversationsConversationIdMessagesPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2ConversationsConversationIdMessagesPost(conversationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConversationsApi - factory interface
 * @export
 */
export const ConversationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConversationsApiFp(configuration)
    return {
        /**
         * Track conversation for admin
         * @param {string} conversationId 
         * @param {DomesticV2ConversationsConversationIdAdminTrackConversationPostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2ConversationsConversationIdAdminTrackConversationPost(conversationId: string, body?: DomesticV2ConversationsConversationIdAdminTrackConversationPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2ConversationsConversationIdAdminTrackConversationPost(conversationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the conversation details
         * @param {string} conversationId 
         * @param {number} [page] Page for pagination
         * @param {number} [perPage] Returned conversations per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2ConversationsConversationIdGet(conversationId: string, page?: number, perPage?: number, options?: any): AxiosPromise<DomesticV2ConversationsConversationIdGet200Response> {
            return localVarFp.domesticV2ConversationsConversationIdGet(conversationId, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the conversation details
         * @param {string} conversationId 
         * @param {DomesticV2ConversationsConversationIdMessagesPostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2ConversationsConversationIdMessagesPost(conversationId: string, body?: DomesticV2ConversationsConversationIdMessagesPostRequest, options?: any): AxiosPromise<ConversationMessage> {
            return localVarFp.domesticV2ConversationsConversationIdMessagesPost(conversationId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConversationsApi - interface
 * @export
 * @interface ConversationsApi
 */
export interface ConversationsApiInterface {
    /**
     * Track conversation for admin
     * @param {string} conversationId 
     * @param {DomesticV2ConversationsConversationIdAdminTrackConversationPostRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApiInterface
     */
    domesticV2ConversationsConversationIdAdminTrackConversationPost(conversationId: string, body?: DomesticV2ConversationsConversationIdAdminTrackConversationPostRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get the conversation details
     * @param {string} conversationId 
     * @param {number} [page] Page for pagination
     * @param {number} [perPage] Returned conversations per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApiInterface
     */
    domesticV2ConversationsConversationIdGet(conversationId: string, page?: number, perPage?: number, options?: AxiosRequestConfig): AxiosPromise<DomesticV2ConversationsConversationIdGet200Response>;

    /**
     * Get the conversation details
     * @param {string} conversationId 
     * @param {DomesticV2ConversationsConversationIdMessagesPostRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApiInterface
     */
    domesticV2ConversationsConversationIdMessagesPost(conversationId: string, body?: DomesticV2ConversationsConversationIdMessagesPostRequest, options?: AxiosRequestConfig): AxiosPromise<ConversationMessage>;

}

/**
 * ConversationsApi - object-oriented interface
 * @export
 * @class ConversationsApi
 * @extends {BaseAPI}
 */
export class ConversationsApi extends BaseAPI implements ConversationsApiInterface {
    /**
     * Track conversation for admin
     * @param {string} conversationId 
     * @param {DomesticV2ConversationsConversationIdAdminTrackConversationPostRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public domesticV2ConversationsConversationIdAdminTrackConversationPost(conversationId: string, body?: DomesticV2ConversationsConversationIdAdminTrackConversationPostRequest, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).domesticV2ConversationsConversationIdAdminTrackConversationPost(conversationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the conversation details
     * @param {string} conversationId 
     * @param {number} [page] Page for pagination
     * @param {number} [perPage] Returned conversations per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public domesticV2ConversationsConversationIdGet(conversationId: string, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).domesticV2ConversationsConversationIdGet(conversationId, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the conversation details
     * @param {string} conversationId 
     * @param {DomesticV2ConversationsConversationIdMessagesPostRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public domesticV2ConversationsConversationIdMessagesPost(conversationId: string, body?: DomesticV2ConversationsConversationIdMessagesPostRequest, options?: AxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).domesticV2ConversationsConversationIdMessagesPost(conversationId, body, options).then((request) => request(this.axios, this.basePath));
    }
}
