/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface InsuranceClaim
 */
export interface InsuranceClaim {
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceClaim
     */
    'was_damage_repaired'?: boolean;
    /**
     * ISO 8601 date
     * @type {string}
     * @memberof InsuranceClaim
     */
    'claimed_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceClaim
     */
    'claim_type'?: InsuranceClaimClaimTypeEnum;
}

export const InsuranceClaimClaimTypeEnum = {
    hurricane_or_storm: 'hurricane_or_storm',
    towing_only: 'towing_only',
    lightning_strike: 'lightning_strike',
    hit_something_or_went_aground: 'hit_something_or_went_aground',
    theft_of_equipment: 'theft_of_equipment',
    injury_or_fatality: 'injury_or_fatality',
    theft_of_boat: 'theft_of_boat',
    sinking: 'sinking',
    fire_or_explosion: 'fire_or_explosion',
    dismasting: 'dismasting',
    flooding: 'flooding',
    other: 'other'
} as const;

export type InsuranceClaimClaimTypeEnum = typeof InsuranceClaimClaimTypeEnum[keyof typeof InsuranceClaimClaimTypeEnum];


