import { useEffect, useRef } from 'react';

function useCloseOnClickOutside(active, setActive) {
  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && active === true) {
        setActive(false);
      }
    }

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    });
  }

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  return wrapperRef;
}

export default useCloseOnClickOutside;
