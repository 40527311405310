import { useAtomValue } from 'jotai';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import OpenApiConfiguration from 'api/OpenApiConfiguration';
import { AxiosError } from 'axios';
import { ConversationsApi } from 'swagger/apis/conversations-api';
import { activeConversationIdAtom } from '../store';
import { PER_PAGE } from '../constants';

const conversationsApi = new ConversationsApi(OpenApiConfiguration);

type ConversationDetailQueryOptions = Omit<
  UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof conversationsApi.domesticV2ConversationsConversationIdGet>>,
    AxiosError<any, any>
  >,
  'queryKey' | 'queryFn' | 'getNextPageParam'
>;

export default function useConversationDetail(options?: ConversationDetailQueryOptions) {
  const activeConversationId = useAtomValue(activeConversationIdAtom);

  return useConversationDetailBaseQuery(activeConversationId, options);
}

export const getNextPageParam = (lastPage, allPages) => {
  const compiled = (allPages || []).reduce((prev, current) => [...prev, ...(current.data.messages || [])], []);
  return compiled.length !== parseInt(lastPage.headers['x-total'], 10) ? allPages.length + 1 : undefined;
};

export function useConversationDetailBaseQuery(conversationId, options?: ConversationDetailQueryOptions) {
  const { data: { pages = [] } = {}, ...other } = useInfiniteQuery(
    ['conversationDetail', conversationId],
    ({ pageParam = 0 }) =>
      conversationsApi.domesticV2ConversationsConversationIdGet(conversationId, pageParam, PER_PAGE),
    {
      ...options,
      getNextPageParam,
      enabled: !!conversationId && (options?.enabled ?? true),
    }
  );

  const rest = pages?.[0]?.data || {};

  return {
    conversationId,
    pages,
    data: rest,
    ...other,
  };
}
