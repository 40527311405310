export const defaultLocations = [
  {
    description: 'Miami, FL, USA',
    matched_substrings: [
      {
        length: 3,
        offset: 0,
      },
    ],
    place_id: 'ChIJEcHIDqKw2YgRZU-t3XHylv8',
    reference: 'ChIJEcHIDqKw2YgRZU-t3XHylv8',
    structured_formatting: {
      main_text: 'Miami',
      main_text_matched_substrings: [
        {
          length: 3,
          offset: 0,
        },
      ],
      secondary_text: 'FL, USA',
    },
    terms: [
      {
        offset: 0,
        value: 'Miami',
      },
      {
        offset: 7,
        value: 'FL',
      },
      {
        offset: 11,
        value: 'USA',
      },
    ],
    types: ['locality', 'political', 'geocode'],
  },
  {
    description: 'Miami Beach, FL, USA',
    matched_substrings: [
      {
        length: 8,
        offset: 0,
      },
    ],
    place_id: 'ChIJud3-Kxem2YgR62OUJUEXvjc',
    reference: 'ChIJud3-Kxem2YgR62OUJUEXvjc',
    structured_formatting: {
      main_text: 'Miami Beach',
      main_text_matched_substrings: [
        {
          length: 8,
          offset: 0,
        },
      ],
      secondary_text: 'FL, USA',
    },
    terms: [
      {
        offset: 0,
        value: 'Miami Beach',
      },
      {
        offset: 13,
        value: 'FL',
      },
      {
        offset: 17,
        value: 'USA',
      },
    ],
    types: ['locality', 'political', 'geocode'],
  },
  {
    description: 'Seattle, WA, USA',
    matched_substrings: [
      {
        length: 5,
        offset: 0,
      },
    ],
    place_id: 'ChIJVTPokywQkFQRmtVEaUZlJRA',
    reference: 'ChIJVTPokywQkFQRmtVEaUZlJRA',
    structured_formatting: {
      main_text: 'Seattle',
      main_text_matched_substrings: [
        {
          length: 5,
          offset: 0,
        },
      ],
      secondary_text: 'WA, USA',
    },
    terms: [
      {
        offset: 0,
        value: 'Seattle',
      },
      {
        offset: 9,
        value: 'WA',
      },
      {
        offset: 13,
        value: 'USA',
      },
    ],
    types: ['locality', 'political', 'geocode'],
  },
  {
    description: 'Chicago, IL, USA',
    matched_substrings: [
      {
        length: 5,
        offset: 0,
      },
    ],
    place_id: 'ChIJ7cv00DwsDogRAMDACa2m4K8',
    reference: 'ChIJ7cv00DwsDogRAMDACa2m4K8',
    structured_formatting: {
      main_text: 'Chicago',
      main_text_matched_substrings: [
        {
          length: 5,
          offset: 0,
        },
      ],
      secondary_text: 'IL, USA',
    },
    terms: [
      {
        offset: 0,
        value: 'Chicago',
      },
      {
        offset: 9,
        value: 'IL',
      },
      {
        offset: 13,
        value: 'USA',
      },
    ],
    types: ['locality', 'political', 'geocode'],
  },
  {
    description: 'Tampa, FL, USA',
    matched_substrings: [
      {
        length: 5,
        offset: 0,
      },
    ],
    place_id: 'ChIJ4dG5s4K3wogRY7SWr4kTX6c',
    reference: 'ChIJ4dG5s4K3wogRY7SWr4kTX6c',
    structured_formatting: {
      main_text: 'Tampa',
      main_text_matched_substrings: [
        {
          length: 5,
          offset: 0,
        },
      ],
      secondary_text: 'FL, USA',
    },
    terms: [
      {
        offset: 0,
        value: 'Tampa',
      },
      {
        offset: 7,
        value: 'FL',
      },
      {
        offset: 11,
        value: 'USA',
      },
    ],
    types: ['locality', 'political', 'geocode'],
  },
  {
    description: 'New York City, NY, USA',
    matched_substrings: [
      {
        length: 10,
        offset: 0,
      },
    ],
    place_id: 'ChIJOwg_06VPwokRYv534QaPC8g',
    reference: 'ChIJOwg_06VPwokRYv534QaPC8g',
    structured_formatting: {
      main_text: 'New York City',
      main_text_matched_substrings: [
        {
          length: 10,
          offset: 0,
        },
      ],
      secondary_text: 'NY, USA',
    },
    terms: [
      {
        offset: 0,
        value: 'New York City',
      },
      {
        offset: 15,
        value: 'NY',
      },
      {
        offset: 19,
        value: 'USA',
      },
    ],
    types: ['locality', 'political', 'geocode'],
  },
  {
    description: 'Washington D.C., DC, USA',
    matched_substrings: [
      {
        length: 3,
        offset: 0,
      },
    ],
    place_id: 'ChIJW-T2Wt7Gt4kRKl2I1CJFUsI',
    reference: 'ChIJW-T2Wt7Gt4kRKl2I1CJFUsI',
    structured_formatting: {
      main_text: 'Washington D.C.',
      main_text_matched_substrings: [
        {
          length: 3,
          offset: 0,
        },
      ],
      secondary_text: 'DC, USA',
    },
    terms: [
      {
        offset: 0,
        value: 'Washington D.C.',
      },
      {
        offset: 17,
        value: 'DC',
      },
      {
        offset: 21,
        value: 'USA',
      },
    ],
    types: ['locality', 'political', 'geocode'],
  },
  {
    description: 'Fort Lauderdale, FL, USA',
    matched_substrings: [
      {
        length: 4,
        offset: 0,
      },
    ],
    place_id: 'ChIJ9cr6ICcB2YgRvo6_QHW-AnY',
    reference: 'ChIJ9cr6ICcB2YgRvo6_QHW-AnY',
    structured_formatting: {
      main_text: 'Fort Lauderdale',
      main_text_matched_substrings: [
        {
          length: 4,
          offset: 0,
        },
      ],
      secondary_text: 'FL, USA',
    },
    terms: [
      {
        offset: 0,
        value: 'Fort Lauderdale',
      },
      {
        offset: 17,
        value: 'FL',
      },
      {
        offset: 21,
        value: 'USA',
      },
    ],
    types: ['locality', 'political', 'geocode'],
  },
];
