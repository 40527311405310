export const PER_PAGE = 18;

export enum MESSAGE_TYPES {
  newMessage = 'booking_new_message',
  specialOffer = 'booking_special_offer',
  preApproval = 'booking_pre_approval',
  ownerModification = 'booking_owner_modification',
  renterModification = 'booking_renter_modification',
  acceptModification = 'booking_accept_modification',
  declineModification = 'booking_decline_modification',
  checkout = 'booking_checkout',
  bookNow = 'booking_book_now',
  bookingOwnerEditInquiry = 'booking_owner_edit_inquiry',
  bookingRenterEditInquiry = 'booking_renter_edit_inquiry',
  messageOwner = 'booking_message_owner',
  rolodexCancel = 'booking_rolodex_cancel',
  approved = 'approved',
}

export enum TabNames {
  owner = 'owner',
  renter = 'renter',
  captain = 'captain',
}

export enum FilterValues {
  all = 'All',
  archived = 'Archived',
  read = 'Read',
  unread = 'Unread',
}

export const messagesFilterOptions = {
  ALL: { value: FilterValues.all, label: 'All' },
  UNREAD: { value: FilterValues.unread, label: 'Unread' },
  READ: { value: FilterValues.read, label: 'Read' },
  ARCHIVED: { value: FilterValues.archived, label: 'Archived' },
};

export const MESSAGE_TYPE_TO_BOOKING_STATUS_MAP = {
  booking_new_message: {},
  booking_owner_edit_inquiry: {},
  booking_renter_edit_inquiry: {},
  booking_special_offer: {},
  booking_pre_approval: {
    booking_status: 'pre-approved',
  },
  booking_pre_approved: {
    booking_status: 'pre-approved',
  },
  booking_owner_modification: {},
  booking_renter_modification: {},
  booking_accept_modification: {},
  booking_decline_modification: {},
  booking_checkout: {},
  booking_book_now: {},
  booking_message_owner: {},
  booking_rolodex_cancel: {},
};
