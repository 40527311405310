/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DomesticV2BoatsBoatIdAddOnsIdDeleteRequest
 */
export interface DomesticV2BoatsBoatIdAddOnsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdAddOnsIdDeleteRequest
     */
    'price_cents'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BoatsBoatIdAddOnsIdDeleteRequest
     */
    'price_type'?: DomesticV2BoatsBoatIdAddOnsIdDeleteRequestPriceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdAddOnsIdDeleteRequest
     */
    'max_quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BoatsBoatIdAddOnsIdDeleteRequest
     */
    'description'?: string;
}

export const DomesticV2BoatsBoatIdAddOnsIdDeleteRequestPriceTypeEnum = {
    booking: 'per_booking',
    hour: 'per_hour',
    passenger: 'per_passenger'
} as const;

export type DomesticV2BoatsBoatIdAddOnsIdDeleteRequestPriceTypeEnum = typeof DomesticV2BoatsBoatIdAddOnsIdDeleteRequestPriceTypeEnum[keyof typeof DomesticV2BoatsBoatIdAddOnsIdDeleteRequestPriceTypeEnum];


