/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PublicUser } from './public-user';

/**
 * 
 * @export
 * @interface BookingModification
 */
export interface BookingModification {
    /**
     * 
     * @type {string}
     * @memberof BookingModification
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingModification
     */
    'created_at'?: string;
    /**
     * 
     * @type {PublicUser}
     * @memberof BookingModification
     */
    'requested_by'?: PublicUser;
    /**
     * 
     * @type {string}
     * @memberof BookingModification
     */
    'trip_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingModification
     */
    'trip_finish'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingModification
     */
    'trip_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingModification
     */
    'state'?: BookingModificationStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BookingModification
     */
    'can_accept_or_decline'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BookingModification
     */
    'can_cancel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BookingModification
     */
    'expires_at'?: string;
}

export const BookingModificationStateEnum = {
    submitted: 'submitted',
    accepted: 'accepted',
    declined: 'declined',
    expired: 'expired'
} as const;

export type BookingModificationStateEnum = typeof BookingModificationStateEnum[keyof typeof BookingModificationStateEnum];


