/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DriveVerificationBoatClaim
 */
export interface DriveVerificationBoatClaim {
    /**
     * 
     * @type {string}
     * @memberof DriveVerificationBoatClaim
     */
    'claim_type': DriveVerificationBoatClaimClaimTypeEnum;
    /**
     * ISO 8601 date
     * @type {string}
     * @memberof DriveVerificationBoatClaim
     */
    'claimed_on': string;
    /**
     * 
     * @type {boolean}
     * @memberof DriveVerificationBoatClaim
     */
    'was_damage_repaired': boolean;
}

export const DriveVerificationBoatClaimClaimTypeEnum = {
    hurricane_or_storm: 'hurricane_or_storm',
    towing_only: 'towing_only',
    lightning_strike: 'lightning_strike',
    dismasting: 'dismasting',
    hit_something_or_went_aground: 'hit_something_or_went_aground',
    flooding: 'flooding',
    theft_of_equipment: 'theft_of_equipment',
    collision_with_another_boat: 'collision_with_another_boat',
    theft_of_boat: 'theft_of_boat',
    injury_or_fatality: 'injury_or_fatality',
    other: 'other'
} as const;

export type DriveVerificationBoatClaimClaimTypeEnum = typeof DriveVerificationBoatClaimClaimTypeEnum[keyof typeof DriveVerificationBoatClaimClaimTypeEnum];


