import { Component } from 'react';
import { logError } from '../../../BugSnagger';

class GenericErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    logError(error);
  }

  render() {
    const { state, props } = this;
    if (state.hasError) {
      return null;
    }

    return props.children;
  }
}

export default GenericErrorBoundary;
