/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Address } from './address';
import { BoatRules } from './boat-rules';

/**
 * 
 * @export
 * @interface BoatInsertOrUpdate
 */
export interface BoatInsertOrUpdate {
    /**
     * 
     * @type {string}
     * @memberof BoatInsertOrUpdate
     */
    'lienholder_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoatInsertOrUpdate
     */
    'listing_tagline'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoatInsertOrUpdate
     */
    'listing_description'?: string;
    /**
     * 
     * @type {number}
     * @memberof BoatInsertOrUpdate
     */
    'year_manufactured'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoatInsertOrUpdate
     */
    'make'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoatInsertOrUpdate
     */
    'boat_model'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoatInsertOrUpdate
     */
    'hin'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoatInsertOrUpdate
     */
    'cancellation_policy'?: string;
    /**
     * The UUID of the feature
     * @type {Array<string>}
     * @memberof BoatInsertOrUpdate
     */
    'features'?: Array<string>;
    /**
     * The UUID of the model
     * @type {string}
     * @memberof BoatInsertOrUpdate
     */
    'boat_model_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoatInsertOrUpdate
     */
    'boat_type'?: BoatInsertOrUpdateBoatTypeEnum;
    /**
     * 
     * @type {BoatRules}
     * @memberof BoatInsertOrUpdate
     */
    'boat_rules'?: BoatRules;
    /**
     * 
     * @type {Address}
     * @memberof BoatInsertOrUpdate
     */
    'address'?: Address;
    /**
     * 
     * @type {boolean}
     * @memberof BoatInsertOrUpdate
     */
    'non_production_maker'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BoatInsertOrUpdate
     */
    'multibook_enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BoatInsertOrUpdate
     */
    'multibook_buffer_time_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatInsertOrUpdate
     */
    'advance_notice'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BoatInsertOrUpdate
     */
    'appears_on_site'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BoatInsertOrUpdate
     */
    'boat_registration_number'?: string;
}

export const BoatInsertOrUpdateBoatTypeEnum = {
    power: 'power',
    sail: 'sail'
} as const;

export type BoatInsertOrUpdateBoatTypeEnum = typeof BoatInsertOrUpdateBoatTypeEnum[keyof typeof BoatInsertOrUpdateBoatTypeEnum];


