/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface InsuranceStatus
 */
export interface InsuranceStatus {
    /**
     * 
     * @type {string}
     * @memberof InsuranceStatus
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceStatus
     */
    'status'?: InsuranceStatusStatusEnum;
    /**
     * The boat eligibility, does not take into account selected packages
     * @type {boolean}
     * @memberof InsuranceStatus
     */
    'base_eligibility'?: boolean;
    /**
     * The boat has been edited, and is waiting for updates to be approved with insurance provider.
     * @type {boolean}
     * @memberof InsuranceStatus
     */
    'pending_updates'?: boolean;
    /**
     * Insurance provider policy number
     * @type {string}
     * @memberof InsuranceStatus
     */
    'policy_number'?: string;
}

export const InsuranceStatusStatusEnum = {
    approved: 'approved',
    denied: 'denied',
    pending: 'pending'
} as const;

export type InsuranceStatusStatusEnum = typeof InsuranceStatusStatusEnum[keyof typeof InsuranceStatusStatusEnum];


