/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { SearchResultListMetaAllOfGeography } from './search-result-list-meta-all-of-geography';

/**
 * 
 * @export
 * @interface SearchResultListMetaAllOf
 */
export interface SearchResultListMetaAllOf {
    /**
     * 
     * @type {string}
     * @memberof SearchResultListMetaAllOf
     */
    'search_score_sorted_by'?: SearchResultListMetaAllOfSearchScoreSortedByEnum;
    /**
     * Optimizely ab test for calendar blocked search test
     * @type {boolean}
     * @memberof SearchResultListMetaAllOf
     */
    'calendar_blocked_search_test'?: boolean;
    /**
     * 
     * @type {SearchResultListMetaAllOfGeography}
     * @memberof SearchResultListMetaAllOf
     */
    'geography'?: SearchResultListMetaAllOfGeography;
}

export const SearchResultListMetaAllOfSearchScoreSortedByEnum = {
    conversion: 'conversion',
    volume: 'volume',
    rating: 'rating',
    instant_book: 'instant_book',
    even: 'even',
    responsiveness: 'responsiveness'
} as const;

export type SearchResultListMetaAllOfSearchScoreSortedByEnum = typeof SearchResultListMetaAllOfSearchScoreSortedByEnum[keyof typeof SearchResultListMetaAllOfSearchScoreSortedByEnum];


