/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AddOnsParams } from './add-ons-params';

/**
 * 
 * @export
 * @interface DomesticV2BookingsCheckoutPostRequest
 */
export interface DomesticV2BookingsCheckoutPostRequest {
    /**
     * half day is four hours, full_day is eight (usually)
     * @type {string}
     * @memberof DomesticV2BookingsCheckoutPostRequest
     */
    'duration'?: DomesticV2BookingsCheckoutPostRequestDurationEnum;
    /**
     * The package the client is making an inquiry on.
     * @type {string}
     * @memberof DomesticV2BookingsCheckoutPostRequest
     */
    'package_id'?: string;
    /**
     * ISO8601 date
     * @type {string}
     * @memberof DomesticV2BookingsCheckoutPostRequest
     */
    'trip_finish'?: string;
    /**
     * ISO8601 date
     * @type {string}
     * @memberof DomesticV2BookingsCheckoutPostRequest
     */
    'trip_start'?: string;
    /**
     * HH:MM trip start time
     * @type {string}
     * @memberof DomesticV2BookingsCheckoutPostRequest
     */
    'trip_start_time'?: string;
    /**
     * The credit card ID used for payment.
     * @type {string}
     * @memberof DomesticV2BookingsCheckoutPostRequest
     */
    'credit_card_id'?: string;
    /**
     * The Captain ID.
     * @type {string}
     * @memberof DomesticV2BookingsCheckoutPostRequest
     */
    'captain_id'?: string;
    /**
     * The coupon code to discount rental price. Overrides Boating Credits
     * @type {string}
     * @memberof DomesticV2BookingsCheckoutPostRequest
     */
    'coupon_code'?: string;
    /**
     * Message from renter
     * @type {string}
     * @memberof DomesticV2BookingsCheckoutPostRequest
     */
    'message'?: string;
    /**
     * The number of passengers
     * @type {number}
     * @memberof DomesticV2BookingsCheckoutPostRequest
     */
    'passengers'?: number;
    /**
     * The array of add-ons
     * @type {Array<AddOnsParams>}
     * @memberof DomesticV2BookingsCheckoutPostRequest
     */
    'add_ons'?: Array<AddOnsParams>;
    /**
     * If renter is purchasing insurance
     * @type {string}
     * @memberof DomesticV2BookingsCheckoutPostRequest
     */
    'purchasing_insurance'?: string;
    /**
     * ID of insurance_renter_policy
     * @type {string}
     * @memberof DomesticV2BookingsCheckoutPostRequest
     */
    'insurance_renter_policy_id'?: string;
}

export const DomesticV2BookingsCheckoutPostRequestDurationEnum = {
    half_day: 'half_day',
    full_day: 'full_day',
    two_hours: 'two_hours',
    three_hours: 'three_hours',
    six_hours: 'six_hours'
} as const;

export type DomesticV2BookingsCheckoutPostRequestDurationEnum = typeof DomesticV2BookingsCheckoutPostRequestDurationEnum[keyof typeof DomesticV2BookingsCheckoutPostRequestDurationEnum];


