/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DomesticV2MeGovernmentIdGetRequest
 */
export interface DomesticV2MeGovernmentIdGetRequest {
    /**
     * The type of ID
     * @type {string}
     * @memberof DomesticV2MeGovernmentIdGetRequest
     */
    'type'?: DomesticV2MeGovernmentIdGetRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeGovernmentIdGetRequest
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2MeGovernmentIdGetRequest
     */
    'territory': string;
    /**
     * ISO 2 country code
     * @type {string}
     * @memberof DomesticV2MeGovernmentIdGetRequest
     */
    'country_code'?: string;
    /**
     * Date, formatted YYYY-MM-DD
     * @type {string}
     * @memberof DomesticV2MeGovernmentIdGetRequest
     */
    'expires_on': string;
}

export const DomesticV2MeGovernmentIdGetRequestTypeEnum = {
    passport: 'passport',
    us_drivers_license: 'us_drivers_license'
} as const;

export type DomesticV2MeGovernmentIdGetRequestTypeEnum = typeof DomesticV2MeGovernmentIdGetRequestTypeEnum[keyof typeof DomesticV2MeGovernmentIdGetRequestTypeEnum];


