/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DomesticV2BoatsBoatIdPackagesIdDeleteRequestInstantBooking } from './domestic-v2-boats-boat-id-packages-id-delete-request-instant-booking';

/**
 * 
 * @export
 * @interface DomesticV2BoatsBoatIdPackagesIdDeleteRequest
 */
export interface DomesticV2BoatsBoatIdPackagesIdDeleteRequest {
    /**
     * 
     * @type {boolean}
     * @memberof DomesticV2BoatsBoatIdPackagesIdDeleteRequest
     */
    'include_captain_price'?: boolean;
    /**
     * Boat package price for half day trip with cents without dots.
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdPackagesIdDeleteRequest
     */
    'half_day_cents'?: number;
    /**
     * Boat package price for full day trip with cents without dots.
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdPackagesIdDeleteRequest
     */
    'all_day_cents'?: number;
    /**
     * Boat package price for seven days trip with cents without dots.
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdPackagesIdDeleteRequest
     */
    'seven_day_cents'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdPackagesIdDeleteRequest
     */
    'two_hour_price_cents'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdPackagesIdDeleteRequest
     */
    'three_hour_price_cents'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomesticV2BoatsBoatIdPackagesIdDeleteRequest
     */
    'six_hour_price_cents'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BoatsBoatIdPackagesIdDeleteRequest
     */
    'fuel_policy'?: DomesticV2BoatsBoatIdPackagesIdDeleteRequestFuelPolicyEnum;
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BoatsBoatIdPackagesIdDeleteRequest
     */
    'insurance_type'?: DomesticV2BoatsBoatIdPackagesIdDeleteRequestInsuranceTypeEnum;
    /**
     * 
     * @type {DomesticV2BoatsBoatIdPackagesIdDeleteRequestInstantBooking}
     * @memberof DomesticV2BoatsBoatIdPackagesIdDeleteRequest
     */
    'instant_booking'?: DomesticV2BoatsBoatIdPackagesIdDeleteRequestInstantBooking;
}

export const DomesticV2BoatsBoatIdPackagesIdDeleteRequestFuelPolicyEnum = {
    renter_pays: 'renter_pays',
    owner_pays: 'owner_pays'
} as const;

export type DomesticV2BoatsBoatIdPackagesIdDeleteRequestFuelPolicyEnum = typeof DomesticV2BoatsBoatIdPackagesIdDeleteRequestFuelPolicyEnum[keyof typeof DomesticV2BoatsBoatIdPackagesIdDeleteRequestFuelPolicyEnum];
export const DomesticV2BoatsBoatIdPackagesIdDeleteRequestInsuranceTypeEnum = {
    p2p: 'p2p',
    commercial: 'commercial'
} as const;

export type DomesticV2BoatsBoatIdPackagesIdDeleteRequestInsuranceTypeEnum = typeof DomesticV2BoatsBoatIdPackagesIdDeleteRequestInsuranceTypeEnum[keyof typeof DomesticV2BoatsBoatIdPackagesIdDeleteRequestInsuranceTypeEnum];


