/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CicoWindow
 */
export interface CicoWindow {
    /**
     * 
     * @type {string}
     * @memberof CicoWindow
     */
    'status'?: CicoWindowStatusEnum;
    /**
     * ISO8601 formatted date-time
     * @type {string}
     * @memberof CicoWindow
     */
    'starts_at'?: string;
    /**
     * ISO8601 formatted date-time
     * @type {string}
     * @memberof CicoWindow
     */
    'ends_at'?: string;
}

export const CicoWindowStatusEnum = {
    open: 'open',
    closed: 'closed',
    completed: 'completed'
} as const;

export type CicoWindowStatusEnum = typeof CicoWindowStatusEnum[keyof typeof CicoWindowStatusEnum];


