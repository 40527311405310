/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BoatAvailablePackagesPackagesInner
 */
export interface BoatAvailablePackagesPackagesInner {
    /**
     * public_id for packages table
     * @type {string}
     * @memberof BoatAvailablePackagesPackagesInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoatAvailablePackagesPackagesInner
     */
    'type'?: BoatAvailablePackagesPackagesInnerTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BoatAvailablePackagesPackagesInner
     */
    'eligible'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BoatAvailablePackagesPackagesInner
     */
    'ineligible_reason_codes'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BoatAvailablePackagesPackagesInner
     */
    'ineligible_reasons'?: Array<string>;
}

export const BoatAvailablePackagesPackagesInnerTypeEnum = {
    bareboat: 'bareboat',
    captained: 'captained'
} as const;

export type BoatAvailablePackagesPackagesInnerTypeEnum = typeof BoatAvailablePackagesPackagesInnerTypeEnum[keyof typeof BoatAvailablePackagesPackagesInnerTypeEnum];


