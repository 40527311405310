/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DomesticV3MeBoatsGet200ResponseInnerActionsRequiredInner
 */
export interface DomesticV3MeBoatsGet200ResponseInnerActionsRequiredInner {
    /**
     * 
     * @type {string}
     * @memberof DomesticV3MeBoatsGet200ResponseInnerActionsRequiredInner
     */
    'action_type'?: DomesticV3MeBoatsGet200ResponseInnerActionsRequiredInnerActionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DomesticV3MeBoatsGet200ResponseInnerActionsRequiredInner
     */
    'severity'?: DomesticV3MeBoatsGet200ResponseInnerActionsRequiredInnerSeverityEnum;
}

export const DomesticV3MeBoatsGet200ResponseInnerActionsRequiredInnerActionTypeEnum = {
    price_missing: 'price_missing',
    buoy_underwriting: 'buoy_underwriting'
} as const;

export type DomesticV3MeBoatsGet200ResponseInnerActionsRequiredInnerActionTypeEnum = typeof DomesticV3MeBoatsGet200ResponseInnerActionsRequiredInnerActionTypeEnum[keyof typeof DomesticV3MeBoatsGet200ResponseInnerActionsRequiredInnerActionTypeEnum];
export const DomesticV3MeBoatsGet200ResponseInnerActionsRequiredInnerSeverityEnum = {
    info: 'info',
    warning: 'warning',
    critical: 'critical'
} as const;

export type DomesticV3MeBoatsGet200ResponseInnerActionsRequiredInnerSeverityEnum = typeof DomesticV3MeBoatsGet200ResponseInnerActionsRequiredInnerSeverityEnum[keyof typeof DomesticV3MeBoatsGet200ResponseInnerActionsRequiredInnerSeverityEnum];


