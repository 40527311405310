/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { OwnerCaptainProvision } from './owner-captain-provision';

/**
 * 
 * @export
 * @interface TemporaryPackage
 */
export interface TemporaryPackage {
    /**
     * 
     * @type {string}
     * @memberof TemporaryPackage
     */
    'price_mode'?: TemporaryPackagePriceModeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof TemporaryPackage
     */
    'initial_duration_preferences'?: Array<TemporaryPackageInitialDurationPreferencesEnum>;
    /**
     * Commercial captained fishing charter
     * @type {boolean}
     * @memberof TemporaryPackage
     */
    'commercial_captained_fishing_charter'?: boolean;
    /**
     * Renter operates boat
     * @type {boolean}
     * @memberof TemporaryPackage
     */
    'bareboat'?: boolean;
    /**
     * Captain operates boat
     * @type {boolean}
     * @memberof TemporaryPackage
     */
    'captained'?: boolean;
    /**
     * Use boatsetter captain network or custom captain list
     * @type {boolean}
     * @memberof TemporaryPackage
     */
    'using_captain_network'?: boolean;
    /**
     * 
     * @type {OwnerCaptainProvision}
     * @memberof TemporaryPackage
     */
    'owner_captain_provision'?: OwnerCaptainProvision;
    /**
     * Captain payment be collected
     * @type {string}
     * @memberof TemporaryPackage
     */
    'captain_payment_collection'?: TemporaryPackageCaptainPaymentCollectionEnum;
    /**
     * Fuel policy
     * @type {string}
     * @memberof TemporaryPackage
     */
    'fuel_policy'?: TemporaryPackageFuelPolicyEnum;
    /**
     * Boat 2-hour price in cents
     * @type {number}
     * @memberof TemporaryPackage
     */
    'boat_two_hour_price_cents'?: number;
    /**
     * Boat 3-hour price in cents
     * @type {number}
     * @memberof TemporaryPackage
     */
    'boat_three_hour_price_cents'?: number;
    /**
     * Boat 4-hour price in cents
     * @type {number}
     * @memberof TemporaryPackage
     */
    'boat_half_day_price_cents'?: number;
    /**
     * Boat 6-hour price in cents
     * @type {number}
     * @memberof TemporaryPackage
     */
    'boat_six_hour_price_cents'?: number;
    /**
     * Boat 4-hour price in cents
     * @type {number}
     * @memberof TemporaryPackage
     */
    'boat_all_day_price_cents'?: number;
    /**
     * Captain 2-hour price in cents
     * @type {number}
     * @memberof TemporaryPackage
     */
    'captain_two_hour_price_cents'?: number;
    /**
     * Captain 3-hour price in cents
     * @type {number}
     * @memberof TemporaryPackage
     */
    'captain_three_hour_price_cents'?: number;
    /**
     * Captain 4-hour price in cents
     * @type {number}
     * @memberof TemporaryPackage
     */
    'captain_half_day_price_cents'?: number;
    /**
     * Captain 6-hour price in cents
     * @type {number}
     * @memberof TemporaryPackage
     */
    'captain_six_hour_price_cents'?: number;
    /**
     * Captain 4-hour price in cents
     * @type {number}
     * @memberof TemporaryPackage
     */
    'captain_all_day_price_cents'?: number;
}

export const TemporaryPackagePriceModeEnum = {
    smart: 'smart',
    custom: 'custom'
} as const;

export type TemporaryPackagePriceModeEnum = typeof TemporaryPackagePriceModeEnum[keyof typeof TemporaryPackagePriceModeEnum];
export const TemporaryPackageInitialDurationPreferencesEnum = {
    half_day: 'half_day',
    full_day: 'full_day',
    two_hours: 'two_hours',
    three_hours: 'three_hours',
    six_hours: 'six_hours'
} as const;

export type TemporaryPackageInitialDurationPreferencesEnum = typeof TemporaryPackageInitialDurationPreferencesEnum[keyof typeof TemporaryPackageInitialDurationPreferencesEnum];
export const TemporaryPackageCaptainPaymentCollectionEnum = {
    via_boatsetter: 'via_boatsetter',
    at_dock: 'at_dock'
} as const;

export type TemporaryPackageCaptainPaymentCollectionEnum = typeof TemporaryPackageCaptainPaymentCollectionEnum[keyof typeof TemporaryPackageCaptainPaymentCollectionEnum];
export const TemporaryPackageFuelPolicyEnum = {
    owner_pays: 'owner_pays',
    renter_pays: 'renter_pays'
} as const;

export type TemporaryPackageFuelPolicyEnum = typeof TemporaryPackageFuelPolicyEnum[keyof typeof TemporaryPackageFuelPolicyEnum];


