import { useEffect, useRef, useState } from 'react';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';
import styled, { css } from 'styled-components';
import { useRouter, withRouter } from 'next/router';
import getConfig from 'next/config';
import { useBreakpoint } from 'components/BreakpointProvider';
import Script from 'next/script';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import dayjs from 'dayjs';
import {
  isBookingMultiDayAtom,
  tripFinishDayjsAtom,
  tripStartDayjsAtom,
} from 'components/forms/bookingWidget/jotaiStore';
import { Box } from 'components/primitives/box';
import boatsetterPaths from 'utils/boatsetterPaths';
import getDefaultStartDate from '../helpers/dateHelpers';
import {
  nearFromGeoTargetsAtom,
  searchActionAtom,
  setMultiDayBookingAtom,
  setPlaceFromSearchAtom,
  tripDateAtom,
} from './search/v2/Filter/filterStore';
import BookingCalendarWrapper, { DesktopWrapper } from './calendar/BookingCalendarWrapper';
import { defaultLocations } from './defaultLocations';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import useCloseOnClickOutside from '../hooks/useCloseOnClickOutside';
import { mediaMLgMin, mediaMSmMax } from '../helpers/breakpoints';

const { GOOGLE_API } = getConfig().publicRuntimeConfig;

enum FilterType {
  DATE = 'date',
  PLACE = 'place',
  EMPTY = '',
}

const SearchBar = (props) => {
  const { router, setFalseHeader } = props;
  const { query } = router;

  const localRouter = useRouter();

  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const [suggestionsVisible, setSuggestionsVisible] = useState(true);
  const [searchFilterOpen, setSearchFilterOpen] = useState(false);
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
  const [mobileFilterDetailsOpen, setMobileFilterDetailsOpen] = useState(false);
  const [filterType, setFilterType] = useState<FilterType>(FilterType.EMPTY);
  const [, setEndDateError] = useState(false);

  const dropRef = useRef(null);
  const buttonRef = useRef(null);
  const inputRef = useRef(null);
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints.xs || breakpoints.sm;

  const [tripStartAtom, setTripStartAtom] = useAtom(tripStartDayjsAtom);
  const [tripFinishAtom, setTripFinishAtom] = useAtom(tripFinishDayjsAtom);
  const [isBookingMultiDay, setIsBookingMultiDay] = useAtom(isBookingMultiDayAtom);

  // Initialize trip date or set default dates when they are in the past
  useEffect(() => {
    if (!localRouter.isReady) return;

    // Single-day default logic
    const tripDate = localRouter.query.trip_date ? dayjs(query.trip_date) : null;
    const isTripDatePast = dayjs(tripDate).isBefore(dayjs(), 'day');
    const startDate = localRouter.query.start_date ? dayjs(query.start_date) : null;

    if ((!tripDate && !startDate) || isTripDatePast) {
      const defaultDate = getDefaultStartDate();
      const formattedDefaultDate = defaultDate.toISOString().split('T')[0];
      setTripStartAtom(defaultDate);
      setDateAtom(formattedDefaultDate);
      if (localRouter.query.trip_date !== formattedDefaultDate) {
        router.replace({
          pathname: router.pathname,
          query: {
            ...router.query,
            trip_date: formattedDefaultDate,
          },
        });
      }
      return;
    }

    // Multi-day default logic
    const endDate = localRouter.query.end_date ? dayjs(query.end_date) : null;
    const isStartDatePast = dayjs(startDate).isBefore(dayjs(), 'day');

    // If startDate is nil we assume the trip is single-day and skip
    if (startDate && isStartDatePast) {
      const defaultStartDate = getDefaultStartDate();
      const tripDaysCount = endDate.diff(startDate, 'days');
      const defaultEndDate = defaultStartDate.add(tripDaysCount, 'days');
      const formattedDefaultStartDate = defaultStartDate.toISOString().split('T')[0];
      const formattedDefaultEndDate = defaultEndDate.toISOString().split('T')[0];
      setTripStartAtom(defaultStartDate);
      setTripFinishAtom(defaultEndDate);
      setDateAtom(formattedDefaultStartDate);
      setIsBookingMultiDay(true);
      setMultiDayBooking({
        startDate: formattedDefaultStartDate,
        endDate: formattedDefaultEndDate,
      });
      if (
        localRouter.query.start_period !== formattedDefaultStartDate ||
        localRouter.query.end_period !== formattedDefaultEndDate
      ) {
        router.replace({
          pathname: router.pathname,
          query: {
            ...router.query,
            start_period: formattedDefaultStartDate,
            end_period: formattedDefaultEndDate,
          },
        });
      }

      return;
    }

    // Valid date logic
    // Sync calendar with query for single-day search
    if (localRouter.query.trip_date && localRouter.query.trip_date !== tripStartAtom?.toISOString().split('T')[0]) {
      setTripStartAtom(dayjs(localRouter.query.trip_date as string));
    }
    // Sync calendar with query for multi-day search
    if (
      localRouter.query.start_date &&
      localRouter.query.end_date &&
      (localRouter.query.start_date !== tripStartAtom?.toISOString().split('T')[0] ||
        localRouter.query.end_date !== tripFinishAtom?.toISOString().split('T')[0])
    ) {
      setTripStartAtom(dayjs(localRouter.query.start_date as string));
      setTripFinishAtom(dayjs(localRouter.query.end_date as string));
      setIsBookingMultiDay(true);
    }
  }, [localRouter]);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    init,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['geocode', 'establishment'],
      // @ts-ignore
      fields: ['address_components', 'geometry', 'formatted_address'],
    },
    debounce: 300,
    initOnMount: false,
  });
  const wrapperRef = useCloseOnClickOutside(suggestionsVisible, () => {
    setSuggestionsVisible(false);
    setActiveSuggestion(-1);
  });

  const handleInput = (e) => {
    setLoaded(true);
    init();
    if (!suggestionsVisible) {
      setSuggestionsVisible(true);
    }
    setValue(e.target.value);
  };

  const setDateAtom = useSetAtom(tripDateAtom);
  const setSearchAction = useSetAtom(searchActionAtom);
  const setNearAtom = useSetAtom(setPlaceFromSearchAtom);
  const setMultiDayBooking = useSetAtom(setMultiDayBookingAtom);
  const headline = useAtomValue(nearFromGeoTargetsAtom);

  const handleMobileClear = () => {
    setValue('');
  };

  const handleSelect = async (selection) => {
    if (!selection) return;
    const { description, place_id } = selection;
    setValue(description, false);
    setActiveSuggestion(-1);
    const place = (await getDetails({ placeId: place_id })) as any;
    clearSuggestions();
    setSuggestionsVisible(false);

    if (place && place.address_components) {
      const scrollContainer = document.getElementById('__next');
      scrollContainer?.scrollTo(0, 0);

      setNearAtom(place);
      setSearchAction('search');

      setSearchFilterOpen(false);
      setMobileFilterOpen(false);
      setMobileFilterDetailsOpen(false);
      setValue('');
      setFalseHeader(false);
    }
  };

  const sendPayload = async (selection) => {
    if (isBookingMultiDay) {
      setMultiDayBooking(selection);
    } else {
      setDateAtom(selection);
    }
    setSearchFilterOpen(false);
    setMobileFilterOpen(false);
    setMobileFilterDetailsOpen(false);
    setValue('');
    setFalseHeader(false);
  };

  const handleMobileSearchClick = () => {
    setMobileFilterOpen(!mobileFilterOpen);

    if (mobileFilterDetailsOpen) {
      setMobileFilterDetailsOpen(false);
      setFalseHeader(false);
    }
  };

  const handleMobileFilterDetails = (type: FilterType) => {
    setFilterType(type);
    setMobileFilterDetailsOpen(!mobileFilterDetailsOpen);
    setFalseHeader(true);
  };

  const handleSearchClick = (type: FilterType) => {
    if (searchFilterOpen && type !== filterType) {
      setFilterType(type);
      return;
    }
    setSearchFilterOpen(!searchFilterOpen);
    setFilterType(type);
  };

  useOnClickOutside(dropRef, (e) => {
    if (buttonRef.current.contains(e.target)) return;
    setSearchFilterOpen(false);
  });

  const renderSuggestions = () =>
    data.map((suggestion, index) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          className={`pac-item pac-item-flex no-border ${activeSuggestion === index ? 'pac-item-selected' : ''}`}
          key={place_id}
          onClick={(e) => {
            e.preventDefault();
            handleSelect(suggestion);
          }}
        >
          <img alt="map marker icon" src="/images/icons/map-marker.svg" />
          <span className="pac-item-main">
            <span>{main_text}</span>
          </span>
          <span className="pac-item-sub">{secondary_text}</span>
        </li>
      );
    });

  const renderDefault = () =>
    defaultLocations.map((suggestion, index) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          className={`pac-item pac-item-flex no-border ${activeSuggestion === index ? 'pac-item-selected' : ''}`}
          key={place_id}
          onClick={(e) => {
            e.preventDefault();
            handleSelect(suggestion);
          }}
        >
          {/* <span className="pac-icon pac-icon-marker" /> */}
          <img alt="map marker icon" src="/images/icons/map-marker.svg" />
          <span className="pac-item-main">
            <span>{main_text}</span>
          </span>
          <span className="pac-item-sub">{secondary_text}</span>
        </li>
      );
    });

  const [loaded, setLoaded] = useState(false);

  const getMaxLength = () => {
    if (isMobile) {
      return 30;
    }
    if (isBookingMultiDay) {
      return 21;
    }
    return 15;
  };

  const truncate = (input) => {
    const max = getMaxLength();
    return input.length > max ? `${input.substring(0, max)}...` : input;
  };

  const onDateSelect = (date) => {
    if (isBookingMultiDay) {
      const startDate = dayjs(date.startDate);
      const endDate = dayjs(date.endDate);
      if (startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) {
        sendPayload({ startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') });
      } else {
        setEndDateError(true);
      }
    } else {
      const newDate = dayjs(date);

      sendPayload(newDate ? newDate.format('YYYY-MM-DD') : '');
    }
  };

  const getDate = () => {
    const tripDate = query.trip_date ? dayjs(query.trip_date) : null;
    const startDate = query.start_date ? dayjs(query.start_date) : null;
    const endDate = query.end_date ? dayjs(query.end_date) : null;
    const today = dayjs();

    if (tripDate && !tripDate.isBefore(today, 'day')) {
      if (tripDate.year() !== today.year()) {
        return truncate(tripDate.format('ddd, MMM D, YYYY'));
      }
      return tripDate.format('ddd, MMM D');
    }

    if (startDate && endDate) {
      if (startDate.year() !== today.year() || endDate.year() !== today.year()) {
        return truncate(`${startDate.format('MMM D, YYYY')} – ${endDate.format('MMM D, YYYY')}`);
      }
      if (startDate.month() !== endDate.month()) {
        return `${startDate.format('MMM D')} – ${endDate.format('MMM D')}`;
      }
      return `${startDate.format('MMM D')} – ${endDate.format('D')}`;
    }

    return today.add(3, 'days').format('ddd, MMM D');
  };

  return (
    <SearchContainer>
      {loaded && localRouter.route !== boatsetterPaths.search && localRouter.route !== '/boats/[id]' && (
        <Script src={`https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=${GOOGLE_API}`} />
      )}
      <SearchWrapper
        ref={wrapperRef}
        onClick={async () => {
          setLoaded(true);
        }}
        id="js-nav-searchbar"
      >
        <InputContainer ref={buttonRef} style={{ minWidth: isBookingMultiDay && !isMobile ? 455 : '' }}>
          <MobileClick onClick={handleMobileSearchClick} />
          <PlacePicker
            onClick={() => handleSearchClick(FilterType.PLACE)}
            data-testid="component-search-bar-place-picker"
          >
            <Box mt="5px" as="span">
              <svg
                fill="#17233c"
                className="no-mobile iconSvg"
                height={14}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1008 1008"
              >
                <path d="M504 28.1c-195.1 0-352.1 157-352.1 352.1 0 180.8 290.3 542.5 323.6 585.3 4.8 9.5 19 14.3 28.6 14.3 9.5 0 19-4.8 28.6-14.3C566 922.7 856.3 561 856.3 380.2 851.4 185.2 699.1 28.1 504 28.1zM223.2 380.3c0-152.3 123.7-280.8 276-280.8S780 223.2 780 375.5c0 119-180.8 375.9-276 504.4-95.2-123.7-280.8-380.7-280.8-499.6zm347.4 0c0 38.1-28.6 66.6-66.6 66.6s-66.6-28.6-66.6-66.6 28.6-66.6 66.6-66.6 66.6 28.5 66.6 66.6z" />
              </svg>
            </Box>
            <span>{truncate(headline.replace(', USA', '').replace(', US', ''))}</span>
          </PlacePicker>
          <DatePicker onClick={() => handleSearchClick(FilterType.DATE)} data-testid="component-search-bar-date-picker">
            <svg className="no-mobile iconSvg" height={15} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1008 1008">
              <circle cx="529" cy="726" r="10" fill="#fff" />
              <path d="M899.5 260.6v608.5c0 33-27.9 60.8-60.8 60.8H169.3c-33 0-60.8-27.9-60.8-60.8V260.6c0-33 27.9-60.8 60.8-60.8h60.8v-45.6c0-41.4 33.8-76.1 76.1-76.1h30.4c42.3 0 76.1 34.6 76.1 76.1v45.6h182.5v-45.6c0-41.4 33.8-76.1 76.1-76.1h30.4c42.3 0 76.1 34.6 76.1 76.1v45.6h60.8c33 0 60.9 27.8 60.9 60.8zM717 291V154.1c0-8.5-6.8-15.2-15.2-15.2h-30.4c-8.5 0-15.2 6.8-15.2 15.2V291c0 8.5 6.8 15.2 15.2 15.2h30.4c8.4 0 15.2-6.7 15.2-15.2zm-15.2 91.3v136.9h136.9V382.3H701.8zm-182.6 0v136.9h152.1V382.3H519.2zm182.6 167.3v152.1h136.9V549.6H701.8zM351.9 291V154.1c0-8.5-6.8-15.2-15.2-15.2h-30.4c-8.5 0-15.2 6.8-15.2 15.2V291c0 8.5 6.8 15.2 15.2 15.2h30.4c8.4 0 15.2-6.7 15.2-15.2zm167.3 258.6v152.1h152.1V549.6H519.2zm182.6 182.6v136.9h136.9V732.2H701.8zM336.7 382.3v136.9h152.1V382.3H336.7zm182.5 349.9v136.9h152.1V732.2H519.2zM169.3 382.3v136.9h136.9V382.3H169.3zm167.4 167.3v152.1h152.1V549.6H336.7zm-167.4 0v152.1h136.9V549.6H169.3zm167.4 182.6v136.9h152.1V732.2H336.7zm-167.4 0v136.9h136.9V732.2H169.3z" />
            </svg>

            <span>{getDate()}</span>
          </DatePicker>
          <DownArrow
            onClick={() => handleSearchClick(FilterType.DATE)}
            data-testid="component-search-bar-date-picker-arrow"
          >
            {searchFilterOpen || mobileFilterOpen ? (
              <svg height={13} fill="#0751C2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1008 1008">
                <path d="M132.6 658.6c0-6.6 4-13.2 7.9-17.2l346.3-346.3c4-4 11.9-7.9 17.2-7.9 6.6 0 13.2 4 17.2 7.9l346.3 346.3c4 4 7.9 10.6 7.9 17.2 0 5.3-4 13.2-7.9 17.2l-37 37c-4 4-10.6 7.9-17.2 7.9-5.3 0-13.2-4-17.2-7.9l-292.2-292-292 292c-4 4-11.9 7.9-17.2 7.9-6.6 0-13.2-4-17.2-7.9l-37-37c-3.9-4-7.9-11.9-7.9-17.2z" />
              </svg>
            ) : (
              <svg height={13} fill="#0751C2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1008 1008">
                <path d="M875.4 349.4c0 6.6-4 13.2-7.9 17.2L521.2 712.8c-4 4-11.9 7.9-17.2 7.9-6.6 0-13.2-4-17.2-7.9L140.5 366.6c-4-4-7.9-10.6-7.9-17.2 0-5.3 4-13.2 7.9-17.2l37-37c4-4 10.6-7.9 17.2-7.9 5.3 0 13.2 4 17.2 7.9L504 587.3l292.1-292.1c4-4 11.9-7.9 17.2-7.9 6.6 0 13.2 4 17.2 7.9l37 37c3.9 4 7.9 11.9 7.9 17.2z" />
              </svg>
            )}
          </DownArrow>
        </InputContainer>
        {mobileFilterDetailsOpen && filterType === FilterType.PLACE && (
          <FalseMobileHeader>
            <BackButton
              onClick={() => {
                setMobileFilterDetailsOpen(false);
                setFalseHeader(false);
                setValue('');
              }}
            >
              <svg height={16} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1008 1008">
                <path d="M658.6 875.4c-6.6 0-13.2-4-17.2-7.9L295.2 521.2c-4-4-7.9-11.9-7.9-17.2 0-6.6 4-13.2 7.9-17.2l346.3-346.3c4-4 10.6-7.9 17.2-7.9 5.3 0 13.2 4 17.2 7.9l37 37c4 4 7.9 10.6 7.9 17.2 0 5.3-4 13.2-7.9 17.2L420.8 504.1l292.1 292.1c4 4 7.9 11.9 7.9 17.2 0 6.6-4 13.2-7.9 17.2l-37 37c-4.1 3.8-12 7.8-17.3 7.8z" />
              </svg>
            </BackButton>
            {filterType === FilterType.PLACE && 'Enter location'}
          </FalseMobileHeader>
        )}
        {searchFilterOpen && (
          <SearchDropdown ref={dropRef} filterType={filterType} data-testid="search-bar-dropdown-box">
            {filterType === FilterType.PLACE && (
              <>
                <SearchInputWrapper>
                  <SearchInput
                    id="place_input"
                    name="place_input"
                    placeholder="Where do you want to boat?"
                    type="text"
                    autoComplete="off"
                    value={value}
                    onChange={handleInput}
                    onFocus={() => {
                      setSuggestionsVisible(true);
                    }}
                    onKeyPress={(e) => {
                      init();
                      if (e.key === 'Enter') {
                        handleSelect(data[activeSuggestion || 0]);
                        setActiveSuggestion(-1);
                      }
                    }}
                    onKeyDown={(e) => {
                      init();
                      if (e.key === 'ArrowDown') {
                        setActiveSuggestion(Math.min(activeSuggestion + 1, data.length));
                      } else if (e.key === 'ArrowUp') {
                        setActiveSuggestion(Math.max(activeSuggestion - 1, 0));
                      }
                    }}
                    autoFocus
                    data-testid="search-bar-dropdown-input"
                  />
                  {value && (
                    <CloseIcon onClick={handleMobileClear}>
                      <svg height={15} fill="#5E696A" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1008 1008">
                        <path d="M227.8 157.3l633.9 633.9c19.5 19.5 19.5 51 0 70.4-19.5 19.5-51 19.5-70.4 0l-634-633.8c-19.5-19.5-19.5-51 0-70.4 19.5-19.5 51-19.5 70.5-.1z" />
                        <path d="M146.3 780.2l633.9-633.9c19.5-19.5 51-19.5 70.4 0 19.5 19.5 19.5 51 0 70.4l-633.9 634c-19.5 19.5-51 19.5-70.4 0-19.5-19.5-19.5-51 0-70.5z" />
                      </svg>
                    </CloseIcon>
                  )}
                </SearchInputWrapper>
                {!value && <ul>{renderDefault()}</ul>}
                {status === 'OK' && ready && suggestionsVisible && <ul>{renderSuggestions()}</ul>}
              </>
            )}
            {filterType === FilterType.DATE && (
              <StyledBookingCalendarWrapper
                onChange={onDateSelect}
                hideMultiDay={false}
                hidePrice
                autoApply={false}
                toggleCall={(e) => {
                  setFilterType(FilterType.EMPTY);
                  setSearchFilterOpen(false);
                  if (e && !(e.type || e.eventPhase)) onDateSelect(e);
                }}
                mobile={false}
              />
            )}
          </SearchDropdown>
        )}
        {mobileFilterOpen && (
          <MobileOverlay onClick={handleMobileSearchClick}>
            <MobileDropDown
              filterType={filterType}
              mobileFilterDetailsOpen={mobileFilterDetailsOpen}
              onClick={(e) => e.stopPropagation()}
            >
              {!mobileFilterDetailsOpen && (
                <>
                  <Row onClick={() => handleMobileFilterDetails(FilterType.PLACE)} className="mobile-dropdown-row">
                    <Title className="title">Where</Title>
                    <Desc className="desc">{headline.replace(', USA', '')}</Desc>
                  </Row>
                  <Row onClick={() => handleMobileFilterDetails(FilterType.DATE)} className="mobile-dropdown-row">
                    <Title className="title">Date</Title>
                    <Desc className="desc">{getDate()}</Desc>
                  </Row>
                </>
              )}
              {mobileFilterDetailsOpen && (
                <div>
                  {filterType === FilterType.PLACE && (
                    <>
                      <SearchInputWrapper>
                        <SearchIcon>
                          <img alt="search icon" src="/images/icons/search-icon.svg" />
                        </SearchIcon>
                        <SearchInput
                          id="place_input"
                          ref={inputRef}
                          name="place_input"
                          placeholder="Where do you want to boat?"
                          type="text"
                          autoComplete="off"
                          value={value}
                          onChange={handleInput}
                          onFocus={() => {
                            setSuggestionsVisible(true);
                          }}
                          onKeyPress={(e) => {
                            init();
                            if (e.key === 'Enter') {
                              handleSelect(data[activeSuggestion || 0]);
                              setActiveSuggestion(-1);
                            }
                          }}
                          onKeyDown={(e) => {
                            init();
                            if (e.key === 'ArrowDown') {
                              setActiveSuggestion(Math.min(activeSuggestion + 1, data.length));
                            } else if (e.key === 'ArrowUp') {
                              setActiveSuggestion(Math.max(activeSuggestion - 1, 0));
                            }
                          }}
                          autoFocus={false}
                        />
                        {value && (
                          <CloseIcon onClick={handleMobileClear}>
                            <svg height={15} fill="#5E696A" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1008 1008">
                              <path d="M227.8 157.3l633.9 633.9c19.5 19.5 19.5 51 0 70.4-19.5 19.5-51 19.5-70.4 0l-634-633.8c-19.5-19.5-19.5-51 0-70.4 19.5-19.5 51-19.5 70.5-.1z" />
                              <path d="M146.3 780.2l633.9-633.9c19.5-19.5 51-19.5 70.4 0 19.5 19.5 19.5 51 0 70.4l-633.9 634c-19.5 19.5-51 19.5-70.4 0-19.5-19.5-19.5-51 0-70.5z" />
                            </svg>
                          </CloseIcon>
                        )}
                      </SearchInputWrapper>
                      {!value && <DefaultList>{renderDefault()}</DefaultList>}
                      {status === 'OK' && ready && suggestionsVisible && (
                        <DefaultList>{renderSuggestions()}</DefaultList>
                      )}
                    </>
                  )}
                  {filterType === FilterType.DATE && (
                    <MobileBookingCalendarWrapper
                      onChange={onDateSelect}
                      hidePrice
                      autoApply={false}
                      hideMobileHeader
                      monthsToShow={3}
                      toggleCall={(e) => {
                        setMobileFilterDetailsOpen(false);
                        setFalseHeader(false);
                        if (e && !(e.type || e.eventPhase)) onDateSelect(e);
                      }}
                      mobile
                      dontFetch
                      mobileFullLength
                    />
                  )}
                </div>
              )}
            </MobileDropDown>
          </MobileOverlay>
        )}
      </SearchWrapper>
    </SearchContainer>
  );
};

const SearchIcon = styled.div`
  display: flex;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
`;

const DefaultList = styled.div`
  height: 250px;
  overflow-y: scroll;

  .pac-item-sub {
    font-size: 12.25px;
  }
`;

const FalseMobileHeader = styled.div`
  width: 100vw;
  height: 54px;
  z-index: 150;
  background: white;
  margin-top: -50px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #0a252f;
  left: 0;
`;

const BackButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background: #f5f7f9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 20px;
`;

const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;

  ${mediaMSmMax} {
    border-bottom: 1px solid #d2d6db;
  }
`;

const MobileDropDown = styled.div<{ filterType: FilterType; mobileFilterDetailsOpen: boolean }>`
  width: 100%;
  background: #fff;
  margin-top: -1px;
  padding: 16px 20px;

  ${({ filterType, mobileFilterDetailsOpen }) =>
    filterType === FilterType.PLACE &&
    mobileFilterDetailsOpen &&
    css`
      padding: 16px 0;

      ${SearchInputWrapper}, ${DefaultList} {
        padding: 0 22px;
      }
    `}

  position: relative;
  z-index: 1000;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
`;

const Title = styled.div`
  color: #5e696a;
  font-weight: 400;
`;

const Desc = styled.div`
  color: #0751c2;
  font-weight: 500;
`;

const MobileOverlay = styled.div`
  height: calc(100vh - 50px);
  width: 100vw;
  background: rgba(23, 35, 60, 0.7);
  //margin-left: -55px;
  left: 0;
  position: absolute;
  z-index: 100;
  margin-top: 1px;
`;

const CloseIcon = styled.div`
  background: #f5f7f9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: absolute;
  right: 24px;
  top: 32px;
  height: 28px;
  width: 28px;
`;

const SearchDropdown = styled.div<{ filterType: FilterType }>`
  position: absolute;
  filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.09));
  background: #fff;
  border-radius: 18px;
  top: 45px;
  font-weight: 500;

  ${({ filterType }) =>
    filterType === FilterType.DATE &&
    css`
      width: 730px;
      left: 50%;
      transform: translate(-50%, 0px);
      padding: 26px;
    `}

  ${({ filterType }) =>
    filterType === FilterType.PLACE &&
    css`
      width: 390px;
      padding: 26px;
    `}
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  border-radius: 0;
  width: 100% !important;
  background-color: rgba(255, 255, 255, 0.15);
  font-size: 16px;
  border-bottom: 1px solid #d2d6db;
  padding: 15px;
  padding-right: 40px;
  ${mediaMSmMax} {
    border-bottom: 0;
  }
`;

const PlacePicker = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;

  ${mediaMSmMax} {
    font-weight: 600;
    padding-top: 5px;
  }
`;

const DatePicker = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-left: 1px solid #d2d6db;
  ${mediaMSmMax} {
    border: none;
    font-size: 10px;
    font-weight: 400;
    padding-bottom: 3px;
    line-height: 10px;
    z-index: unset;
  }
`;

const MobileClick = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20;

  ${mediaMSmMax} {
    display: block;
  }
`;

const DownArrow = styled.span`
  position: absolute;
  cursor: pointer;
  right: 15px;
  ${mediaMSmMax} {
    top: 50%;
    right: 10px;
    transform: translate(-50%, -50%);
  }
`;

const InputContainer = styled.div`
  border: 1px solid rgba(188, 192, 192, 0.4);
  box-sizing: border-box;
  border-radius: 30px;
  min-width: 334px;
  height: 44px;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  position: relative;
  color: #17233c;
  overflow: hidden;

  div {
    width: 50%;
    ${mediaMSmMax} {
      width: 100%;
    }

    .iconSvg {
      padding: 0 6px 0 17px;
      ${mediaMSmMax} {
        display: none;
      }
    }
  }

  ${mediaMSmMax} {
    min-width: unset;
    flex-direction: column;
    padding-left: 16px;
    margin-bottom: unset;
    margin-top: -1.5px;
    font-size: 13px;
  }
`;

const SearchWrapper = styled.div`
  ${mediaMLgMin} {
    position: relative;
    z-index: 14;
    top: -2px;
  }
`;

const SearchContainer = styled.div`
  ${mediaMSmMax} {
    width: 100%;
  }
`;

export default withRouter(SearchBar);

const MobileBookingCalendarWrapper = styled(BookingCalendarWrapper)`
  padding: 0 !important;
  border-radius: 0 !important;
  height: 101%;

  .mobile {
    margin: initial;
    width: 100%;

    .rdrCalendarWrapper {
      width: 100%;
    }

    .rdrMonth {
      width: 100%;
    }
  }
`;

const StyledBookingCalendarWrapper = styled(BookingCalendarWrapper)`
  &${DesktopWrapper} {
    box-shadow: none !important;
    border: none;
    transform: translate(-50%, 0px);
    border-radius: 18px;
    display: contents;
  }
`;
