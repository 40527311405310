/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DomesticV2BoatsBoatIdInsuranceGet200Response
 */
export interface DomesticV2BoatsBoatIdInsuranceGet200Response {
    /**
     * 
     * @type {string}
     * @memberof DomesticV2BoatsBoatIdInsuranceGet200Response
     */
    'state'?: DomesticV2BoatsBoatIdInsuranceGet200ResponseStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DomesticV2BoatsBoatIdInsuranceGet200Response
     */
    'felony_conviction'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DomesticV2BoatsBoatIdInsuranceGet200Response
     */
    'customizations'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DomesticV2BoatsBoatIdInsuranceGet200Response
     */
    'claims'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DomesticV2BoatsBoatIdInsuranceGet200Response
     */
    'rejection_reasons'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DomesticV2BoatsBoatIdInsuranceGet200Response
     */
    'currently_insured'?: boolean;
}

export const DomesticV2BoatsBoatIdInsuranceGet200ResponseStateEnum = {
    incomplete: 'incomplete',
    accept: 'accept',
    reject: 'reject'
} as const;

export type DomesticV2BoatsBoatIdInsuranceGet200ResponseStateEnum = typeof DomesticV2BoatsBoatIdInsuranceGet200ResponseStateEnum[keyof typeof DomesticV2BoatsBoatIdInsuranceGet200ResponseStateEnum];


