/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Boat } from './boat';
import { BoatPackage } from './boat-package';
import { BookingAddOn } from './booking-add-on';
import { BookingCaptain } from './booking-captain';
import { BookingOwner } from './booking-owner';
import { BookingOwnerPricing } from './booking-owner-pricing';
import { BookingPaymentMethod } from './booking-payment-method';
import { BookingRenter } from './booking-renter';
import { BookingRenterPricing } from './booking-renter-pricing';
import { BookingRenterReview } from './booking-renter-review';
import { CicoWindow } from './cico-window';
import { InsuranceRenterPolicy } from './insurance-renter-policy';

/**
 * 
 * @export
 * @interface Booking
 */
export interface Booking {
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'id'?: string;
    /**
     * Granular Booking State
     * @type {string}
     * @memberof Booking
     */
    'state'?: BookingStateEnum;
    /**
     * Group of bookings state
     * @type {string}
     * @memberof Booking
     */
    'status'?: BookingStatusEnum;
    /**
     * Min price for booking
     * @type {number}
     * @memberof Booking
     */
    'special_offer_min_price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'can_pre_approve'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'ready_for_rental_post_grandfathering'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'could_pre_approve_if_ready_for_rental'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'can_modify'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'waiting_on_renter_identity_verification'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'conversation_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'trip_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'trip_finish'?: string;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'package_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'package_currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'special_offer_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'date_price_adjustment'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'boating_credits'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'renter_add_on_total'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'owner_add_on_total'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'pre_tax_and_fees_booking_total'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'service_fee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'rental_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'boat_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'captain_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'earnings_including_captain'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'security_deposit_initial_hold'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'security_deposit'?: number;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'security_deposit_capture_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'security_deposit_status'?: BookingSecurityDepositStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'booking_fee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'payout_deduction'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'insurance_cost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'coupon_price_adjustment'?: number;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'trip_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'owner_payout'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'sales_tax'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'passengers'?: number;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'package_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'location_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'location_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'pickup_instructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'fuel_policy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'insurance_type'?: string;
    /**
     * Insurance provider name
     * @type {string}
     * @memberof Booking
     */
    'insurer'?: BookingInsurerEnum;
    /**
     * Towing insurance provider name
     * @type {string}
     * @memberof Booking
     */
    'towing_insurer'?: BookingTowingInsurerEnum;
    /**
     * insurance policy URL for insurance
     * @type {string}
     * @memberof Booking
     */
    'insurance_policy_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'request_expires_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'cancellation_full_refund_expires_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'cancelled_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'booking_total'?: number;
    /**
     * 
     * @type {object}
     * @memberof Booking
     */
    'booking_modification'?: object;
    /**
     * 
     * @type {BoatPackage}
     * @memberof Booking
     */
    'package'?: BoatPackage;
    /**
     * 
     * @type {Boat}
     * @memberof Booking
     */
    'boat'?: Boat;
    /**
     * 
     * @type {BookingOwner}
     * @memberof Booking
     */
    'owner'?: BookingOwner;
    /**
     * 
     * @type {BookingRenter}
     * @memberof Booking
     */
    'renter'?: BookingRenter;
    /**
     * 
     * @type {BookingCaptain}
     * @memberof Booking
     */
    'captain'?: BookingCaptain;
    /**
     * 
     * @type {CicoWindow}
     * @memberof Booking
     */
    'check_in_window'?: CicoWindow;
    /**
     * 
     * @type {CicoWindow}
     * @memberof Booking
     */
    'check_out_window'?: CicoWindow;
    /**
     * 
     * @type {BookingRenterReview}
     * @memberof Booking
     */
    'renter_review'?: BookingRenterReview;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'fuel_reimbursement_expires_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'captain_payment_collection'?: BookingCaptainPaymentCollectionEnum;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'boat_us_sub_policy'?: string;
    /**
     * 
     * @type {Array<BookingAddOn>}
     * @memberof Booking
     */
    'booking_add_ons'?: Array<BookingAddOn>;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'captain_state'?: BookingCaptainStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'captain_network_enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'security_deposit_captures_at'?: string;
    /**
     * 
     * @type {BookingOwnerPricing}
     * @memberof Booking
     */
    'owner_pricing'?: BookingOwnerPricing;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'owner_listing_fee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'owner_insurance_fee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'owner_earning'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'renter_insurance_fee'?: number;
    /**
     * The renter portion of the insurance tax, in cents
     * @type {number}
     * @memberof Booking
     */
    'renter_insurance_tax'?: number;
    /**
     * 
     * @type {BookingRenterPricing}
     * @memberof Booking
     */
    'renter_pricing'?: BookingRenterPricing;
    /**
     * 
     * @type {BookingPaymentMethod}
     * @memberof Booking
     */
    'payment_method'?: BookingPaymentMethod;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'is_fuel_reimbursement_allowed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'state_read_by_user'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'pay_captain_at_dock'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'captain_payment_collection_at_dock'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'can_captain_cancel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'can_captain_approve'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'can_captain_decline'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'can_cancel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'can_decline'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'can_approve'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'can_edit_or_cancel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'can_view_details'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'show_owner_leave_a_review'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'details_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'boat_missing_hin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'instant_bookable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'amount_to_be_charged'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'display_captain_price_as_tbd'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'amount_to_be_collected_at_dock'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'captain_fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'user_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'captain_payout'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'can_view_onboard_document'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'onboard_document_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'show_renter_leave_a_review'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'show_captain_leave_a_review'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'can_be_booked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'is_captain_available'?: boolean;
    /**
     * 
     * @type {InsuranceRenterPolicy}
     * @memberof Booking
     */
    'insurance_renter_policy'?: InsuranceRenterPolicy;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'legacy_rental_agreement_path'?: string;
}

export const BookingStateEnum = {
    NULL: 'NULL',
    cancelled: 'cancelled',
    pre_approved: 'pre_approved',
    expired: 'expired',
    aboard: 'aboard',
    payment_held: 'payment_held',
    ashore: 'ashore',
    concluded: 'concluded',
    approved: 'approved',
    pending_tos: 'pending_tos',
    initialized: 'initialized',
    disputed: 'disputed',
    declined: 'declined',
    request_canceled: 'request_canceled',
    pending: 'pending'
} as const;

export type BookingStateEnum = typeof BookingStateEnum[keyof typeof BookingStateEnum];
export const BookingStatusEnum = {
    trip_request: 'trip_request',
    upcoming: 'upcoming',
    active: 'active',
    completed: 'completed'
} as const;

export type BookingStatusEnum = typeof BookingStatusEnum[keyof typeof BookingStatusEnum];
export const BookingSecurityDepositStatusEnum = {
    NULL: 'NULL',
    created: 'created',
    extended: 'extended',
    captured: 'captured',
    released: 'released'
} as const;

export type BookingSecurityDepositStatusEnum = typeof BookingSecurityDepositStatusEnum[keyof typeof BookingSecurityDepositStatusEnum];
export const BookingInsurerEnum = {
    boat_us: 'boat_us',
    buoy: 'buoy'
} as const;

export type BookingInsurerEnum = typeof BookingInsurerEnum[keyof typeof BookingInsurerEnum];
export const BookingTowingInsurerEnum = {
    boat_us: 'boat_us',
    sea_tow: 'sea_tow',
    buoy: 'buoy'
} as const;

export type BookingTowingInsurerEnum = typeof BookingTowingInsurerEnum[keyof typeof BookingTowingInsurerEnum];
export const BookingCaptainPaymentCollectionEnum = {
    NULL: 'NULL',
    at_dock_via_boatsetter: 'at_dock via_boatsetter'
} as const;

export type BookingCaptainPaymentCollectionEnum = typeof BookingCaptainPaymentCollectionEnum[keyof typeof BookingCaptainPaymentCollectionEnum];
export const BookingCaptainStateEnum = {
    pending: 'pending',
    available: 'available',
    cancelled: 'cancelled',
    declined: 'declined',
    admin_removed: 'admin_removed',
    renter_removed: 'renter_removed',
    approved: 'approved'
} as const;

export type BookingCaptainStateEnum = typeof BookingCaptainStateEnum[keyof typeof BookingCaptainStateEnum];


