import { ColoredLink, LinkWrap } from 'components/header/shareComponents';
import Link from 'next/link';
import boatsetterPaths from 'utils/boatsetterPaths';
import { useFeatureFlagQueryByName } from 'components/layouts/hooks';

type Props = {
  onSignup: () => void;
  onLogin: () => void;
};

export default function AuthLinks({ onLogin, onSignup }: Props) {
  const { flag: isNewAuthEnabled } = useFeatureFlagQueryByName('clerk_signup');

  const signupPath = isNewAuthEnabled ? boatsetterPaths.auth.root : boatsetterPaths.signUp;
  const loginPath = isNewAuthEnabled ? boatsetterPaths.auth.root : boatsetterPaths.logIn;

  return (
    <>
      <LinkWrap>
        <ColoredLink onClick={() => onSignup()}>
          <Link href={signupPath}>Sign&nbsp;up</Link>
        </ColoredLink>
      </LinkWrap>
      <LinkWrap>
        <ColoredLink onClick={() => onLogin()}>
          <Link href={loginPath}>Log&nbsp;in</Link>
        </ColoredLink>
      </LinkWrap>
    </>
  );
}
