/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BookingPrerequisite
 */
export interface BookingPrerequisite {
    /**
     * The type of prerequisite
     * @type {string}
     * @memberof BookingPrerequisite
     */
    'type'?: BookingPrerequisiteTypeEnum;
    /**
     * Whether the prerequisite is satisfied
     * @type {boolean}
     * @memberof BookingPrerequisite
     */
    'satisfied'?: boolean;
    /**
     * The error message if the prerequisite is not satisfied
     * @type {string}
     * @memberof BookingPrerequisite
     */
    'error'?: string | null;
    /**
     * A description of the prerequisite
     * @type {string}
     * @memberof BookingPrerequisite
     */
    'description'?: string;
}

export const BookingPrerequisiteTypeEnum = {
    allowed_to_drive_boat: 'allowed_to_drive_boat',
    age_restricted: 'age_restricted',
    allowed_to_rent: 'allowed_to_rent',
    identity_verification: 'identity_verification',
    initial_setup: 'initial_setup'
} as const;

export type BookingPrerequisiteTypeEnum = typeof BookingPrerequisiteTypeEnum[keyof typeof BookingPrerequisiteTypeEnum];


