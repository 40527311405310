import styled from 'styled-components';
import Link from 'next/link';
import { ColoredLink } from 'components/header/shareComponents';

const NavInbox = ({ userDetails }) => (
  <InboxLink>
    <ColoredLink>
      <Link passHref href="/a/inbox" legacyBehavior>
        <LinkWrapper>Messages {userDetails.has_unread_messages && <Bubble />}</LinkWrapper>
      </Link>
    </ColoredLink>
  </InboxLink>
);

const InboxLink = styled.div`
  display: flex;
`;

const Bubble = styled.span`
  position: absolute;
  background: #f4c064;
  z-index: 12;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  top: 0;
  right: -9px;
`;

const LinkWrapper = styled.a`
  position: relative;
  display: flex;
`;

export default NavInbox;
