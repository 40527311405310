/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BoatAddOn } from './boat-add-on';
import { BoatBoatManager } from './boat-boat-manager';
import { BoatFeatures } from './boat-features';
import { BoatLocation } from './boat-location';
import { BoatPackage } from './boat-package';
import { BoatPhoto } from './boat-photo';
import { BoatPrimaryManager } from './boat-primary-manager';
import { BoatRules } from './boat-rules';
import { BoatTrim } from './boat-trim';
import { CheapestPackage } from './cheapest-package';

/**
 * 
 * @export
 * @interface Boat
 */
export interface Boat {
    /**
     * 
     * @type {string}
     * @memberof Boat
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Boat
     */
    'state'?: string;
    /**
     * 
     * @type {number}
     * @memberof Boat
     */
    'primary_rate'?: number;
    /**
     * Boat Title
     * @type {string}
     * @memberof Boat
     */
    'listing_tagline'?: string;
    /**
     * Boat Description
     * @type {string}
     * @memberof Boat
     */
    'listing_description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Boat
     */
    'activated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Boat
     */
    'commercial_captained_fishing_charter'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Boat
     */
    'has_lienholder'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Boat
     */
    'multibook_enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Boat
     */
    'multibook_buffer_time_hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof Boat
     */
    'horsepower'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Boat
     */
    'is_new_boat'?: boolean;
    /**
     * 
     * @type {BoatTrim}
     * @memberof Boat
     */
    'trim'?: BoatTrim;
    /**
     * 
     * @type {boolean}
     * @memberof Boat
     */
    'agreed_to_uscg_regulations'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Boat
     */
    'is_instant_book'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Boat
     */
    'self_insured'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Boat
     */
    'instant_book_eligible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Boat
     */
    'instant_book_enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Boat
     */
    'live_on_site_text'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Boat
     */
    'previewable'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof Boat
     */
    'lienholder'?: object;
    /**
     * 
     * @type {string}
     * @memberof Boat
     */
    'company_website'?: string;
    /**
     * 
     * @type {string}
     * @memberof Boat
     */
    'hin'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Boat
     */
    'is_owner_activated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Boat
     */
    'captain_network_enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Boat
     */
    'private_staterooms'?: number;
    /**
     * 
     * @type {number}
     * @memberof Boat
     */
    'bed_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof Boat
     */
    'quick_summary'?: string;
    /**
     * Boat Type
     * @type {string}
     * @memberof Boat
     */
    'type'?: string;
    /**
     * Boat Category
     * @type {string}
     * @memberof Boat
     */
    'boat_category'?: string;
    /**
     * Boat Length (ft)
     * @type {number}
     * @memberof Boat
     */
    'length'?: number;
    /**
     * Boat Make
     * @type {string}
     * @memberof Boat
     */
    'make'?: string;
    /**
     * Boat Model
     * @type {string}
     * @memberof Boat
     */
    'model'?: string;
    /**
     * 
     * @type {string}
     * @memberof Boat
     */
    'make_and_model'?: string;
    /**
     * Boat Vessel Year
     * @type {number}
     * @memberof Boat
     */
    'year_manufactured'?: number;
    /**
     * Boat Fuel Type
     * @type {string}
     * @memberof Boat
     */
    'fuel_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof Boat
     */
    'passenger_capacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof Boat
     */
    'sleeping_capacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof Boat
     */
    'capacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof Boat
     */
    'completed_booking_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof Boat
     */
    'review_count'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Boat
     */
    'is_fishing'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Boat
     */
    'rating'?: number;
    /**
     * 
     * @type {number}
     * @memberof Boat
     */
    'top_speed'?: number;
    /**
     * Insured value with cents
     * @type {number}
     * @memberof Boat
     */
    'insured_value_cents'?: number;
    /**
     * 
     * @type {number}
     * @memberof Boat
     */
    'advance_notice'?: number;
    /**
     * 
     * @type {Array<BoatFeatures>}
     * @memberof Boat
     */
    'features'?: Array<BoatFeatures>;
    /**
     * 
     * @type {Array<BoatPhoto>}
     * @memberof Boat
     */
    'photos'?: Array<BoatPhoto>;
    /**
     * 
     * @type {BoatPhoto}
     * @memberof Boat
     */
    'best_photo'?: BoatPhoto;
    /**
     * 
     * @type {Array<BoatPackage>}
     * @memberof Boat
     */
    'packages'?: Array<BoatPackage>;
    /**
     * 
     * @type {Array<BoatAddOn>}
     * @memberof Boat
     */
    'boat_add_ons'?: Array<BoatAddOn>;
    /**
     * 
     * @type {BoatPrimaryManager}
     * @memberof Boat
     */
    'primary_manager'?: BoatPrimaryManager;
    /**
     * 
     * @type {BoatBoatManager}
     * @memberof Boat
     */
    'boat_manager'?: BoatBoatManager;
    /**
     * 
     * @type {BoatLocation}
     * @memberof Boat
     */
    'location'?: BoatLocation;
    /**
     * The Boat\'s time zone
     * @type {string}
     * @memberof Boat
     */
    'time_zone'?: string;
    /**
     * 
     * @type {BoatRules}
     * @memberof Boat
     */
    'boat_rules'?: BoatRules;
    /**
     * 
     * @type {CheapestPackage}
     * @memberof Boat
     */
    'cheapest_package'?: CheapestPackage;
    /**
     * 
     * @type {string}
     * @memberof Boat
     */
    'cancellation_policy'?: BoatCancellationPolicyEnum;
    /**
     * 
     * @type {string}
     * @memberof Boat
     */
    'captain_option'?: BoatCaptainOptionEnum;
    /**
     * The default start_times is null. 0..6 key is day of week
     * @type {object}
     * @memberof Boat
     */
    'start_times'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof Boat
     */
    'boat_cancellation_text'?: Array<string>;
    /**
     * ISO8601 date
     * @type {string}
     * @memberof Boat
     */
    'deleted_at'?: string;
    /**
     * ISO8601 date
     * @type {string}
     * @memberof Boat
     */
    'created_at'?: string;
    /**
     * ISO8601 date
     * @type {string}
     * @memberof Boat
     */
    'blw_agreement_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Boat
     */
    'boat_cancellation_policy'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Boat
     */
    'coi_submitted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Boat
     */
    'engine_year'?: number;
    /**
     * 
     * @type {string}
     * @memberof Boat
     */
    'engine_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof Boat
     */
    'engine_quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof Boat
     */
    'hull_material'?: string;
    /**
     * 
     * @type {string}
     * @memberof Boat
     */
    'external_insurer'?: string;
    /**
     * 
     * @type {string}
     * @memberof Boat
     */
    'boat_registration_number'?: string;
}

export const BoatCancellationPolicyEnum = {
    strict: 'strict',
    moderate: 'moderate',
    flexible: 'flexible'
} as const;

export type BoatCancellationPolicyEnum = typeof BoatCancellationPolicyEnum[keyof typeof BoatCancellationPolicyEnum];
export const BoatCaptainOptionEnum = {
    captain_optional: 'captain_optional',
    captain_only: 'captain_only',
    no_captain_only: 'no_captain_only'
} as const;

export type BoatCaptainOptionEnum = typeof BoatCaptainOptionEnum[keyof typeof BoatCaptainOptionEnum];


