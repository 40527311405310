/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface OwnerStripeRequirements
 */
export interface OwnerStripeRequirements {
    /**
     * 
     * @type {Array<string>}
     * @memberof OwnerStripeRequirements
     */
    'requirements_due'?: Array<OwnerStripeRequirementsRequirementsDueEnum>;
    /**
     * If requirements are past due, the owner\'s Stripe Connect Account is disabled
     * @type {boolean}
     * @memberof OwnerStripeRequirements
     */
    'account_enabled'?: boolean;
    /**
     * If requirements are blocking the owner\'s boat from going live
     * @type {boolean}
     * @memberof OwnerStripeRequirements
     */
    'is_boat_blocked'?: boolean;
}

export const OwnerStripeRequirementsRequirementsDueEnum = {
    tax_form_bank_account_stripe_additional_requirements: 'tax_form bank_account stripe_additional_requirements'
} as const;

export type OwnerStripeRequirementsRequirementsDueEnum = typeof OwnerStripeRequirementsRequirementsDueEnum[keyof typeof OwnerStripeRequirementsRequirementsDueEnum];


