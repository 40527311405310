import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

const HeaderDropdown = ({ icon, children, className = null, id = null }) => {
  const [active, setActive] = useState(false);
  const wrapperRef = useRef(null);

  useOnClickOutside(wrapperRef, () => {
    setActive(false);
  });

  const toggleMenu = () => {
    setActive(!active);
  };

  return (
    <Wrapper ref={wrapperRef} className={className} id={id}>
      <FlexButton type="button" onClick={() => toggleMenu()}>
        {icon}
      </FlexButton>
      <DropdownWrapper data-testid="components-header-dropdown-box" className={`${active && 'is-active'}`}>
        {children}
      </DropdownWrapper>
    </Wrapper>
  );
};

const FlexButton = styled.button`
  display: flex;
  align-items: center;
`;

export const DropdownSection = styled.ul`
  li {
    padding: 5px 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #eaebeb;
  }

  &:first-child {
    padding-top: 0;
  }

  padding: 15px 0;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const DropdownWrapper = styled.div`
  z-index: 1000;
  padding: 15px 25px;
  background: white;
  top: 100%;
  display: none;
  position: absolute;
  min-width: 220px;
  max-width: 640px;
  right: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25), 0 0 0 1px rgba(0, 0, 0, 0.1);
  margin-top: 31px;

  &.is-active {
    display: block;
  }

  a {
    color: #0a4195 !important;
  }

  &::before {
    right: 7px;
    top: -10px;
    border: 10px solid transparent;
    border-top: none;
    border-bottom: 10px solid rgba(0, 0, 0, 0.2);
    filter: drop-shadow(0 -1px 1px rgba(130, 130, 130, 0.3));
    content: '';
    display: inline-block;
    position: absolute;
    bottom: auto;
  }

  &::after {
    right: 8px;
    top: -9px;
    border: 9px solid transparent;
    border-top: none;
    border-bottom: 9px solid #fff;
    position: absolute;
    content: '';
  }
`;

export default HeaderDropdown;
