/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DomesticV2InsurersInsurerIdInsuranceStatusGet200Response
 */
export interface DomesticV2InsurersInsurerIdInsuranceStatusGet200Response {
    /**
     * 
     * @type {string}
     * @memberof DomesticV2InsurersInsurerIdInsuranceStatusGet200Response
     */
    'renter_insurance_status'?: DomesticV2InsurersInsurerIdInsuranceStatusGet200ResponseRenterInsuranceStatusEnum;
}

export const DomesticV2InsurersInsurerIdInsuranceStatusGet200ResponseRenterInsuranceStatusEnum = {
    approved: 'approved',
    denied: 'denied'
} as const;

export type DomesticV2InsurersInsurerIdInsuranceStatusGet200ResponseRenterInsuranceStatusEnum = typeof DomesticV2InsurersInsurerIdInsuranceStatusGet200ResponseRenterInsuranceStatusEnum[keyof typeof DomesticV2InsurersInsurerIdInsuranceStatusGet200ResponseRenterInsuranceStatusEnum];


