/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DriveVerificationStatus
 */
export interface DriveVerificationStatus {
    /**
     * 
     * @type {string}
     * @memberof DriveVerificationStatus
     */
    'status'?: DriveVerificationStatusStatusEnum;
}

export const DriveVerificationStatusStatusEnum = {
    not_started: 'not_started',
    accepted: 'accepted',
    rejected: 'rejected'
} as const;

export type DriveVerificationStatusStatusEnum = typeof DriveVerificationStatusStatusEnum[keyof typeof DriveVerificationStatusStatusEnum];


