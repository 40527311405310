/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DriveVerificationBoatClaim } from './drive-verification-boat-claim';
import { DriveVerificationGovernmentId } from './drive-verification-government-id';
import { DriveVerificationMovingViolation } from './drive-verification-moving-violation';

/**
 * 
 * @export
 * @interface DriveVerification
 */
export interface DriveVerification {
    /**
     * 
     * @type {boolean}
     * @memberof DriveVerification
     */
    'felony_conviction': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DriveVerification
     */
    'insurance_rejections'?: Array<DriveVerificationInsuranceRejectionsEnum>;
    /**
     * ISO 8601 date
     * @type {string}
     * @memberof DriveVerification
     */
    'birthdate': string;
    /**
     * 
     * @type {Array<DriveVerificationBoatClaim>}
     * @memberof DriveVerification
     */
    'claims'?: Array<DriveVerificationBoatClaim>;
    /**
     * 
     * @type {DriveVerificationGovernmentId}
     * @memberof DriveVerification
     */
    'government_id'?: DriveVerificationGovernmentId;
    /**
     * 
     * @type {Array<DriveVerificationMovingViolation>}
     * @memberof DriveVerification
     */
    'moving_violations'?: Array<DriveVerificationMovingViolation>;
}

export const DriveVerificationInsuranceRejectionsEnum = {
    insurer_discontinued_program: 'insurer_discontinued_program',
    insurer_changed_guidelines: 'insurer_changed_guidelines',
    other: 'other'
} as const;

export type DriveVerificationInsuranceRejectionsEnum = typeof DriveVerificationInsuranceRejectionsEnum[keyof typeof DriveVerificationInsuranceRejectionsEnum];


