/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface IdentityVerificationStatus
 */
export interface IdentityVerificationStatus {
    /**
     * 
     * @type {string}
     * @memberof IdentityVerificationStatus
     */
    'status'?: IdentityVerificationStatusStatusEnum;
}

export const IdentityVerificationStatusStatusEnum = {
    dob_required_phone_verification_required_processing_not_started_verified_pending_ssn_required_technical_error: 'dob_required phone_verification_required processing not_started verified pending ssn_required technical_error'
} as const;

export type IdentityVerificationStatusStatusEnum = typeof IdentityVerificationStatusStatusEnum[keyof typeof IdentityVerificationStatusStatusEnum];


