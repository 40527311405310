/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { InsuranceScreeningQuestionOptionsInner } from './insurance-screening-question-options-inner';
import { InsuranceScreeningQuestionValidations } from './insurance-screening-question-validations';

/**
 * 
 * @export
 * @interface InsuranceScreeningQuestion
 */
export interface InsuranceScreeningQuestion {
    /**
     * The human-readable question text
     * @type {string}
     * @memberof InsuranceScreeningQuestion
     */
    'text'?: string;
    /**
     * Unique text identifier for the question
     * @type {string}
     * @memberof InsuranceScreeningQuestion
     */
    'slug'?: string;
    /**
     * The question data type
     * @type {string}
     * @memberof InsuranceScreeningQuestion
     */
    'type'?: InsuranceScreeningQuestionTypeEnum;
    /**
     * Whether the question is required
     * @type {boolean}
     * @memberof InsuranceScreeningQuestion
     */
    'required'?: boolean;
    /**
     * The default answer for the question
     * @type {string}
     * @memberof InsuranceScreeningQuestion
     */
    'default_answer'?: string;
    /**
     * 
     * @type {InsuranceScreeningQuestionValidations}
     * @memberof InsuranceScreeningQuestion
     */
    'validations'?: InsuranceScreeningQuestionValidations;
    /**
     * The list of key/value options when type = select or list
     * @type {Array<InsuranceScreeningQuestionOptionsInner>}
     * @memberof InsuranceScreeningQuestion
     */
    'options'?: Array<InsuranceScreeningQuestionOptionsInner>;
    /**
     * The user answer for the question
     * @type {string}
     * @memberof InsuranceScreeningQuestion
     */
    'answer'?: string;
}

export const InsuranceScreeningQuestionTypeEnum = {
    datetime: 'datetime',
    int: 'int',
    list: 'list',
    money: 'money',
    text: 'text',
    yes_no: 'yes_no'
} as const;

export type InsuranceScreeningQuestionTypeEnum = typeof InsuranceScreeningQuestionTypeEnum[keyof typeof InsuranceScreeningQuestionTypeEnum];


